import React from 'react';
import {Box, Button, Divider, Flex, Text} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {LeftColumnContent, RightColumnContent, TwoColumnGrid} from './helpers';
import {AccountTypeahead} from '../../AccountTypeahead';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {AccountList} from '../../AccountList';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {MaxBulkPreviewAccounts} from '../../../shared/validation';
import {FieldBuilderPageIndicator} from './FieldBuilderPageIndicator';
import {AccountImportButton} from '../../AccountImportButton';
import {BuilderPreviewTable} from '../../Ai/BuilderPreviewTable';

export const AiFieldBuilderSelectAccountsStep = () => {
  const {
    previewAccountIds,
    addAccount,
    removeAccount,
    publishChanges,
    serverState,
  } = useAiFieldBuilderStore();
  const {nextStep, prevStep} = useAiFieldBuilderNavStore();

  const onContinue = () => {
    publishChanges.mutate('save', {onSuccess: nextStep});
  };

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Box position="relative" h="100%">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <LeftColumnContent>
                <FieldBuilderPageIndicator />
                <Flex direction="column" flex="1" gap="4">
                  <Flex direction="column" gap="1">
                    <Text fontSize="xl">Choose accounts to preview</Text>
                    <Text fontSize="md" color="kgray.300">
                      Choose up to {MaxBulkPreviewAccounts} accounts.
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap="2">
                    <Box flex="1">
                      <AccountTypeahead
                        disabledTooltip="Max preview accounts reached"
                        isDisabled={
                          previewAccountIds.length >= MaxBulkPreviewAccounts
                        }
                        onSelect={addAccount}
                        placeholder="Search for an account..."
                        selected={previewAccountIds}
                      />
                    </Box>
                    <AccountImportButton
                      accountIds={previewAccountIds}
                      addAccount={addAccount}
                      maxAccounts={MaxBulkPreviewAccounts}
                      borderRadius="xl"
                      h="100%"
                    >
                      Upload CSV
                    </AccountImportButton>
                  </Flex>
                  <AccountList
                    onRemoveAccount={removeAccount}
                    selectedAccountIds={previewAccountIds}
                  />
                </Flex>
              </LeftColumnContent>
            </ScrollableFlex>
          </WrapperFlex>
        </Box>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4}>
          <Button variant="ghost" onClick={prevStep}>
            Back
          </Button>

          <Button
            colorScheme="kbuttonblue"
            isDisabled={!previewAccountIds.length}
            isLoading={publishChanges.isPending}
            onClick={onContinue}
          >
            Continue
          </Button>
        </Flex>
      </Flex>

      <RightColumnContent>
        <Flex direction="column" gap="1">
          <Text fontSize="xl">Preview</Text>
          <Text fontSize="md" color="kgray.300">
            Choose accounts that will help you review your AI Analyst&apos;s
            answers
          </Text>
        </Flex>
        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <BuilderPreviewTable
                accountIds={previewAccountIds}
                fieldDefinition={serverState}
              />
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </RightColumnContent>
    </TwoColumnGrid>
  );
};
