import _ from 'lodash';
import {KeyplayScoringSignal, ResolvedScoringSignal} from './signals';
import {zodEnum, keys} from './util';
import {AccountTier, CustomerAccountSource, Tag} from './scoredAccounts';
import {AccountFieldLabels} from './fields';
import {
  BusinessAudience,
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
} from './account';
import {WithId} from './util';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {KeyplayStatus} from './accountStatus';
import {ObjectId} from 'bson';
import {CountryCode} from './countries';

export interface FieldMappingTypes {
  ats: string | undefined;
  audience: BusinessAudience[] | undefined;
  businessOverview: string | undefined;
  employeeCount: number | undefined;
  finalDomain: string | undefined;
  finalLinkedIn: string | undefined;
  fit: number;
  hiring: number | undefined;
  hiringEng: number | undefined;
  hiringSales: number | undefined;
  hiringSoftwareEng: number | undefined;
  hqCity: string | undefined;
  hqCountry: CountryCode | undefined;
  hqPostalCode: string | undefined;
  hqState: string | undefined;
  inSam: boolean | undefined;
  jobUrl: string | undefined;
  lastFundingDate: string | undefined;
  linkedInJobCount: number | undefined;
  mostSimilarTo: string | undefined;
  name: string | undefined;
  oneMonthHeadcountGrowth: number | undefined;
  oneMonthHiringEngChange: number | undefined;
  oneMonthHiringHRChange: number | undefined;
  oneMonthHiringProductChange: number | undefined;
  oneMonthOpenRolesChange: number | undefined;
  oneMonthRecruitingVelocityChange: number | undefined;
  oneMonthTotalFundingChange: number | undefined;
  openRolePercentage: number | undefined;
  org: ResolvedScoringSignal[];
  primaryCategory: PrimaryBusinessCategory[] | undefined;
  profile: ResolvedScoringSignal[];
  recruitingVelocity: number | undefined;
  relevance: ResolvedScoringSignal[];
  secondaryCategory: SecondaryBusinessCategory[] | undefined;
  signals: ResolvedScoringSignal[];
  signalScore: number | undefined;
  similarityScore: number | undefined;
  sixMonthHeadcountGrowth: number | undefined;
  slackCommunityPage: string | undefined;
  source: CustomerAccountSource | undefined;
  stackFit: ResolvedScoringSignal[];
  status: KeyplayStatus;
  tags: WithId<Tag>[];
  tier: AccountTier | undefined;
  timestamp: Date;
  totalFundingRaised: number | undefined;
  yearFounded: number | undefined;

  // customer specific
  iterable_b2bSignals: KeyplayScoringSignal[];
  iterable_b2cSignals: KeyplayScoringSignal[];
}

export const FieldMappingLabels = {
  ats: 'ATS Used',
  audience: 'Audience',
  businessOverview: 'Business Overview',
  employeeCount: AccountFieldLabels.employeeCount,
  finalDomain: AccountFieldLabels.finalDomain,
  finalLinkedIn: AccountFieldLabels.finalLinkedIn,
  fit: 'Overall Fit',
  hiring: AccountFieldLabels.hiring,
  hiringEng: AccountFieldLabels.hiringEng,
  hiringSales: AccountFieldLabels.hiringSales,
  hiringSoftwareEng: AccountFieldLabels.hiringSoftwareEng,
  hqCity: AccountFieldLabels.hqCity,
  hqCountry: AccountFieldLabels.hqCountry,
  hqPostalCode: AccountFieldLabels.hqPostalCode,
  hqState: AccountFieldLabels.hqState,
  inSam: 'In SAM',
  jobUrl: 'Job URL',
  lastFundingDate: AccountFieldLabels.lastFundingDate,
  linkedInJobCount: AccountFieldLabels.linkedInJobCount,
  mostSimilarTo: 'Most Similar To',
  name: AccountFieldLabels.name,
  oneMonthHeadcountGrowth: AccountFieldLabels.oneMonthHeadcountGrowth,
  oneMonthHiringEngChange: AccountFieldLabels.oneMonthHiringEngChange,
  oneMonthHiringHRChange: AccountFieldLabels.oneMonthHiringHRChange,
  oneMonthHiringProductChange: AccountFieldLabels.oneMonthHiringProductChange,
  oneMonthOpenRolesChange: AccountFieldLabels.oneMonthOpenRolesChange,
  oneMonthRecruitingVelocityChange:
    AccountFieldLabels.oneMonthRecruitingVelocityChange,
  oneMonthTotalFundingChange: AccountFieldLabels.oneMonthTotalFundingChange,
  openRolePercentage: AccountFieldLabels.openRolePercentage,
  org: 'Org Signals',
  primaryCategory: 'Primary Categories',
  profile: 'Profile Signals',
  recruitingVelocity: AccountFieldLabels.recruitingVelocity,
  relevance: 'Relevance Signals',
  secondaryCategory: 'Secondary Categories',
  signals: 'Signals Matched',
  signalScore: 'Signal Score',
  similarityScore: 'Similarity Score',
  sixMonthHeadcountGrowth: AccountFieldLabels.sixMonthGrowth,
  slackCommunityPage: AccountFieldLabels.slackCommunityPage,
  source: 'Source',
  stackFit: 'Stack Signals',
  status: 'Status',
  tags: 'Tags',
  tier: 'Tier',
  timestamp: 'Last Enriched',
  totalFundingRaised: AccountFieldLabels.totalFundingRaised,
  yearFounded: 'Year Founded',

  // Note: These must match the corresponding rollup names.
  iterable_b2bSignals: 'B2B Signals',
  iterable_b2cSignals: 'B2C Signals',
} as const satisfies Record<keyof FieldMappingTypes, string>;

export const FieldMappingFields = keys(FieldMappingLabels);
export const FieldMappingFieldSchema = zodEnum(FieldMappingFields);
export type FieldMappingField = z.infer<typeof FieldMappingFieldSchema>;

// Managed field mappings are automatically created and updated by Keyplay.
const ManagedFieldMappingFields = [
  // Note: The order here determines the order in which fields are displayed in the UI.
  'finalDomain',
  'tier',
  'fit',
  'signals',
  'primaryCategory',
  'secondaryCategory',
  'audience',
  'tags',
  'profile',
  'org',
  'relevance',
  'stackFit',
  'source',
  'status',
  'signalScore',
  'similarityScore',
  'mostSimilarTo',
  'hqCountry',
  'inSam',

  'iterable_b2bSignals',
  'iterable_b2cSignals',
] as const satisfies readonly FieldMappingField[];

export const ManagedFieldMappingFieldSchema = zodEnum(
  ManagedFieldMappingFields
);
export type ManagedFieldMappingField = z.infer<
  typeof ManagedFieldMappingFieldSchema
>;
export const isManagedFieldMappingField = zodTypeguard(
  ManagedFieldMappingFieldSchema
);

// Customer-specific managed fields.
export const OptionalManagedFieldMappingFields = [
  'profile',
  'org',
  'relevance',
  'stackFit',

  'iterable_b2bSignals',
  'iterable_b2cSignals',
] as const satisfies readonly ManagedFieldMappingField[];

// Available to all customers.
export const CoreManagedFieldMappingFields = _.difference(
  ManagedFieldMappingFields,
  OptionalManagedFieldMappingFields
);

export const ManagedFieldMappingCrmNames = {
  audience: 'keyplay_audience',
  finalDomain: 'keyplay_final_domain',
  fit: 'keyplay_fit',
  hqCountry: 'keyplay_hq_country',
  inSam: 'keyplay_in_sam',
  mostSimilarTo: 'keyplay_similar_accounts',
  org: 'keyplay_org_signals',
  primaryCategory: 'keyplay_primary_categories',
  profile: 'keyplay_profile_signals',
  relevance: 'keyplay_relevance_signals',
  secondaryCategory: 'keyplay_secondary_categories',
  signalScore: 'keyplay_signal_score',
  signals: 'keyplay_signals',
  similarityScore: 'keyplay_similarity_score',
  source: 'keyplay_source',
  stackFit: 'keyplay_stack_signals',
  status: 'keyplay_status',
  tags: 'keyplay_tags',
  tier: 'keyplay_tier',

  iterable_b2bSignals: 'keyplay_iterable_b2b_signals',
  iterable_b2cSignals: 'keyplay_iterable_b2c_signals',
} as const satisfies Record<ManagedFieldMappingField, string>;

export const ManagedFieldMappingCrmLabels = {
  audience: 'Keyplay Audience',
  finalDomain: 'Keyplay Final Domain',
  fit: 'Keyplay Fit Score',
  hqCountry: 'Keyplay HQ Country',
  inSam: 'Keyplay In SAM',
  mostSimilarTo: 'Keyplay Most Similar To',
  org: 'Keyplay Org Signals',
  primaryCategory: 'Keyplay Categories',
  profile: 'Keyplay Profile Signals',
  relevance: 'Keyplay Relevance Signals',
  secondaryCategory: 'Keyplay Secondary Categories',
  signals: 'Keyplay Signals',
  signalScore: 'Keyplay Signal Score',
  similarityScore: 'Keyplay Similarity Score',
  source: 'Keyplay Source',
  stackFit: 'Keyplay Stack Signals',
  status: 'Keyplay Status',
  tags: 'Keyplay Tags',
  tier: 'Keyplay Tier',

  iterable_b2bSignals: 'Keyplay B2B Signals',
  iterable_b2cSignals: 'Keyplay B2C Signals',
} as const satisfies Record<ManagedFieldMappingField, string>;

const AdditionalFieldMappingFields = [
  'ats',
  'audience',
  'businessOverview',
  'employeeCount',
  'finalDomain',
  'finalLinkedIn',
  'fit',
  'hiring',
  'hiringEng',
  'hiringSales',
  'hiringSoftwareEng',
  'hqCity',
  'hqCountry',
  'hqPostalCode',
  'hqState',
  'jobUrl',
  'lastFundingDate',
  'linkedInJobCount',
  'mostSimilarTo',
  'name',
  'oneMonthHeadcountGrowth',
  'oneMonthHiringEngChange',
  'oneMonthHiringHRChange',
  'oneMonthHiringProductChange',
  'oneMonthOpenRolesChange',
  'oneMonthRecruitingVelocityChange',
  'oneMonthTotalFundingChange',
  'openRolePercentage',
  'primaryCategory',
  'recruitingVelocity',
  'secondaryCategory',
  'signals',
  'signalScore',
  'similarityScore',
  'sixMonthHeadcountGrowth',
  'slackCommunityPage',
  'status',
  'tags',
  'tier',
  'timestamp',
  'totalFundingRaised',
  'yearFounded',
] as const satisfies readonly FieldMappingField[];

export const AdditionalFieldMappingFieldSchema = zodEnum(
  AdditionalFieldMappingFields
);
export type AdditionalFieldMappingField = z.infer<
  typeof AdditionalFieldMappingFieldSchema
>;
export const isAdditionalFieldMappingField = zodTypeguard(
  AdditionalFieldMappingFieldSchema
);

// Customer-specific additional fields.
export const OptionalAdditionalFieldMappingFields = [
  'linkedInJobCount',
  'slackCommunityPage',
] as const satisfies readonly AdditionalFieldMappingField[];

// Available to all customers.
export const CoreAdditionalFieldMappingFields = _.difference(
  AdditionalFieldMappingFields,
  OptionalAdditionalFieldMappingFields
);

// Managed fields are fully managed by Keyplay.
// Additional fields are managed by the customer.
// - They can either be built-in fields (e.g. employee count) or custom fields.
const FieldMappingManagementTypeSchema = z.union([
  z.literal('managed'),
  z.literal('additional'),
]);
export type FieldMappingManagementType = z.infer<
  typeof FieldMappingManagementTypeSchema
>;

export const MultiMarketFields = [
  'fit',
  'mostSimilarTo',
  'signals',
  'similarityScore',
  'signalScore',
  'tier',
] satisfies readonly AdditionalFieldMappingField[];

const FieldMappingBaseSchema = z.object({
  crmFieldName: z.string(),
  enrichmentFieldType: FieldMappingManagementTypeSchema,
  enabled: z.boolean(),
  kind: z.string(),
  marketId: z.instanceof(ObjectId).optional(),
});

// Managed field mapping types.

const ManagedFieldMappingSchema = FieldMappingBaseSchema.extend({
  enrichmentFieldType: z.literal('managed'),
  enrichmentField: ManagedFieldMappingFieldSchema,
  kind: z.literal('keyplay'),
});
export type ManagedFieldMapping = z.infer<typeof ManagedFieldMappingSchema>;
export function isManagedFieldMapping(
  fieldMapping: FieldMapping
): fieldMapping is ManagedFieldMapping {
  return (
    fieldMapping.enrichmentFieldType === 'managed' &&
    fieldMapping.kind === 'keyplay'
  );
}

// Additional field mapping types.

const FieldOverrideSchema = z.union([
  // Always override value.
  z.literal('always'),
  // Override value if the field is empty.
  z.literal('ifEmpty'),
]);
export type FieldOverride = z.infer<typeof FieldOverrideSchema>;

const AdditionalFieldMappingBaseSchema = FieldMappingBaseSchema.extend({
  enrichmentFieldType: z.literal('additional'),
  override: FieldOverrideSchema,
});

const AdditionalKeyplayFieldMappingSchema =
  AdditionalFieldMappingBaseSchema.extend({
    kind: z.literal('keyplay'),
    enrichmentField: AdditionalFieldMappingFieldSchema,
  });
export type AdditionalKeyplayFieldMapping = z.infer<
  typeof AdditionalKeyplayFieldMappingSchema
>;
export function isAdditionalKeyplayFieldMapping(
  fieldMapping: FieldMapping
): fieldMapping is AdditionalKeyplayFieldMapping {
  return (
    fieldMapping.enrichmentFieldType === 'additional' &&
    fieldMapping.kind === 'keyplay'
  );
}

const CustomFieldMappingSchema = AdditionalFieldMappingBaseSchema.extend({
  kind: z.literal('custom'),
  customFieldId: z.instanceof(ObjectId),
});
export type CustomFieldMapping = z.infer<typeof CustomFieldMappingSchema>;
export function isCustomFieldMapping(
  fieldMapping: FieldMapping
): fieldMapping is CustomFieldMapping {
  return (
    fieldMapping.enrichmentFieldType === 'additional' &&
    fieldMapping.kind === 'custom'
  );
}

const AdditionalFieldMappingSchema = z.union([
  AdditionalKeyplayFieldMappingSchema,
  CustomFieldMappingSchema,
]);
export type AdditionalFieldMapping = z.infer<
  typeof AdditionalFieldMappingSchema
>;
export function isAdditionalFieldMapping(
  fieldMapping: FieldMapping
): fieldMapping is AdditionalFieldMapping {
  return fieldMapping.enrichmentFieldType === 'additional';
}

// Type guard for strictly Keyplay field mappings (managed or additional).
export function isKeyplayFieldMapping(
  fieldMapping: FieldMapping
): fieldMapping is AdditionalKeyplayFieldMapping | ManagedFieldMapping {
  return fieldMapping.kind === 'keyplay';
}

export const FieldMappingSchema = z.union([
  AdditionalFieldMappingSchema,
  ManagedFieldMappingSchema,
]);
export type FieldMapping = z.infer<typeof FieldMappingSchema>;

export const OneWaySyncSettingSchema = z.object({
  type: z.literal('oneWay'),
});
export type OneWaySyncSetting = z.infer<typeof OneWaySyncSettingSchema>;

export const TwoWaySyncSettingSchema = z.object({
  type: z.literal('twoWay'),
  pushSavedAccounts: z.boolean(),
});
export type TwoWaySyncSetting = z.infer<typeof TwoWaySyncSettingSchema>;

export const SelectiveTwoWaySyncSettingSchema = z.object({
  type: z.literal('selectiveTwoWay'),
  pushSavedAccounts: z.boolean(),
});
export type SelectiveTwoWaySyncSetting = z.infer<
  typeof SelectiveTwoWaySyncSettingSchema
>;

export const SyncSettingsSchema = z.union([
  OneWaySyncSettingSchema,
  TwoWaySyncSettingSchema,
  SelectiveTwoWaySyncSettingSchema,
]);
export type SyncSettings = z.infer<typeof SyncSettingsSchema>;
export type SyncType = SyncSettings['type'];

export const IntegrationConfigSchema = z.object({
  autoSync: z.boolean().optional(),
  syncSettings: SyncSettingsSchema.optional(),
  fieldMapping: z.array(FieldMappingSchema).optional(),
  optionalFields: z.array(FieldMappingFieldSchema).optional(),
});
export type IntegrationConfig = z.infer<typeof IntegrationConfigSchema>;

// IntegrationStatus Schema
export const IntegrationStatusSchema = z.enum([
  'active',
  'disconnected',
  'refresh_token_expired',
]);
export type IntegrationStatus = z.infer<typeof IntegrationStatusSchema>;

export const CrmSyncDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
] as const;
export const CrmSyncDaySchema = zodEnum(CrmSyncDays);
export type CrmSyncDay = z.infer<typeof CrmSyncDaySchema>;

export const BaseOAuthDataSchema = z.object({
  issuedAt: z.date(),
  refreshToken: z.string(),
  tokenType: z.string(),
});

export const IntegrationTypeSchema = z.union([
  z.literal('hubspot'),
  z.literal('salesforce'),
]);
export type IntegrationType = z.infer<typeof IntegrationTypeSchema>;
export const isIntegrationType = zodTypeguard(IntegrationTypeSchema);

const BaseCrmIntegrationSchema = z.object({
  id: z.instanceof(ObjectId),
  timestamp: z.date(),
  type: IntegrationTypeSchema,

  oauth: BaseOAuthDataSchema,
  crmSyncDay: CrmSyncDaySchema,
  status: IntegrationStatusSchema,

  config: IntegrationConfigSchema.optional(),
  lastEnrichment: z.date().optional(),
});

const SalesforceIntegrationSchema = BaseCrmIntegrationSchema.extend({
  type: z.literal('salesforce'),
  accountCreationBatchSize: z.number().optional(),
  oauth: BaseOAuthDataSchema.extend({
    id: z.string(),
    instanceUrl: z.string(),
    scope: z.string(),
    signature: z.string(),
    isSandbox: z.boolean().optional(),
  }),
});

const HubSpotIntegrationSchema = BaseCrmIntegrationSchema.extend({
  type: z.literal('hubspot'),
  oauth: BaseOAuthDataSchema.extend({
    expiresIn: z.number(),
  }),
});

export type SalesforceIntegration = z.infer<typeof SalesforceIntegrationSchema>;
export type HubSpotIntegration = z.infer<typeof HubSpotIntegrationSchema>;

export type CrmIntegration = SalesforceIntegration | HubSpotIntegration;

export interface CrmField {
  name: string; // The unique name of the field.
  label: string; // The display label of the field.
  type:
    | 'binary'
    | 'boolean'
    | 'date'
    | 'dateTime'
    | 'number'
    | 'string'
    | 'time';
}

export const AllowedCrmFieldTypes = [
  'date',
  'dateTime',
  'number',
] as const satisfies CrmField['type'][];
type AllowedCrmFieldType = (typeof AllowedCrmFieldTypes)[number];

export const isAllowedCrmFieldType = (
  type: CrmField['type']
): type is AllowedCrmFieldType =>
  AllowedCrmFieldTypes.includes(type as AllowedCrmFieldType);

const EnrichErrorSchema = z.object({
  type: z.literal('enrichError'),
  numFailedAccounts: z.number(),
});
export const SalesforceEnrichErrorSchema = EnrichErrorSchema.extend({
  details: z.object({
    integration: z.literal('salesforce'),
    jobId: z.string(),
  }),
});
export const PushSavedAccountsErrorSchema = z.object({
  type: z.literal('pushSavedAccountsError'),
  error: z.string(),
  numFailedAccounts: z.number(),
});
export const UnexpectedErrorSchema = z.object({
  type: z.literal('unexpectedError'),
  error: z.string(),
});

export const IntegrationErrorSchema = z.union([
  SalesforceEnrichErrorSchema,
  PushSavedAccountsErrorSchema,
  UnexpectedErrorSchema,
]);
export type IntegrationError = z.infer<typeof IntegrationErrorSchema>;
export const isIntegrationError = zodTypeguard(IntegrationErrorSchema);

const IntegrationRunSchema = z.object({
  customerId: z.instanceof(ObjectId),
  integrationId: z.instanceof(ObjectId),
  errors: IntegrationErrorSchema.array(),
  status: z.union([z.literal('success'), z.literal('failure')]),
  taskId: z.instanceof(ObjectId),
  timestamp: z.date(),
});
export type IntegrationRun = z.infer<typeof IntegrationRunSchema>;
