import React from 'react';
import {FormControl, FormLabel, FormHelperText} from '@chakra-ui/react';
import Select from 'react-select';
import {
  AiFieldDefinition,
  AiFieldDefinitionConfig,
  AnalysisField,
  FieldDefinition,
  isCustomerAnalysisField,
  KeyplayAnalysisFieldLabels,
  KeyplayAnalysisFields,
} from '../../../shared/enrichment';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {useBuilderMode} from './BuilderModeContext';
import {useCustomer} from '../../../hooks/api/metadata';

const getAnalysisFieldOption = (
  analysisField: AnalysisField,
  fields: FieldDefinition[]
) =>
  isCustomerAnalysisField(analysisField)
    ? {
        label:
          fields.find((field) => field.id.equals(analysisField.fieldId))
            ?.label ?? 'Unknown Field',
        value: analysisField,
      }
    : {
        label: KeyplayAnalysisFieldLabels[analysisField],
        value: analysisField,
      };

const KeyplayAnalysisFieldOptions = KeyplayAnalysisFields.map((field) =>
  getAnalysisFieldOption(field, [])
);

const isResearchAiField = (
  field: FieldDefinition
): field is AiFieldDefinition => {
  return field.type === 'ai' && field.config.fieldType === 'research';
};

export const AnalysisFieldsEditor = ({
  config,
}: {
  config: AiFieldDefinitionConfig;
}) => {
  const mode = useBuilderMode();
  const {serverState, setConfig} = useAiFieldBuilderStore();
  const fieldDefinitions = useCustomer().fieldDefinitions ?? [];
  const researchFieldOptions = fieldDefinitions
    .filter((field) => !field.id.equals(serverState.id))
    .filter(isResearchAiField)
    .map((field) =>
      getAnalysisFieldOption({fieldId: field.id}, fieldDefinitions)
    );

  const groupedOptions = [
    {label: 'Research Fields', options: researchFieldOptions},
    {label: 'Keyplay Fields', options: KeyplayAnalysisFieldOptions},
  ];

  return (
    <FormControl>
      <FormLabel textTransform="uppercase">
        Answer based on analysis of
      </FormLabel>
      <Select
        isDisabled={mode === 'review'}
        isMulti={true}
        onChange={(selectedOptions) => {
          setConfig({
            ...config,
            analysisFields: selectedOptions.map(({value}) => value),
          });
        }}
        isOptionDisabled={(option) =>
          isCustomerAnalysisField(option.value) &&
          config.analysisFields.some((field) => isCustomerAnalysisField(field))
        }
        options={groupedOptions}
        placeholder="Select fields"
        value={config.analysisFields.map((field) =>
          getAnalysisFieldOption(field, fieldDefinitions ?? [])
        )}
        getOptionValue={(option) =>
          isCustomerAnalysisField(option.value)
            ? option.value.fieldId.toString()
            : option.value
        }
      />
      <FormHelperText textColor="kgray.300">
        Your AI Analyst will look at fields you already have in Keyplay in order
        to answer your question. Add as many fields as are relevant.
      </FormHelperText>
    </FormControl>
  );
};
