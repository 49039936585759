import React, {useEffect, useMemo, useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Button,
  useModalContext,
} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {
  AiFieldBuilderStep,
  useAiFieldBuilderNavStore,
} from './AiFieldBuilderNav.state';
import {WrapperFlex} from '../../utils/scrolling';
import {
  AiFieldBuilderCreateField,
  AiFieldBuilderEditField,
} from './AiFieldBuilderDefineFieldStep';
import {AiFieldBuilderSelectAccountsStep} from './AiFieldBuilderSelectAccountsStep';
import {AiFieldBuilderPreviewResultsStep} from './AiFieldBuilderPreviewResultsStep';
import {BuilderModalHeader} from '../../Ai/BuilderModalHeader';
import {
  FieldDefinitionProvider,
  useFieldDefinitionFromContext,
} from './FieldDefinitionContext';
import {ObjectId} from 'bson';
import {
  BuilderMode,
  BuilderModeProvider,
  useBuilderMode,
} from './BuilderModeContext';
import {AiFieldBuilderAnalyzeListsStep} from './AnalyzeListsStep/AiFieldBuilderAnalyzeListsStep';
import {AiFieldBuilderAccountFilterStep} from './AiFieldBuilderAccountFilterStep';
import {AiFieldBuilderReviewSettingsStep} from './AiFieldBuilderReviewSettingsStep';
import {BuilderTitleSection} from '../../Ai/BuilderTitleSection';

export const AiFieldBuilderModal = ({
  fieldDefinitionId,
  isOpen,
  onClose,
  mode,
}: {
  fieldDefinitionId?: ObjectId;
  isOpen: boolean;
  onClose: () => void;
  mode: BuilderMode;
}) => {
  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <BuilderModeProvider mode={mode}>
          <AiFieldBuilderContainer fieldDefinitionId={fieldDefinitionId} />
        </BuilderModeProvider>
      </ModalContent>
    </Modal>
  );
};

const AiFieldBuilderContainer = ({
  fieldDefinitionId,
}: {
  fieldDefinitionId?: ObjectId;
}) => {
  const [localFieldDefinitionId, setLocalFieldDefinitionId] = useState<
    ObjectId | undefined
  >(fieldDefinitionId);

  return (
    <>
      {localFieldDefinitionId ? (
        <FieldDefinitionProvider fieldDefinitionId={localFieldDefinitionId}>
          <AiFieldBuilderModalContent />
        </FieldDefinitionProvider>
      ) : (
        <>
          <BuilderModalHeader />
          <ModalBody as={Flex} flexDirection="column" p="0">
            <BuilderTitleSection title="AI Field Builder" />
            <Box flex="1" position="relative">
              <WrapperFlex>
                <AiFieldBuilderCreateField
                  onContinue={(fieldDefinition) => {
                    setLocalFieldDefinitionId(fieldDefinition.id);
                  }}
                />
              </WrapperFlex>
            </Box>
          </ModalBody>
          <ModalCloseButton />
        </>
      )}
    </>
  );
};

const AiFieldBuilderModalContent = () => {
  const {publishChanges, updateServerState, serverState} =
    useAiFieldBuilderStore();
  const {step, setStep} = useAiFieldBuilderNavStore();
  const {onClose} = useModalContext();
  const mode = useBuilderMode();
  const fieldId = serverState.id.toString();

  // reset the step when the builder is opened with a new field
  useEffect(() => {
    setStep('selectAccounts');
  }, [fieldId, setStep]);

  const fieldDefinition = useFieldDefinitionFromContext();
  // Update the server state if the field definition has changed
  useEffect(() => {
    updateServerState({fieldDefinition, updateMode: 'ifNoChanges'});
  }, [fieldDefinition, updateServerState]);

  const Steps = useMemo(
    () =>
      ({
        defineField: (
          <AiFieldBuilderEditField
            onContinue={() => setStep('selectAccounts')}
          />
        ),
        selectAccounts: <AiFieldBuilderSelectAccountsStep />,
        previewResults: <AiFieldBuilderPreviewResultsStep />,
        analyzeLists: <AiFieldBuilderAnalyzeListsStep />,
        accountFilter: <AiFieldBuilderAccountFilterStep />,
        reviewSettings: <AiFieldBuilderReviewSettingsStep />,
      }) satisfies Record<AiFieldBuilderStep, React.ReactNode>,
    [setStep]
  );

  return (
    <>
      <BuilderModalHeader />
      <ModalBody as={Flex} flexDirection="column" p="0">
        <BuilderTitleSection
          fieldLabel={serverState.label}
          title="AI Field Builder"
        >
          <Button
            colorScheme="kbuttonblue"
            isLoading={publishChanges.isPending}
            onClick={() => publishChanges.mutate('save', {onSuccess: onClose})}
            variant="outline"
          >
            {mode === 'review' ? 'Save & Exit' : 'Save Draft & Exit'}
          </Button>
        </BuilderTitleSection>
        <Box flex="1" position="relative">
          <WrapperFlex>{Steps[step]}</WrapperFlex>
        </Box>
      </ModalBody>
      <ModalCloseButton />
    </>
  );
};
