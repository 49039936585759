import React, {useMemo} from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Tab,
  TabList,
  Tabs,
  Link as ChakraLink,
} from '@chakra-ui/react';
import {PageTitle} from '../PageTitle';
import {Navigate, Route, Routes, matchPath, useLocation} from 'react-router';
import Api from '../Api';
import CallbackHandling from '../Integrations/CallbackHandling';
import {Link} from 'react-router-dom';
import {
  Bot,
  Code,
  List,
  SettingsAdjust,
  Share,
  TextAlignLeft,
  User,
  UserMultiple,
} from '@carbon/icons-react';
import {IntegrationDetails} from '../Integrations/IntegrationDetails';
import IntegrationsList from '../Integrations/Integrations';
import {useFeatures} from '../../hooks/api/metadata';
import {Account} from './Account';
import {Users} from './Users';
import {useIsFreemiumCustomer} from '../../hooks/api/metadata';
import {Fields} from './Fields';
import {Signals} from './Signals';
import {Title} from '../Title';
import {TestLists} from './TestLists/TestLists';
import {useIsKeyplayAdmin} from '../../hooks/api/user';
import {Agents} from './Agents/Agents';
import {PlanAndCredits} from './PlanAndCredits';
import {Credits} from '../Icons/Credits';

function useTabGroups() {
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const isKeyplayAdmin = useIsKeyplayAdmin();

  return useMemo(
    () =>
      [
        {
          section: 'Your Settings',
          tabs: [
            {
              label: 'Account',
              path: 'account',
              element: <Account />,
              icon: <User />,
              hidden: false,
            },
          ],
        },
        {
          section: 'Team Settings',
          tabs: [
            {
              label: 'Plan & Credits',
              path: 'plan',
              element: <PlanAndCredits />,
              icon: <Credits />,
              hidden: false,
            },
            {
              label: 'Users',
              path: 'users',
              element: <Users />,
              icon: <UserMultiple />,
              hidden: false,
            },
            {
              label: 'Integrations',
              path: 'integrations',
              element: <IntegrationsList />,
              icon: <Share />,
              hidden: isFreemiumCustomer,
            },
            {
              label: 'Agents',
              path: 'agents',
              element: <Agents />,
              icon: <Bot />,
              hidden: !isKeyplayAdmin,
            },
            {
              label: 'Fields',
              path: 'fields',
              element: <Fields />,
              icon: <List />,
              hidden: isFreemiumCustomer,
            },
            {
              label: 'Signals',
              path: 'signals',
              element: <Signals />,
              icon: <SettingsAdjust />,
              hidden: isFreemiumCustomer,
            },
            {
              label: 'Test Lists',
              path: 'testLists',
              element: <TestLists />,
              icon: <TextAlignLeft />,
              hidden: isFreemiumCustomer,
            },
            {
              label: 'API',
              path: 'api',
              element: <Api />,
              icon: <Code />,
              hidden: isFreemiumCustomer,
            },
          ],
        },
      ].map((group) => ({
        ...group,
        tabs: group.tabs.filter((tab) => !tab.hidden),
      })),
    [isFreemiumCustomer, isKeyplayAdmin]
  );
}

const Settings = () => {
  return (
    <Routes>
      <Route path="integrations/callback" element={<CallbackHandling />} />
      <Route
        path="integrations/:integration"
        element={<IntegrationDetails />}
      />
      <Route path="/*" element={<AccountSettings />} />
    </Routes>
  );
};

const AccountSettings = () => {
  const location = useLocation();
  const tabGroups = useTabGroups();
  const selectedIndex = tabGroups
    .map((group) => group.tabs)
    .flat()
    .findIndex((tab) => matchPath(`/settings/${tab.path}`, location.pathname));

  const features = useFeatures();
  const hasAllFeatures = (['apiKey', 'hubspot', 'salesforce'] as const).every(
    (feature) => features.includes(feature)
  );

  return (
    <Box>
      <Flex bg="kgray.100" px={8} h={20} alignItems="center">
        <PageTitle my={0}>Account & Settings</PageTitle>
      </Flex>
      {!hasAllFeatures && (
        <Box mx={4} mb={2}>
          <Alert borderRadius={4} status="warning">
            <AlertIcon />
            <Box>
              Looking for additional integrations and API access?
              <ChakraLink
                color="kblue.400"
                href="mailto:hello@keyplay.io?subject=New Integration Request"
                ms={2}
                target="_blank"
              >
                Contact us to learn more
              </ChakraLink>
              .
            </Box>
          </Alert>
        </Box>
      )}
      <Flex gap={8} maxW={'1200px'} my={8} mx="auto" px="2">
        <Tabs
          h="fit-content"
          index={selectedIndex}
          orientation="vertical"
          variant="unstyled"
        >
          <TabList as={Flex} gap="8" width="200px">
            {tabGroups.map(({section, tabs}) => {
              return (
                <Box key={section}>
                  <Box
                    fontSize="sm"
                    fontWeight="normal"
                    color="kgray.300"
                    textTransform="uppercase"
                    mb={4}
                  >
                    {section}
                  </Box>
                  {tabs.map((tab) => (
                    <Tab
                      as={Link}
                      color="kgray.300"
                      fontWeight="500"
                      to={tab.path}
                      key={tab.path}
                      justifyContent="start"
                      _hover={{
                        color: 'kgray.400',
                      }}
                      _selected={{
                        bgColor: 'kgray.100',
                        color: 'kgray.400',
                      }}
                    >
                      <Box mr={4}>{tab.icon}</Box>
                      {tab.label}
                    </Tab>
                  ))}
                </Box>
              );
            })}
          </TabList>
        </Tabs>

        <Box marginEnd={4} flex="1">
          <Routes>
            {tabGroups.map((group) => {
              return group.tabs.map((tab, index) => {
                return (
                  <Route
                    key={index}
                    path={tab.path}
                    element={<Title title={tab.label}>{tab.element}</Title>}
                  />
                );
              });
            })}
            <Route path="/*" element={<Navigate to="account" />} />
          </Routes>
        </Box>
      </Flex>
    </Box>
  );
};

export default Settings;
