import {Box, Image, Text} from '@chakra-ui/react';
import React, {useState} from 'react';
import Select, {
  DropdownIndicatorProps,
  PropsValue,
  StylesConfig,
} from 'react-select';
import {useDebounce} from 'usehooks-ts';
import {useAccountTypeahead} from '../hooks/api/accountTypeahead';
import {useIsFreemiumCustomer} from '../hooks/api/metadata';
import {AccountWithLogo} from '../shared/scoredAccounts';
import {SearchDropdownIndicator} from './SearchDropdownIndicator';

export interface AccountTypeaheadProps {
  defaultAccount?: AccountWithLogo;
  onAccountSelect: (account: AccountWithLogo) => void;
  placeholder?: string;
  isDisabled?: boolean;
}

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #EBE8EA',
    borderRadius: '10px',
    cursor: 'text',
    flexDirection: state.hasValue ? 'row' : 'row-reverse',
    padding: '0px',
    margin: '0px',
    fontSize: '16px',
    height: '58px',
    fontWeight: '300',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: '300',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0, // remove padding around the list
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
} satisfies StylesConfig<{value: AccountWithLogo}>;

const DropdownIndicator = ({
  selectProps,
}: DropdownIndicatorProps<{value: AccountWithLogo}>) =>
  selectProps.value ? <></> : <SearchDropdownIndicator />;

const formatOptionLabel = (
  data: {value: AccountWithLogo},
  {context}: {context: string}
) => (
  <Box display="flex" alignItems="center" ml="1" mt="0">
    <Image
      src={data.value.logoUrl}
      alt="icon"
      boxSize="40px"
      mr="2"
      fallbackSrc="/accountFallback.png"
    />
    {context === 'value' ? (
      <Text fontWeight="500" fontSize="20px">
        {data.value.name}
      </Text>
    ) : (
      <Text fontWeight="thin" fontSize="14px">
        {data.value.name}
      </Text>
    )}
  </Box>
);

export const SingleAccountTypeahead: React.FC<AccountTypeaheadProps> = ({
  defaultAccount,
  onAccountSelect,
  placeholder = 'Start typing to pick an account...',
  isDisabled,
}) => {
  const [search, setSearch] = useState('');
  const [displayedAccount, setDisplayedAccount] = useState<
    PropsValue<{value: AccountWithLogo}> | undefined
  >(defaultAccount ? {value: defaultAccount} : undefined);
  const [selectedAccount, setSelectedAccount] = useState<
    AccountWithLogo | undefined
  >(defaultAccount);
  const debouncedSearch = useDebounce<string>(search, 200);
  const accountTypeaheadQuery = useAccountTypeahead(debouncedSearch);
  const isFreemium = useIsFreemiumCustomer();

  const typeaheadAccounts = accountTypeaheadQuery.data ?? [];

  return (
    <Select
      menuPortalTarget={document.body}
      placeholder={placeholder}
      value={displayedAccount}
      noOptionsMessage={() => null}
      styles={customSelectStyles}
      components={{DropdownIndicator, IndicatorSeparator: null}}
      formatOptionLabel={formatOptionLabel}
      inputValue={search}
      onInputChange={(val: string) => setSearch(val)}
      isMulti={false}
      isClearable={true}
      isDisabled={isDisabled !== undefined ? isDisabled : isFreemium}
      hideSelectedOptions={true}
      controlShouldRenderValue={true}
      options={typeaheadAccounts.map((account) => ({
        value: account,
        label: account.domain,
      }))}
      onChange={(selected) => {
        setDisplayedAccount(selected);
        if (selected?.value) {
          setSelectedAccount(selected?.value);
          onAccountSelect(selected.value);
        }
      }}
      onBlur={() => {
        if (selectedAccount) {
          setDisplayedAccount({value: selectedAccount});
        }
      }}
    />
  );
};
