import React from 'react';
import {Box, Button, Flex, Grid, Link, Text, useToast} from '@chakra-ui/react';
import {
  CrmField,
  FieldMappingLabels,
  MultiMarketFields,
  AdditionalFieldMapping,
  FieldOverride,
  AdditionalFieldMappingField,
  CoreAdditionalFieldMappingFields,
  OptionalAdditionalFieldMappingFields,
  isAdditionalFieldMappingField,
} from '../../shared/integrations';
import {AddAlt, ChevronRight, TrashCan} from '@carbon/icons-react';
import {UseQueryResult} from '@tanstack/react-query';
import {useIntegrationDetailsState} from './IntegrationDetails.state';
import {DistributedOmit} from '../../shared/util';
import {useCustomer, useMarkets} from '../../hooks/api/metadata';
import {GridCell} from './components';
import {IntegrationSection} from './components';
import {GridHeader} from './components';
import Select, {OptionProps, components} from 'react-select';
import {FieldDefinition, isRestrictedField} from '../../shared/enrichment';
import _ from 'lodash';
import {Market} from '../../shared/market';
import {useCrmFields} from '../../hooks/api/integrations';
import {
  useIntegrationFromContext,
  useIntegrationStaticInfo,
} from './IntegrationDetailsContext';

export const AdditionalEnrichmentFieldMapping = () => {
  const integration = useIntegrationFromContext();
  const integrationInfo = useIntegrationStaticInfo();

  const {addAdditionalField, additionalFieldMappings, serverState} =
    useIntegrationDetailsState();
  const crmFieldsResult = useCrmFields(integration.type);
  const markets = useMarkets();
  const customer = useCustomer();
  const toast = useToast();

  const availableFieldMappingFields = CoreAdditionalFieldMappingFields.concat(
    _.intersection(
      (serverState?.config?.optionalFields ?? []).filter(
        isAdditionalFieldMappingField
      ),
      OptionalAdditionalFieldMappingFields
    )
  );

  // Use for efficient lookups of existing fields
  const crmFieldLookups = new Set(
    additionalFieldMappings.map(({crmFieldName}) => crmFieldName)
  );

  // These are all the enrichment field options to show in the select dropdown
  const fieldMappingOptions = getAllFieldConfigOptions(
    availableFieldMappingFields,
    additionalFieldMappings,
    markets,
    // TODO: support field mapping for restricted fields
    (customer.fieldDefinitions ?? []).filter(
      (field) => !isRestrictedField(field)
    )
  );

  // These are all the CRM field options to show in the select dropdown
  const crmFieldOptions =
    crmFieldsResult.data?.map((field) => ({
      isDisabled: crmFieldLookups.has(field.name),
      label: field.label,
      value: field.name,
    })) ?? [];

  // When the user adds a new field mapping, we select the first available enrichment field
  // and set the CRM field to an empty string so the placeholder text is displayed.
  const addNewFieldMapping = () => {
    const firstAvailableField = fieldMappingOptions.find(
      ({isDisabled}) => !isDisabled
    );

    if (!firstAvailableField) {
      toast({
        title: 'Unable to add new field mapping',
        description: 'Contact Keyplay support for assistance.',
        status: 'error',
      });
      return;
    }

    const {fieldMapping} = firstAvailableField;
    addAdditionalField({
      ...fieldMapping,
      enabled: true,
      override: 'always',
    });
  };

  const hasMoreFieldsToAdd =
    additionalFieldMappings.length < fieldMappingOptions.length;

  return (
    <IntegrationSection>
      <Box fontSize={'lg'} fontWeight={500} mb={6}>
        Additional Field Mapping
      </Box>
      <Grid templateColumns={'70px 1fr min-content 1fr 220px'} gap={2}>
        {additionalFieldMappings.length > 0 && (
          <>
            <GridHeader></GridHeader>
            <GridHeader>Keyplay Field</GridHeader>
            <GridHeader></GridHeader>
            <GridHeader>{integrationInfo.label} Field</GridHeader>
            <GridHeader ms="4">Override?</GridHeader>
          </>
        )}

        {additionalFieldMappings.length === 0 && (
          <GridCell colSpan={6} px={0}>
            <Text fontStyle={'italic'}>No field mappings configured.</Text>
          </GridCell>
        )}

        {additionalFieldMappings.map((selectedFieldMapping, index) => (
          <FieldMappingGridRow
            key={index}
            crmFieldOptions={crmFieldOptions}
            crmFieldsResult={crmFieldsResult}
            fieldMappingOptions={fieldMappingOptions}
            selectedFieldMapping={selectedFieldMapping}
          />
        ))}

        {hasMoreFieldsToAdd && (
          <GridCell colSpan={2} px={2}>
            <Link
              alignItems={'center'}
              as={Flex}
              color="kblue.300"
              fontWeight={500}
              gap={2}
              onClick={addNewFieldMapping}
            >
              <AddAlt />
              Add a field
            </Link>
          </GridCell>
        )}
      </Grid>
    </IntegrationSection>
  );
};

function getAllFieldConfigOptions(
  fieldMappingFields: AdditionalFieldMappingField[],
  fieldMappings: AdditionalFieldMapping[],
  markets: Market[],
  fieldDefinitions: FieldDefinition[]
) {
  return [
    // For each additional enrichment field we create an option, except
    // for the multi-market fields, which we handle separately.
    ..._.difference(fieldMappingFields, MultiMarketFields).map((field) => {
      const existingFieldMapping = fieldMappings.find(
        (fieldMapping) =>
          fieldMapping.kind === 'keyplay' &&
          fieldMapping.enrichmentField === field
      );

      return getFieldMappingOption({
        existingFieldMapping,
        defaultFieldMapping: {
          enrichmentField: field,
          kind: 'keyplay',
        },
        label: FieldMappingLabels[field],
        value: field,
      });
    }),
    // For each multi-market field, we create an option for each market
    ...MultiMarketFields.map((field) => {
      return markets.map((market) => {
        const existingFieldMapping = fieldMappings.find(
          (fieldMapping) =>
            fieldMapping.kind === 'keyplay' &&
            fieldMapping.enrichmentField === field &&
            fieldMapping.marketId?.equals(market.id)
        );
        const label =
          markets.length > 0
            ? market.label + ' ' + FieldMappingLabels[field]
            : FieldMappingLabels[field];

        return getFieldMappingOption({
          existingFieldMapping,
          defaultFieldMapping: {
            enrichmentField: field,
            kind: 'keyplay',
            marketId: market.id,
          },
          label,
          value: field + market.id,
        });
      });
    }).flat(),
    // Create an option for each custom field
    ...(fieldDefinitions.map(({id, label}) => {
      const existingFieldMapping = fieldMappings.find(
        (fieldMapping) =>
          fieldMapping.kind === 'custom' &&
          fieldMapping.customFieldId.equals(id)
      );

      return getFieldMappingOption({
        existingFieldMapping,
        defaultFieldMapping: {
          customFieldId: id,
          kind: 'custom',
        },
        label: label,
        value: id.toString(),
      });
    }) ?? []),
  ].sort((a, b) => a.label.localeCompare(b.label));
}

function getFieldMappingOption({
  existingFieldMapping,
  defaultFieldMapping,
  label,
  value,
}: {
  existingFieldMapping?: AdditionalFieldMapping;
  defaultFieldMapping: DistributedOmit<
    AdditionalFieldMapping,
    'enabled' | 'override' | 'enrichmentFieldType' | 'crmFieldName'
  >;
  label: string;
  value: string;
}): FieldMappingOption {
  return {
    label,
    value,
    isDisabled: !!existingFieldMapping,
    fieldMapping: existingFieldMapping ?? {
      ...defaultFieldMapping,
      enabled: true,
      override: 'always',
      enrichmentFieldType: 'additional',
      crmFieldName: '',
    },
  };
}

type BaseOption = {
  isDisabled: boolean;
  label: string;
  value: string;
};
type FieldMappingOption = BaseOption & {
  fieldMapping: AdditionalFieldMapping;
};

type EnrichmentFieldGridRowProps = {
  crmFieldOptions: BaseOption[];
  crmFieldsResult: UseQueryResult<CrmField[]>;
  fieldMappingOptions: FieldMappingOption[];
  selectedFieldMapping: AdditionalFieldMapping;
};

interface OverrideSelectOption {
  value: FieldOverride;
  label: string;
  description: string;
}

const OverrideOption = (props: OptionProps<OverrideSelectOption>) => {
  const {label, description} = props.data;
  return (
    <components.Option {...props}>
      <Text>{label}</Text>
      <Text fontSize="small" mt="2" fontWeight="thin" color="kgray.300">
        {description}
      </Text>
    </components.Option>
  );
};

const FieldMappingGridRow = ({
  crmFieldOptions,
  crmFieldsResult,
  fieldMappingOptions,
  selectedFieldMapping,
}: EnrichmentFieldGridRowProps) => {
  const integrationInfo = useIntegrationStaticInfo();
  const {deleteAdditionalField, updateAdditionalField, publishChanges} =
    useIntegrationDetailsState();

  const onUpdateSelectedFieldMapping = (
    oldFieldMapping: AdditionalFieldMapping,
    updatedFieldMapping: AdditionalFieldMapping
  ) => {
    updateAdditionalField(oldFieldMapping, updatedFieldMapping);

    if (updatedFieldMapping.crmFieldName) {
      publishChanges.mutate();
    }
  };

  const onDeleteSelectedField = (deletedField: AdditionalFieldMapping) => {
    deleteAdditionalField(deletedField);
    publishChanges.mutate();
  };

  let crmFieldValue = '';
  if (crmFieldsResult.isPending) {
    crmFieldValue = 'Loading...';
  } else if (selectedFieldMapping.crmFieldName) {
    const crmField = crmFieldsResult.data?.find(
      (field) => field.name === selectedFieldMapping.crmFieldName
    );
    // It's possible we have a field mapping for a field that no longer exists in HubSpot
    crmFieldValue = crmField?.label ?? 'unknown field';
  }

  const overrideOptions = [
    {
      value: 'always',
      label: 'Always override',
      description: 'Always override this field',
    },
    {
      value: 'ifEmpty',
      label: 'No override',
      description: 'Only write to this field if blank',
    },
  ] satisfies OverrideSelectOption[];

  return (
    <>
      <GridCell p={0} justifyContent={'center'}>
        <Button
          colorScheme="red"
          fontSize={14}
          onClick={() => {
            onDeleteSelectedField(selectedFieldMapping);
          }}
          variant="outline"
        >
          <TrashCan />
        </Button>
      </GridCell>

      <GridCell p={0}>
        <Select
          hideSelectedOptions={true}
          menuPlacement="top"
          onChange={(selectedOption) => {
            if (!selectedOption) {
              return;
            }

            const {fieldMapping} = selectedOption;
            onUpdateSelectedFieldMapping(selectedFieldMapping, {
              ...fieldMapping,
              crmFieldName: selectedFieldMapping.crmFieldName,
            });
          }}
          options={fieldMappingOptions}
          styles={{
            container: (base) => ({
              ...base,
              flexGrow: 1,
            }),
          }}
          value={fieldMappingOptions.find((option) =>
            _.isEqual(option.fieldMapping, selectedFieldMapping)
          )}
        />
      </GridCell>
      <GridCell>
        <ChevronRight />
      </GridCell>
      <GridCell p={0}>
        <Select
          hideSelectedOptions={true}
          menuPlacement="top"
          onChange={(selectedOption) => {
            if (!selectedOption) {
              return;
            }

            onUpdateSelectedFieldMapping(selectedFieldMapping, {
              ...selectedFieldMapping,
              crmFieldName: selectedOption.value,
            });
          }}
          options={crmFieldOptions}
          placeholder={`Select a ${integrationInfo.label} field`}
          styles={{
            container: (base) => ({
              ...base,
              flexGrow: 1,
            }),
          }}
          isLoading={crmFieldsResult.isPending}
          value={
            crmFieldValue && {
              label: crmFieldValue,
              value: selectedFieldMapping.crmFieldName,
            }
          }
        />
      </GridCell>
      <GridCell p={0} ms="4">
        <Select
          components={{Option: OverrideOption}}
          isMulti={false}
          isSearchable={false}
          menuPlacement="top"
          options={overrideOptions}
          placeholder={'Select...'}
          value={overrideOptions.find(
            (o) => o.value === selectedFieldMapping.override
          )}
          onChange={(selectedOption) => {
            if (!selectedOption) {
              return;
            }

            onUpdateSelectedFieldMapping(selectedFieldMapping, {
              ...selectedFieldMapping,
              override: selectedOption.value,
            });
          }}
          styles={{
            container: (base) => ({
              ...base,
              flexGrow: 1,
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused ? '#EDF2FD' : '',
              color: 'inherit',
              ':hover': {
                backgroundColor: '#EDF2FD',
              },
            }),
          }}
        />
      </GridCell>
    </>
  );
};
