import {create} from 'zustand';
import {
  AiFieldDefinition,
  AiFieldDefinitionConfig,
  isAiFieldDefinition,
} from '../../../shared/enrichment';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
  useCreateFieldDefinition,
  useSaveFieldDefinition,
} from '../../../hooks/api/fieldDefinitions';
import {assertExists, assertNever, DistributedOmit} from '../../../shared/util';
import {ObjectId} from 'bson';
import {useAgentBuilderStore} from './AgentBuilder.state';
import _ from 'lodash';
import {EnrichmentPreviewResultQueryKeys} from '../../../hooks/api/fieldDefinitionPreview';

interface FieldDefinitionEditorState {
  serverState: AiFieldDefinition | undefined;
  updateServerState: ({
    serverState,
    updateMode,
  }: {
    serverState: AiFieldDefinition;
    updateMode: 'force' | 'initialLoad';
  }) => void;

  hasChanges: () => boolean;
  reset: () => void;

  label: string;
  setLabel: (label: string) => void;

  config: AiFieldDefinitionConfig | undefined;
  setConfig: (config: AiFieldDefinitionConfig | undefined) => void;
}

const useFieldDefinitionEditorState = create<FieldDefinitionEditorState>()(
  (set, get) => ({
    serverState: undefined,
    updateServerState: ({serverState, updateMode}) => {
      if (get().serverState && updateMode === 'initialLoad') {
        return;
      }

      set({
        serverState,
        label: serverState.label,
        config: serverState.config,
      });
    },

    hasChanges: () => {
      const {serverState, label, config} = get();
      if (!serverState) {
        return !!label || !!config;
      }

      return (
        serverState.label !== label || !_.isEqual(serverState.config, config)
      );
    },
    reset: () =>
      set({
        serverState: undefined,
        label: '',
        config: undefined,
      }),

    label: '',
    setLabel: (label) => set({label}),

    config: undefined,
    setConfig: (config) => set({config}),
  })
);

export const useFieldDefinitionEditorStore = () => {
  const {serverState} = useAgentBuilderStore();
  const store = useFieldDefinitionEditorState();
  const queryClient = useQueryClient();

  const createFieldDefinition = useCreateFieldDefinition();
  const saveFieldDefinition = useSaveFieldDefinition();
  const publishChanges = useMutation({
    mutationFn: async () => {
      const agentId = assertExists(serverState?.id);
      if (!store.config || !store.label) {
        throw new Error('Label and config are required');
      }

      if (store.serverState) {
        const fieldDefinition = {
          ...store.serverState,
          config: store.config,
          label: store.label,
        };

        if (!isAiFieldDefinition(fieldDefinition)) {
          throw new Error('Invalid AI field definition');
        }

        return saveFieldDefinition.mutateAsync({
          fieldDefinition,
          publish: false,
        });
      }

      return createFieldDefinition.mutateAsync(
        getAiFieldDefinition({
          agentId,
          config: store.config,
          label: store.label,
        })
      );
    },
    onSuccess: (updatedFieldDefinition) => {
      if (!isAiFieldDefinition(updatedFieldDefinition)) {
        throw new Error('Invalid AI field definition');
      }

      queryClient.invalidateQueries({
        queryKey: EnrichmentPreviewResultQueryKeys.field({
          fieldDefinitionId: updatedFieldDefinition.id,
        }),
      });

      store.updateServerState({
        serverState: updatedFieldDefinition,
        updateMode: 'force',
      });
    },
  });

  return {
    ...store,
    publishChanges,
  };
};

const getAiFieldDefinition = ({
  agentId,
  config,
  label,
}: {
  agentId: ObjectId;
  config: AiFieldDefinitionConfig;
  label: string;
}): DistributedOmit<AiFieldDefinition, 'id' | 'timestamp'> => {
  const commonFields = {
    agentId,
    label,
    type: 'ai' as const,
    status: 'draft' as const,
  };

  switch (config.fieldType) {
    case 'boolean':
      return {
        ...commonFields,
        dataType: 'boolean',
        config,
      };
    case 'rating':
      return {
        ...commonFields,
        dataType: 'number',
        config,
      };

    case 'category':
      return {
        ...commonFields,
        dataType: 'string',
        config,
      };

    case 'research':
      return {
        ...commonFields,
        dataType: 'string',
        config,
      };

    default:
      assertNever(config);
  }
};
