import {z} from 'zod';
import {ObjectIdSchema} from './zod';
import {RefreshSettingsSchema, StatusSchema} from './enrichment';

const AgentTypeSchema = z.union([
  z.literal('analyst'),
  z.literal('researcher'),
  z.literal('researcherAndAnalyst'),
]);
export type AgentType = z.infer<typeof AgentTypeSchema>;

export const AgentTypeLabels: Record<AgentType, string> = {
  analyst: 'AI Analyst',
  researcher: 'Web Researcher',
  researcherAndAnalyst: 'Web Researcher and Analyst',
};

export const AgentSchema = z.object({
  id: ObjectIdSchema,
  label: z.string(),
  previewAccountIds: ObjectIdSchema.array(),
  refreshSettings: RefreshSettingsSchema.optional(),
  status: StatusSchema,
  type: AgentTypeSchema,
  timestamp: z.date(),
});

export type Agent = z.infer<typeof AgentSchema>;
