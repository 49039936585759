import React, {useEffect} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  ModalCloseButton,
  Box,
  Button,
  useModalContext,
} from '@chakra-ui/react';
import {BuilderModalHeader} from '../../Ai/BuilderModalHeader';
import {BuilderTitleSection} from '../../Ai/BuilderTitleSection';
import {WrapperFlex} from '../../utils/scrolling';
import {AgentBuilderSelectTypeStep} from './AgentBuilderSelectTypeStep';
import {useAgentBuilderStore} from './AgentBuilder.state';
import {AgentBuilderSetNameStep} from './AgentBuilderSetNameStep';
import {ObjectId} from 'bson';
import {useAgent} from '../../../hooks/api/metadata';
import {AgentBuilderSelectAccountsStep} from './AgentBuilderSelectAccountsStep';
import {
  AgentBuilderStep,
  useAgentBuilderNavStore,
} from './AgentBuilderNav.state';
import {AgentBuilderEditResearchFieldStep} from './AgentBuilderEditResearchFieldStep';

export const AgentBuilderModal = ({
  isOpen,
  onClose,
  agentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  agentId?: ObjectId;
}) => {
  const {reset} = useAgentBuilderStore();
  const {setStep} = useAgentBuilderNavStore();
  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        reset();
        setStep('selectType');
      }}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <AgentBuilderContent agentId={agentId} />
      </ModalContent>
    </Modal>
  );
};

const steps = {
  selectType: <AgentBuilderSelectTypeStep />,
  setName: <AgentBuilderSetNameStep />,
  selectAccounts: <AgentBuilderSelectAccountsStep />,
  editResearchField: <AgentBuilderEditResearchFieldStep />,
} satisfies Record<AgentBuilderStep, React.ReactNode>;

const AgentBuilderContent = ({agentId}: {agentId?: ObjectId}) => {
  const agent = useAgent(agentId);
  const {label, type, publishChanges, updateServerState} =
    useAgentBuilderStore();
  const {step} = useAgentBuilderNavStore();
  const {onClose} = useModalContext();

  useEffect(() => {
    if (agent) {
      updateServerState({agent, updateMode: 'ifNoChanges'});
    }
  }, [agent, updateServerState]);

  const saveDraft = () => {
    publishChanges.mutate(undefined, {onSuccess: onClose});
  };

  return (
    <>
      <BuilderModalHeader />
      <ModalBody as={Flex} flexDirection="column" p="0">
        <BuilderTitleSection title="AI Agent Builder">
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!label || !type}
            isLoading={publishChanges.isPending}
            variant="outline"
            onClick={saveDraft}
          >
            Save Draft & Exit
          </Button>
        </BuilderTitleSection>
        <Box flex="1" position="relative">
          <WrapperFlex>{steps[step]}</WrapperFlex>
        </Box>
      </ModalBody>
      <ModalCloseButton />
    </>
  );
};
