import React, {useEffect, useState} from 'react';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Link,
  Divider,
  Tag,
  Button,
  HStack,
  Spinner,
  Alert,
  Tooltip,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table';
import {
  useCustomer,
  useIsFreeOrListBuilderCustomer,
} from '../../hooks/api/metadata';
import {PlanLabels} from '../../shared/customer';
import {useTaskCreditRedemptions} from '../../hooks/api/credits';
import {FieldDefinitionTypeLabels} from '../../shared/enrichment';
import {Information} from '@carbon/icons-react';
import {TaskCreditRedemptionMaxResults} from '../../shared/api/definitions';
import {CreditUsageWithModal} from '../App/CreditUsage';

export const PlanAndCredits = () => {
  const customer = useCustomer();
  const isFreeOrListBuilder = useIsFreeOrListBuilderCustomer();

  return (
    <Box>
      <Text fontSize="xl" fontWeight="500" mb="6">
        Your Plan
      </Text>

      <Flex justifyContent="space-between" alignItems="flex-start" mb="0">
        <Text fontWeight="500">{PlanLabels[customer.plan.type]}</Text>

        {customer.plan.type === 'listBuilder' && (
          <Box textAlign="right">
            <HStack>
              <Text fontWeight="500">Expires:</Text>
              <Text>
                {new Date(customer.plan.endDate).toLocaleDateString()}
              </Text>
              <Tooltip
                label="Purchase more credits to extend your plan's end date"
                placement="top"
                hasArrow
              >
                <Information />
              </Tooltip>
            </HStack>
          </Box>
        )}
      </Flex>

      {isFreeOrListBuilder && (
        <Box mt={2}>
          <CreditUsageWithModal />
        </Box>
      )}

      {!isFreeOrListBuilder && (
        <>
          <Text color="kgray.400" mb="6" mt={1}>
            <Link color="kblue.400" href="mailto:support@keyplay.io">
              Contact Keyplay
            </Link>{' '}
            to make changes to your plan.
          </Text>
          <Divider />
          <Box mt="6">
            <CreditUsageTable />
          </Box>
        </>
      )}
    </Box>
  );
};

type CreditRedemptionRowData = {
  timestamp: Date;
  amount: number;
  state: 'pending' | 'completed';
  name: string;
  type: string;
};

const columnHelper = createColumnHelper<CreditRedemptionRowData>();
const columns = [
  columnHelper.accessor('name', {
    header: 'FIELD NAME',
    cell: (info) => {
      return <Box>{info.getValue()}</Box>;
    },
  }),
  columnHelper.accessor('type', {
    header: 'TYPE',
    cell: (info) => {
      return <Box>{info.getValue()}</Box>;
    },
  }),
  columnHelper.accessor((info) => info, {
    header: 'CREDITS USED',
    cell: (info) => {
      return (
        <Box>
          {info.getValue().state === 'pending' ? (
            <Tag
              key="default"
              size={'md'}
              fontWeight={500}
              fontSize={12}
              textTransform="uppercase"
            >
              Pending
            </Tag>
          ) : (
            info.getValue().amount
          )}
        </Box>
      );
    },
  }),
  columnHelper.accessor('timestamp', {
    header: 'DATE',
    cell: (info) => {
      const date = info.getValue();
      const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return (
        <Box color="kgray.300">
          {formattedDate}, {formattedTime}
        </Box>
      );
    },
  }),
];

const CreditUsageTable = () => {
  const customer = useCustomer();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<CreditRedemptionRowData[] | null>(null);
  const [numRedemptions, setNumRedemptions] = useState(0);
  const pageSize = TaskCreditRedemptionMaxResults;

  const {data, isError, isPending} = useTaskCreditRedemptions({
    offset: page * pageSize,
  });

  useEffect(() => {
    if (data?.redemptions) {
      setNumRedemptions(data.totalRedemptions);

      setRows(
        data.redemptions.map(({redemption, fieldDefinitionId}) => {
          const field = customer.fieldDefinitions?.find((field) =>
            field.id.equals(fieldDefinitionId)
          );

          return {
            timestamp: redemption.timestamp,
            amount: redemption.amount * -1,
            state: redemption.state,
            name: field?.label ?? 'Unknown Field',
            type: field?.type
              ? FieldDefinitionTypeLabels[field.type]
              : 'Unknown Type',
          };
        })
      );
    }
  }, [data, customer.fieldDefinitions]);

  const totalPages = Math.ceil(numRedemptions / pageSize);

  const table = useReactTable({
    columns,
    data: rows ?? [],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination: {
        pageIndex: page,
        pageSize,
      },
    },
  });

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  if (!rows) {
    return (
      <Flex justifyContent="center" alignItems="center" p="8">
        <Spinner />
      </Flex>
    );
  }

  if (isError) {
    return (
      <Alert status="error" borderRadius="md">
        Unable to load credit redemption data. Please try again later.
      </Alert>
    );
  }

  return (
    <Box>
      <Text fontSize="xl" fontWeight="500" mb="2">
        Credits
      </Text>
      <Text fontWeight="500" mb="4">
        {customer.credits} available
      </Text>

      {!!rows.length && (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Box width="180px">
              <Text>
                Total Redemptions: <strong>{numRedemptions}</strong>
              </Text>
            </Box>

            <HStack spacing="2">
              <Button
                onClick={handlePrevPage}
                isDisabled={page === 0}
                isLoading={isPending}
                size="sm"
                variant="outline"
              >
                Previous
              </Button>
              <Text>
                Page {page + 1} of {Math.max(totalPages, 1)}
              </Text>
              <Button
                onClick={handleNextPage}
                isDisabled={page >= totalPages - 1 || numRedemptions === 0}
                isLoading={isPending}
                size="sm"
                variant="outline"
              >
                Next
              </Button>
            </HStack>

            <Box width="180px" textAlign="right">
              <Text fontSize="sm" color="kgray.400">
                Showing {numRedemptions > 0 ? page * pageSize + 1 : 0}-
                {Math.min((page + 1) * pageSize, numRedemptions)} of{' '}
                {numRedemptions}
              </Text>
            </Box>
          </Flex>
          <TableContainer
            border="1px"
            borderColor="kgray.200"
            borderRadius="md"
            mb="4"
          >
            <Table variant="simple">
              <Thead bg="kgray.100">
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th key={header.id}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
