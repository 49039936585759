import {createApiQuery} from './api';
import {GetTaskCreditRedemptions} from '../../shared/api/definitions';

export const TaskCreditRedemptionKeys = {
  all: ['taskCreditRedemptions'] as const,
  list: (offset?: number) =>
    [...TaskCreditRedemptionKeys.all, offset ?? 0] as const,
};

export const useTaskCreditRedemptions = createApiQuery(
  '/taskCreditRedemptions',
  GetTaskCreditRedemptions,
  ({offset}) => TaskCreditRedemptionKeys.list(offset)
);
