import {ObjectId} from 'bson';
import {createStore, StoreApi, useStore} from 'zustand';
import {
  AiEnrichmentAccountFilter,
  AiFieldDefinition,
  AutoEnrichSchedule,
  isAiEnrichmentAccountFilter,
  isFieldDefinition,
} from '../../../shared/enrichment';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useSaveFieldDefinition} from '../../../hooks/api/fieldDefinitions';
import {useFieldDefinitionFromContext} from './FieldDefinitionContext';
import _ from 'lodash';
import produce from 'immer';
import {EnrichmentPreviewResultQueryKeys} from '../../../hooks/api/fieldDefinitionPreview';

interface AiFieldBuilderState {
  updateServerState: ({
    fieldDefinition,
    updateMode,
  }: {
    fieldDefinition: AiFieldDefinition;
    updateMode: 'always' | 'ifNoChanges';
  }) => void;
  serverState: AiFieldDefinition;

  hasChanges: () => boolean;

  label: string;
  setLabel: (label: string) => void;

  previewAccountIds: ObjectId[];
  addAccount: (accountId: ObjectId) => void;
  removeAccount: (accountId: ObjectId) => void;

  config: AiFieldDefinition['config'];
  setConfig: (config: AiFieldDefinition['config']) => void;

  accountFilter: AiEnrichmentAccountFilter | undefined;
  setAccountFilter: (accountFilter: AiEnrichmentAccountFilter) => void;

  autoEnrichNewAccounts: boolean | undefined;
  setAutoEnrichNewAccounts: (autoEnrichNewAccounts: boolean) => void;

  autoEnrichSchedule: AutoEnrichSchedule | undefined;
  setAutoEnrichSchedule: (
    autoEnrichSchedule: AutoEnrichSchedule | undefined
  ) => void;
}

const createAiFieldBuilderStore = (fieldDefinition: AiFieldDefinition) =>
  createStore<AiFieldBuilderState>()((set, get) => ({
    updateServerState: ({fieldDefinition, updateMode}) => {
      if (get().hasChanges() && updateMode === 'ifNoChanges') {
        return;
      }

      set({
        serverState: fieldDefinition,
        label: fieldDefinition.label,
        previewAccountIds: fieldDefinition.previewAccountIds ?? [],
        config: fieldDefinition.config,
        accountFilter: fieldDefinition.refreshSettings?.accountFilter,
        autoEnrichSchedule: fieldDefinition.refreshSettings?.autoEnrichSchedule,
      });
    },
    serverState: fieldDefinition,

    hasChanges: () => {
      const {
        accountFilter,
        autoEnrichNewAccounts,
        autoEnrichSchedule,
        label,
        serverState,
        previewAccountIds,
        config,
      } = get();
      return (
        label !== serverState.label ||
        !_.isEqual(serverState.previewAccountIds, previewAccountIds) ||
        !_.isEqual(serverState.config, config) ||
        !_.isEqual(serverState.refreshSettings?.accountFilter, accountFilter) ||
        serverState.refreshSettings?.autoEnrichNewAccounts !==
          autoEnrichNewAccounts ||
        !_.isEqual(
          serverState.refreshSettings?.autoEnrichSchedule,
          autoEnrichSchedule
        )
      );
    },

    label: fieldDefinition.label,
    setLabel: (label) =>
      set(
        produce<AiFieldBuilderState>((state) => {
          state.label = label;
          if (state.config.fieldType === 'category') {
            state.config.categoryName = label;
          }
        })
      ),

    previewAccountIds: fieldDefinition.previewAccountIds ?? [],
    addAccount: (accountId) => {
      const {previewAccountIds: selectedAccountIds} = get();
      if (selectedAccountIds.some((id) => id.equals(accountId))) {
        return;
      }

      set({previewAccountIds: [...selectedAccountIds, accountId]});
    },
    removeAccount: (accountId) =>
      set({
        previewAccountIds: get().previewAccountIds.filter(
          (id) => !id.equals(accountId)
        ),
      }),

    config: fieldDefinition.config,
    setConfig: (config) => set({config}),

    accountFilter: fieldDefinition.refreshSettings?.accountFilter,
    setAccountFilter: (accountFilter) => set({accountFilter}),

    autoEnrichNewAccounts:
      fieldDefinition.refreshSettings?.autoEnrichNewAccounts,
    setAutoEnrichNewAccounts: (autoEnrichNewAccounts) =>
      set({autoEnrichNewAccounts}),

    autoEnrichSchedule: fieldDefinition.refreshSettings?.autoEnrichSchedule,
    setAutoEnrichSchedule: (autoEnrichSchedule) => set({autoEnrichSchedule}),
  }));

// Store for each field definition
const aiFieldBuilderStore: Record<string, StoreApi<AiFieldBuilderState>> = {};
export const useAiFieldBuilderStore = () => {
  const fieldDefinition = useFieldDefinitionFromContext();
  const fieldDefinitionId = fieldDefinition.id.toString();
  const queryClient = useQueryClient();

  aiFieldBuilderStore[fieldDefinitionId] ??=
    createAiFieldBuilderStore(fieldDefinition);
  const store = useStore(aiFieldBuilderStore[fieldDefinitionId]);

  const saveFieldDefinition = useSaveFieldDefinition();
  const publishChanges = useMutation({
    mutationFn: async (action: 'publish' | 'save') => {
      const accountFilter = isAiEnrichmentAccountFilter(store.accountFilter)
        ? store.accountFilter
        : undefined;

      const fieldDefinition = {
        ...store.serverState,
        config: store.config,
        label: store.label,
        previewAccountIds: store.previewAccountIds,
        status: action === 'publish' ? 'published' : 'draft',
        ...(accountFilter
          ? {
              refreshSettings: {
                accountFilter,
                ...(store.autoEnrichNewAccounts !== undefined
                  ? {autoEnrichNewAccounts: store.autoEnrichNewAccounts}
                  : {}),
                ...(store.autoEnrichSchedule
                  ? {autoEnrichSchedule: store.autoEnrichSchedule}
                  : {}),
              },
            }
          : {}),
      };

      if (!store.hasChanges() && action === 'save') {
        return;
      }

      if (!isFieldDefinition(fieldDefinition)) {
        throw new Error('Invalid field definition');
      }

      return saveFieldDefinition.mutateAsync(
        {fieldDefinition, publish: action === 'publish'},
        {
          onSuccess: async (fieldDefinition) => {
            // TODO: don't invalidate if the only thing that changed was the list
            // of preview accounts?
            await queryClient.invalidateQueries({
              queryKey: EnrichmentPreviewResultQueryKeys.field({
                fieldDefinitionId: fieldDefinition.id,
              }),
            });

            store.updateServerState({fieldDefinition, updateMode: 'always'});
          },
        }
      );
    },
  });

  return {
    ...store,
    publishChanges,
  };
};
