const namePattern = /^[a-z0-9 '!$+@#%&*()-<>]+$/i;

export const TestListName = {
  maxLength: 30,
  minLength: 5,
  pattern: namePattern,
};

export const ImportSourceName = {
  maxLength: 30,
  minLength: 5,
  pattern: namePattern,
};

export const TagName = {
  maxLength: 30,
  minLength: 3,
  pattern: namePattern,
};

export const MaxBulkPreviewAccounts = 25;
export const MaxBulkLookalikeDomains = 250;

export const FieldDefinitionLabel = {
  maxLength: 50,
  minLength: 5,
  pattern: namePattern,
};

export const AgentLabel = {
  maxLength: 50,
  minLength: 5,
  pattern: namePattern,
};
