import React, {createContext, useContext} from 'react';
import {AiFieldDefinition} from '../../../shared/enrichment';
import {ObjectId} from 'bson';
import {useFieldDefinition} from '../../../hooks/api/metadata';

export const FieldDefinitionContext = createContext<AiFieldDefinition | null>(
  null
);

export const FieldDefinitionProvider = ({
  children,
  fieldDefinitionId,
}: React.PropsWithChildren<{fieldDefinitionId: ObjectId}>) => {
  const fieldDefinition = useFieldDefinition(fieldDefinitionId);
  if (!fieldDefinition) {
    throw new Error('FieldDefinition not found');
  }

  if (fieldDefinition.type !== 'ai') {
    throw new Error('FieldDefinition is not an AI field');
  }

  return (
    <FieldDefinitionContext.Provider value={fieldDefinition}>
      {children}
    </FieldDefinitionContext.Provider>
  );
};

export const useFieldDefinitionFromContext = () => {
  const fieldDefinition = useContext(FieldDefinitionContext);
  if (!fieldDefinition) {
    throw new Error('FieldDefinitionContext not found');
  }

  return fieldDefinition;
};
