import React from 'react';
import {PageIndicator} from '../../PageIndicator';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {Flex} from '@chakra-ui/react';
import {ContentHeaderSpacePx} from './helpers';

export const FieldBuilderPageIndicator = () => {
  const {step, steps} = useAiFieldBuilderNavStore();

  return (
    <Flex alignItems="center" h={ContentHeaderSpacePx}>
      <PageIndicator page={steps.indexOf(step)} totalPages={steps.length} />
    </Flex>
  );
};
