import {create} from 'zustand';
import {AgentType} from '../../../shared/agent';
import {useAgentBuilderStore} from './AgentBuilder.state';
import {useEffect} from 'react';

const Steps = [
  'selectType',
  'setName',
  'selectAccounts',
  'editResearchField',
] as const;
export type AgentBuilderStep = (typeof Steps)[number];

export const AgentBuilderSteps: Record<AgentType, readonly AgentBuilderStep[]> =
  {
    analyst: Steps.filter((step) => step !== 'editResearchField'),
    researcher: Steps,
    researcherAndAnalyst: Steps,
  };

interface AgentBuilderNavState {
  step: AgentBuilderStep;
  steps: readonly AgentBuilderStep[];

  setStep: (step: AgentBuilderStep) => void;
  setSteps: (steps: readonly AgentBuilderStep[]) => void;

  nextStep: () => void;
  prevStep: () => void;
}

const useAgentBuilderNavState = create<AgentBuilderNavState>()((set, get) => {
  const getCurrentStepIdx = () => get().steps.indexOf(get().step);

  return {
    step: 'selectType',
    steps: [],

    setStep: (step) => set({step}),
    setSteps: (steps) => set({steps}),

    nextStep: () => {
      const nextStep = get().steps[getCurrentStepIdx() + 1];
      if (nextStep) {
        set({step: nextStep});
      }
    },
    prevStep: () => {
      const prevStep = get().steps[getCurrentStepIdx() - 1];
      if (prevStep) {
        set({step: prevStep});
      }
    },
  };
});

export const useAgentBuilderNavStore = () => {
  const agentType = useAgentBuilderStore().serverState?.type ?? 'analyst';
  const store = useAgentBuilderNavState();

  const {setSteps} = store;
  useEffect(() => {
    setSteps(AgentBuilderSteps[agentType]);
  }, [agentType, setSteps]);

  return store;
};
