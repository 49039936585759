import {StoreApi, createStore, useStore} from 'zustand';
import {devtools} from 'zustand/middleware';
import produce from 'immer';
import {IntegrationResponse} from '../../shared/api/api';
import {
  AdditionalFieldMapping,
  IntegrationConfig,
  isAdditionalFieldMapping,
  SyncSettings,
  ManagedFieldMapping,
  isManagedFieldMapping,
  ManagedFieldMappingField,
} from '../../shared/integrations';
import {useUpdateIntegration} from '../../hooks/api/integrations';
import {useEffect} from 'react';
import _ from 'lodash';
import {useMutation} from '@tanstack/react-query';
import {useToast} from '@chakra-ui/react';
import {useIntegrationFromContext} from './IntegrationDetailsContext';

interface IntegrationDetailsState {
  // initialization and server state
  hasInitialized: boolean;
  updateServerState: (serverResponse: IntegrationResponse) => void;
  serverState: IntegrationResponse | null;

  // client state
  managedFieldMappings: ManagedFieldMapping[];
  addManagedField: (fieldMapping: ManagedFieldMapping) => void;
  deleteManagedField: (field: ManagedFieldMappingField) => void;

  additionalFieldMappings: AdditionalFieldMapping[];
  addAdditionalField: (fieldMapping: AdditionalFieldMapping) => void;
  updateAdditionalField: (
    oldFieldMapping: AdditionalFieldMapping,
    newFieldMapping: AdditionalFieldMapping
  ) => void;
  deleteAdditionalField: (deletedFieldMapping: AdditionalFieldMapping) => void;

  autoSync: boolean;
  setAutoSync: (autoSync: boolean) => void;
  syncSettings: SyncSettings | undefined;
  setSyncSettings: (syncSettings: SyncSettings) => void;

  getServerConfigFromLocalState: () => Partial<IntegrationConfig>;
  hasChanges: (args?: {includeDraftChanges?: boolean}) => boolean;
}

const createIntegrationDetailsStoreFactory = () =>
  createStore<IntegrationDetailsState>()(
    devtools<IntegrationDetailsState>((set, get) => {
      const getFilteredAdditionalFields = () =>
        get().additionalFieldMappings.filter(({crmFieldName}) => crmFieldName);

      const getLocalStateFromServerState = (
        serverState: IntegrationResponse
      ) => {
        const config = serverState.config;
        const fieldMappings = config?.fieldMapping ?? [];

        return {
          managedFieldMappings: fieldMappings.filter(isManagedFieldMapping),
          additionalFieldMappings: fieldMappings.filter(
            isAdditionalFieldMapping
          ),
          autoSync: !!config?.autoSync,
          syncSettings: config?.syncSettings,
        };
      };

      const setLocalState = (serverState: IntegrationResponse) => {
        const {
          managedFieldMappings,
          additionalFieldMappings,
          autoSync,
          syncSettings,
        } = getLocalStateFromServerState(serverState);

        set(
          produce<IntegrationDetailsState>((state) => {
            state.managedFieldMappings = managedFieldMappings;
            state.additionalFieldMappings = additionalFieldMappings;
            state.autoSync = autoSync;
            state.syncSettings = syncSettings;
            state.hasInitialized = true;
          })
        );
      };

      return {
        hasInitialized: false,
        updateServerState: (integrationResponse: IntegrationResponse) => {
          // don't blow away unsaved client changes with new server state
          if (
            !get().hasInitialized ||
            !get().hasChanges({includeDraftChanges: true})
          ) {
            setLocalState(integrationResponse);
          }

          // do this last to ensure hasChanges check runs against old server state
          set(
            produce<IntegrationDetailsState>((state) => {
              state.serverState = integrationResponse;
            })
          );
        },
        serverState: null,

        managedFieldMappings: [],
        addManagedField: (fieldMapping) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.managedFieldMappings.push(fieldMapping);
            })
          ),
        deleteManagedField: (field) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.managedFieldMappings = state.managedFieldMappings.filter(
                (f) => f.enrichmentField !== field
              );
            })
          ),

        additionalFieldMappings: [],
        addAdditionalField: (fieldMapping) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.additionalFieldMappings.push(fieldMapping);
            })
          ),
        updateAdditionalField: (oldFieldMapping, newFieldMapping) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              const index = get().additionalFieldMappings.findIndex(
                (fieldMapping) => _.isEqual(fieldMapping, oldFieldMapping)
              );

              if (index === -1) {
                throw new Error('Could not find field to update');
              }

              state.additionalFieldMappings[index] = newFieldMapping;
            })
          ),
        deleteAdditionalField: (deletedFieldMapping) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.additionalFieldMappings =
                get().additionalFieldMappings.filter(
                  (fieldMapping) =>
                    !_.isEqual(fieldMapping, deletedFieldMapping)
                );
            })
          ),

        autoSync: false,
        setAutoSync: (autoSync) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.autoSync = autoSync;
            })
          ),
        syncSettings: undefined,
        setSyncSettings: (syncSettings) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.syncSettings = syncSettings;
            })
          ),

        hasChanges: ({
          includeDraftChanges,
        }: {includeDraftChanges?: boolean} = {}) => {
          const serverState = get().serverState;
          if (!get().hasInitialized || !serverState) {
            return false;
          }

          const {
            managedFieldMappings,
            additionalFieldMappings,
            autoSync,
            syncSettings,
          } = getLocalStateFromServerState(serverState);

          const additionalFieldsToCompareAgainst = includeDraftChanges
            ? get().additionalFieldMappings
            : getFilteredAdditionalFields();

          return (
            !_.isEqual(managedFieldMappings, get().managedFieldMappings) ||
            !_.isEqual(
              additionalFieldMappings,
              additionalFieldsToCompareAgainst
            ) ||
            !_.isEqual(syncSettings, get().syncSettings) ||
            autoSync !== get().autoSync
          );
        },

        getServerConfigFromLocalState: () => {
          const fieldMapping = [
            ...get().managedFieldMappings,
            ...getFilteredAdditionalFields(),
          ];

          return {
            fieldMapping,
            autoSync: get().autoSync,
            syncSettings: get().syncSettings,
          };
        },
      };
    })
  );

let integrationDetailsStore: StoreApi<IntegrationDetailsState> | null = null;
export const useIntegrationDetailsState = () => {
  // Only initialize the store once
  if (!integrationDetailsStore) {
    integrationDetailsStore = createIntegrationDetailsStoreFactory();
  }
  const store = useStore(integrationDetailsStore);

  const integration = useIntegrationFromContext();
  const updateIntegrationMutation = useUpdateIntegration(integration.type);

  const toast = useToast();
  const successToastId = 'successToast';
  const publishChanges = useMutation({
    mutationFn: async () => {
      if (!store.hasInitialized) {
        throw new Error('Cannot publish changes before initializing');
      }

      if (!store.hasChanges()) {
        return;
      }

      return updateIntegrationMutation.mutateAsync(
        store.getServerConfigFromLocalState()
      );
    },
    onSuccess: () => {
      if (toast.isActive(successToastId)) {
        return;
      }
      toast({
        id: successToastId,
        title: 'Successfully updated integration',
        status: 'success',
        duration: 2_000,
      });
    },
  });

  useEffect(() => {
    store.updateServerState(integration);
    // don't add store as dependency because it causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration]);

  return {
    ...store,
    publishChanges,
  };
};
