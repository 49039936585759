import {SettingsAdjust} from '@carbon/icons-react';
import {
  Box,
  BoxProps,
  Button,
  Select as ChakraSelect,
  Checkbox,
  Flex,
  Grid,
  Image,
  NumberInput,
  NumberInputField,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import {
  useContentPaneHeight,
  useIsFreeOrListBuilderCustomer,
} from '../../hooks/api/metadata';
import {useMetadata} from '../../context/MetadataContext';
import {useTags} from '../../hooks/api/tags';
import {
  BusinessAudience,
  BusinessAudienceLabels,
  PrimaryBusinessCategory,
  PrimaryBusinessCategoryLabels,
} from '../../shared/account';
import {AccountTier, AccountWithLogo} from '../../shared/scoredAccounts';
import {ScoringSignal} from '../../shared/signals';
import {filterNull, keys} from '../../shared/util';
import {MultiGroupSelect} from '../multiGroupSelect';
import {
  SegmentedRadioButton,
  SegmentedRadioButtonGroup,
} from '../segmentedRadioButtons';
import {useAccountView} from './AccountGrid';
import {
  useAccountQueryParams,
  useIsMyAccountsView,
  useUserControls,
} from './AccountGrid.controlstate';
import {useImportSourceCounts} from './useImportSourceCounts';
import {useSignalCounts} from './useSignalCounts';
import {AccountQuery} from '../../shared/api/api';
import {useScoringSignalResolver} from '../../hooks/scoringSignal';
import {ObjectId} from 'bson';
import {useScoringRun} from '../../context/ScoringRunContext';

function loadingTextsFor(type: string) {
  return {
    success: `No ${type}.`,
    pending: 'Loading...',
    error: `An error occurred when loading ${type}.`,
  };
}

const ScoringSignalFilterLoadingTexts = loadingTextsFor('signals');
const ScoringImportSourceFilterLoadingTexts = loadingTextsFor('sources');
const TagFilterLoadingTexts = loadingTextsFor('tags');

const isInteger = /^\d+$/;
type EmployeeFilter = {
  min?: number;
  max?: number;
};

function Label({children, ...props}: BoxProps) {
  return (
    <Box
      fontFamily="mono"
      fontWeight={500}
      fontSize="12px"
      textTransform="uppercase"
      mb={2}
      {...props}
    >
      {children}
    </Box>
  );
}

const formatLookalikeOptionLabel = (data: {value: AccountWithLogo}) => (
  <Box display="flex" alignItems="center" mt="0">
    <Image
      src={data.value.logoUrl}
      alt="icon"
      boxSize="24px"
      mr="2"
      fallbackSrc="/accountFallback.png"
    />
    <Text>{data.value.name}</Text>
  </Box>
);

export const FilterControl = () => {
  const userControls = useUserControls();
  const {
    tiers,
    setTiers,
    signals: selectedSignals,
    setSignals: setSelectedSignals,
    importSources: selectedImportSources,
    setImportSources: setSelectedImportSources,
    tags: selectedTags,
    setTags: setSelectedTags,
    similarTo,
    setSimilarTo,
    inCrm,
    setInCrm,
    inSam,
    setInSam,
    excluded,
    setExcluded,
    disqualified,
    setDisqualified,
    hasTierOverride,
    setHasTierOverride,
    minEmployees,
    setMinEmployees,
    maxEmployees,
    setMaxEmployees,
    audience,
    setAudience,
    primaryCategory,
    setPrimaryCategory,
    filtersDifferFromDefault,
    numberOfActiveFiltersToDisplay,
    resetFilters,
  } = userControls;

  const accountView = useAccountView();
  const isMyAccountsView = useIsMyAccountsView();

  // tiers
  const tierCheckboxes = _.map(tiers, (active, tier: AccountTier) => (
    <Checkbox
      key={tier}
      isChecked={active}
      onChange={(e) => setTiers(tier, e.target.checked)}
    >
      {tier}
    </Checkbox>
  ));

  const metadata = useMetadata();
  const hasImports = Object.keys(metadata.imports ?? {}).length > 0;

  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();
  const scoringRun = useScoringRun();

  const lookalikesWithLogos = scoringRun?.lookalikesWithLogos;

  const queryParams = useAccountQueryParams(scoringRun._id);

  const audienceOptions = keys(BusinessAudienceLabels).map((value) => ({
    value,
    label: BusinessAudienceLabels[value],
  }));
  const selectedAudienceOptions = audience.map((value) => ({
    value,
    label: BusinessAudienceLabels[value],
  }));

  const primaryCategoryOptions = keys(PrimaryBusinessCategoryLabels).map(
    (value) => ({
      value,
      label: PrimaryBusinessCategoryLabels[value],
    })
  );
  const selectedPrimaryCategoryOptions = primaryCategory.map((value) => ({
    value,
    label: PrimaryBusinessCategoryLabels[value],
  }));

  const tags = useTags();
  const tagOptions = React.useMemo(
    () =>
      tags.data?.map((tag) => ({
        value: tag._id,
        label: tag.name,
      })),
    [tags.data]
  );

  const selectedTagOptions = React.useMemo(
    () =>
      tagOptions?.filter((tagOption) =>
        selectedTags.find((id) => id.equals(tagOption.value))
      ),
    [selectedTags, tagOptions]
  );

  const selectedSimilarTo = lookalikesWithLogos?.filter((lookalike) =>
    similarTo.find((id) => id.equals(lookalike._id))
  );

  // in CRM/SAM
  const selectValueToState = (value: 'all' | 'in' | 'out') =>
    ({
      all: undefined,
      in: true,
      out: false,
    })[value];

  const stateToSelectValue = (value: boolean | undefined) => {
    if (value === undefined) {
      return 'all';
    }
    return value ? 'in' : 'out';
  };

  const [employeeFilter, setEmployeeFilter] = useState<EmployeeFilter>({});

  // Ensure that the local employee filter state stays in sync with changes to
  // the zustand store state.
  useEffect(() => {
    setEmployeeFilter({min: minEmployees, max: maxEmployees});
  }, [minEmployees, maxEmployees]);

  // Apply local changes to store only after some time has passed to prevent
  // too many API requests.
  const persistChanges = useMemo(
    () =>
      _.debounce((updatedFilter: EmployeeFilter) => {
        setMinEmployees(updatedFilter.min);
        setMaxEmployees(updatedFilter.max);
      }, 250),
    [setMaxEmployees, setMinEmployees]
  );
  const onMinEmployeesChanged = useCallback(
    (value: string) => {
      const num = parseInt(value);
      const updatedValue = _.isFinite(num) ? num : undefined;
      setEmployeeFilter((prevState) => {
        const newState: EmployeeFilter = {
          ...prevState,
          min: updatedValue,
        };
        persistChanges(newState);
        return newState;
      });
    },
    [persistChanges]
  );
  const onMaxEmployeesChanged = useCallback(
    (value: string) => {
      const num = parseInt(value);
      const updatedValue = _.isFinite(num) ? num : undefined;
      setEmployeeFilter((prevState) => {
        const newState: EmployeeFilter = {
          ...prevState,
          max: updatedValue,
        };
        persistChanges(newState);
        return newState;
      });
    },
    [persistChanges]
  );

  const maxFilterPaneHeight = useContentPaneHeight();

  const activeFiltersCount = numberOfActiveFiltersToDisplay();

  const invalidEmployeeFilter =
    employeeFilter.max !== undefined &&
    employeeFilter.min !== undefined &&
    employeeFilter.max < employeeFilter.min;

  return (
    <Popover placement="bottom-start">
      {({isOpen}) => (
        <>
          <PopoverTrigger>
            <Button
              variant="ghost"
              color="kgray.400"
              backgroundColor={activeFiltersCount ? 'kgray.100' : undefined}
              fontSize="14"
              fontWeight="500"
              leftIcon={
                <Box>
                  <SettingsAdjust />
                </Box>
              }
            >
              Filter
              {activeFiltersCount ? (
                <Box
                  backgroundColor="kblue.400"
                  borderRadius="999px"
                  width="21px"
                  height="21px"
                  color="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="500"
                  ml={2}
                >
                  {activeFiltersCount}
                </Box>
              ) : null}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              fontSize={14}
              width="350px"
              maxHeight={maxFilterPaneHeight}
              overflowY="auto"
            >
              <PopoverBody my={1}>
                <Label>Tiers to show</Label>
                <Grid templateColumns="1fr 1fr 1fr 1fr" autoFlow="column">
                  {tierCheckboxes}
                </Grid>

                <Box as="hr" my={4} />
                <Label>And any of these signals</Label>
                <Box mb={2} display={isOpen ? 'block' : 'none'}>
                  <ScoringSignalsMultiGroupSelect
                    isDisabled={!isOpen}
                    queryParams={queryParams}
                    selectedSignals={selectedSignals}
                    setSelectedSignals={setSelectedSignals}
                  />
                </Box>
                {!!lookalikesWithLogos?.length && (
                  <>
                    <Box as="hr" my={4} />
                    <Label>And similar to these accounts</Label>
                    <Box mb={2} display={isOpen ? 'block' : 'none'}>
                      <Select
                        options={lookalikesWithLogos.map((lookalike) => ({
                          value: lookalike,
                          label: lookalike.name,
                        }))}
                        isMulti
                        formatOptionLabel={formatLookalikeOptionLabel}
                        value={selectedSimilarTo?.map((lookalike) => ({
                          value: lookalike,
                        }))}
                        onChange={(values) =>
                          setSimilarTo(values.map((v) => v.value._id))
                        }
                      />
                    </Box>
                  </>
                )}
                <>
                  <Box as="hr" my={4} />
                  <Label>And any of these categories</Label>
                  <Box mb={2} display={isOpen ? 'block' : 'none'}>
                    <Select
                      options={primaryCategoryOptions}
                      isMulti
                      onChange={(values) =>
                        setPrimaryCategory(
                          values.map(
                            (v) => v.value
                          ) as PrimaryBusinessCategory[]
                        )
                      }
                      value={selectedPrimaryCategoryOptions}
                    />
                  </Box>
                </>
                <>
                  <Box as="hr" my={4} />
                  <Label>And any of these audiences</Label>
                  <Box mb={2} display={isOpen ? 'block' : 'none'}>
                    <Select
                      options={audienceOptions}
                      isMulti
                      onChange={(values) =>
                        setAudience(
                          values.map((v) => v.value) as BusinessAudience[]
                        )
                      }
                      value={selectedAudienceOptions}
                    />
                  </Box>
                </>
                {isMyAccountsView && !!tagOptions?.length && (
                  <>
                    <Box as="hr" my={4} />
                    <Label>And any of these tags</Label>
                    <Box mb={2} display={isOpen ? 'block' : 'none'}>
                      <Select
                        noOptionsMessage={() =>
                          TagFilterLoadingTexts[tags.status]
                        }
                        options={tagOptions}
                        isMulti
                        onChange={(values) =>
                          setSelectedTags(values.map((v) => v.value))
                        }
                        value={selectedTagOptions}
                      />
                    </Box>
                  </>
                )}
                {isMyAccountsView && hasImports && (
                  <>
                    <Box as="hr" my={4} />
                    <Label>And any of these sources</Label>
                    <Box mb={2} display={isOpen ? 'block' : 'none'}>
                      <ImportSourceSelect
                        isDisabled={!isOpen}
                        queryParams={queryParams}
                        selectedImportSources={selectedImportSources}
                        setSelectedImportSources={setSelectedImportSources}
                      />
                    </Box>
                  </>
                )}
                <Box as="hr" my={4} />
                <Label>And within this employee range</Label>
                <Flex alignItems="center" gap={2}>
                  <NumberInput
                    inputMode="numeric"
                    isInvalid={invalidEmployeeFilter}
                    isValidCharacter={(value) => isInteger.test(value)}
                    min={0}
                    onChange={onMinEmployeesChanged}
                    size="sm"
                    value={employeeFilter.min ?? ''}
                  >
                    <NumberInputField placeholder="Min" />
                  </NumberInput>
                  <Box>-</Box>
                  <NumberInput
                    inputMode="numeric"
                    isInvalid={invalidEmployeeFilter}
                    isValidCharacter={(value) => isInteger.test(value)}
                    min={0}
                    onChange={onMaxEmployeesChanged}
                    size="sm"
                    value={employeeFilter.max ?? ''}
                  >
                    <NumberInputField placeholder="Max" />
                  </NumberInput>
                </Flex>
                {isMyAccountsView && (
                  <>
                    <Box as="hr" my={4} />
                    <Flex mb={2} alignItems="center">
                      <Box>In Serviceable Market</Box>
                      <Spacer />
                      <ChakraSelect
                        width="80px"
                        size="xs"
                        onChange={(e) =>
                          setInSam(
                            selectValueToState(
                              e.target.value as 'all' | 'in' | 'out'
                            )
                          )
                        }
                        value={stateToSelectValue(inSam)}
                      >
                        <option value="all">All</option>
                        <option value="in">In</option>
                        <option value="out">Out</option>
                      </ChakraSelect>
                    </Flex>
                    {accountView !== 'crm' && (
                      <Flex mb={2} alignItems="center">
                        <Box>In CRM</Box>
                        <Spacer />
                        <ChakraSelect
                          width="80px"
                          size="xs"
                          onChange={(e) =>
                            setInCrm(
                              selectValueToState(
                                e.target.value as 'all' | 'in' | 'out'
                              )
                            )
                          }
                          value={stateToSelectValue(inCrm)}
                        >
                          <option value="all">All</option>
                          <option value="in">In</option>
                          <option value="out">Out</option>
                        </ChakraSelect>
                      </Flex>
                    )}
                    <Flex mb={2} alignItems="center">
                      <Box>With Tier Overrides</Box>
                      <Spacer />
                      <ChakraSelect
                        width="80px"
                        size="xs"
                        onChange={(e) =>
                          setHasTierOverride(
                            selectValueToState(
                              e.target.value as 'all' | 'in' | 'out'
                            )
                          )
                        }
                        value={stateToSelectValue(hasTierOverride)}
                      >
                        <option value="all">All</option>
                        <option value="in">Only</option>
                      </ChakraSelect>
                    </Flex>
                  </>
                )}

                {isMyAccountsView && !isFreeOrListBuilderCustomer && (
                  <>
                    <Box as="hr" my={4} />
                    <Label>See Excluded Accounts</Label>
                    <SegmentedRadioButtonGroup
                      size="sm"
                      value={excluded ? 'excluded' : 'active'}
                      onChange={(value) => setExcluded(value === 'excluded')}
                      mb={2}
                    >
                      <>
                        <SegmentedRadioButton value="active">
                          Active
                        </SegmentedRadioButton>
                        <SegmentedRadioButton value="excluded">
                          Excluded
                        </SegmentedRadioButton>
                      </>
                    </SegmentedRadioButtonGroup>
                  </>
                )}
                {!isMyAccountsView && !isFreeOrListBuilderCustomer && (
                  <>
                    <Box as="hr" my={4} />
                    <Label>See Disqualified Accounts</Label>
                    <SegmentedRadioButtonGroup
                      size="sm"
                      value={disqualified ? 'disqualified' : 'recommended'}
                      onChange={(value) =>
                        setDisqualified(value === 'disqualified')
                      }
                      mb={2}
                    >
                      <>
                        <SegmentedRadioButton value="recommended">
                          Recommended
                        </SegmentedRadioButton>
                        <SegmentedRadioButton value="disqualified">
                          Disqualified
                        </SegmentedRadioButton>
                      </>
                    </SegmentedRadioButtonGroup>
                  </>
                )}
                {filtersDifferFromDefault() && (
                  <>
                    <Box as="hr" my={4} />
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => resetFilters()}
                    >
                      Reset Filters
                    </Button>
                  </>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

interface ScoringSignalSelectProps {
  isDisabled?: boolean;
  queryParams: AccountQuery | '';
  selectedSignals: ScoringSignal[][];
  setSelectedSignals: (signals: ScoringSignal[][]) => void;
}

const ScoringSignalsMultiGroupSelect = ({
  isDisabled,
  queryParams,
  selectedSignals,
  setSelectedSignals,
}: ScoringSignalSelectProps) => {
  const signalCounts = useSignalCounts({
    queryParams,
    enabled: !isDisabled,
  });
  const scoringSignalResolver = useScoringSignalResolver();

  let signalOptions: Array<{value: ScoringSignal; label: string}> = [];
  if (signalCounts.status === 'success') {
    signalOptions = filterNull(
      _.map(signalCounts.data, (count, signal) => {
        const signalDetails = scoringSignalResolver(signal);
        if (!signalDetails) {
          return null;
        }

        return {
          value: signalDetails.id,
          label: count
            ? `${signalDetails.label} (${count})`
            : signalDetails.label,
        };
      })
    );
  }

  const selectedSignalOptions = React.useMemo(
    () =>
      selectedSignals.map((signalGroup) =>
        filterNull(
          signalGroup.map((signal) => {
            const signalDetails = scoringSignalResolver(signal);
            if (!signalDetails) {
              return null;
            }

            return {
              value: signalDetails.id,
              label: signalDetails.label,
            };
          })
        )
      ),
    [scoringSignalResolver, selectedSignals]
  );

  return (
    <MultiGroupSelect
      addFilterText={'Add Signal Filter'}
      allOptions={signalOptions}
      groupSeparator={<Label>And any of these signals</Label>}
      isDisabled={!!isDisabled}
      maxGroups={3}
      noOptionsMessage={ScoringSignalFilterLoadingTexts[signalCounts.status]}
      onChange={setSelectedSignals}
      selectedOptions={selectedSignalOptions}
    />
  );
};

interface ImportSourceSelectProps {
  isDisabled?: boolean;
  queryParams: AccountQuery | '';
  selectedImportSources: ObjectId[];
  setSelectedImportSources: (importSources: ObjectId[]) => void;
}

const ImportSourceSelect = ({
  isDisabled,
  queryParams,
  selectedImportSources,
  setSelectedImportSources,
}: ImportSourceSelectProps) => {
  const metadata = useMetadata();
  const imports = metadata.imports;

  const importSourceCounts = useImportSourceCounts({
    queryParams,
    enabled: !isDisabled,
  });

  let importSourceOptions: Array<{value: ObjectId; label: string}> = [];
  if (importSourceCounts.status === 'success') {
    importSourceOptions = _.map(importSourceCounts.data, (count, id) => {
      const importSource = imports?.[id] ?? 'unknown';
      return {
        value: new ObjectId(id),
        label: `${importSource} (${count})`,
      };
    });
  }

  const selectedImportSourceOptions = React.useMemo(
    () =>
      selectedImportSources.map((importId) => {
        const importSource = imports?.[importId.toString()] ?? 'unknown';
        return {
          value: importId,
          label: importSource,
        };
      }),
    [selectedImportSources, imports]
  );

  return (
    <Select
      noOptionsMessage={() =>
        ScoringImportSourceFilterLoadingTexts[importSourceCounts.status]
      }
      options={importSourceOptions}
      isMulti
      onChange={(values) =>
        setSelectedImportSources(values.map((v) => v.value))
      }
      value={selectedImportSourceOptions}
      isDisabled={isDisabled}
    />
  );
};
