import React, {useContext} from 'react';
import {
  Box,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {AdminDiscoverExport} from './AdminDiscoverExport';
import {MyAccountsExport} from './MyAccountsExport';
import {useExportMutation} from '../useExportMutation';
import {useIsMyAccountsView} from '../AccountGrid.controlstate';
import {useIsKeyplayAdmin} from '../../../hooks/api/user';
import {isRestrictedField} from '../../../shared/enrichment';
import {useCustomer} from '../../../hooks/api/metadata';

interface ExtraExportFieldsProviderProps {
  includeSpecialFields: boolean;
  includeRestrictedFields: boolean;
}
const ExtraExportFieldsContext =
  React.createContext<ExtraExportFieldsProviderProps>({
    includeSpecialFields: false,
    includeRestrictedFields: false,
  });

function ExtraExportFieldsProvider({
  includeSpecialFields,
  includeRestrictedFields,
  children,
}: React.PropsWithChildren<{
  includeSpecialFields: boolean;
  includeRestrictedFields: boolean;
}>) {
  return (
    <ExtraExportFieldsContext.Provider
      value={{includeSpecialFields, includeRestrictedFields}}
    >
      {children}
    </ExtraExportFieldsContext.Provider>
  );
}

export function useExtraExportFields() {
  return useContext(ExtraExportFieldsContext);
}

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  numberOfAccounts: number;
}

export const ExportModal = ({
  isOpen,
  onClose,
  numberOfAccounts,
}: ExportModalProps) => {
  const isAdmin = useIsKeyplayAdmin();
  const isMyAccountsView = useIsMyAccountsView();
  const [includeRestrictedFields, setIncludeRestrictedFields] =
    React.useState(false);
  const [includeSpecialFields, setIncludeSpecialFields] = React.useState(false);

  const customerHasRestrictedFields =
    useCustomer().fieldDefinitions?.some(isRestrictedField);
  const showFooter = isAdmin || customerHasRestrictedFields;

  const showAdminDiscoverModal = !isMyAccountsView && isAdmin;

  const exportMutation = useExportMutation({
    onSuccess: (result, request) => {
      if (request.mode === 'async') {
        onClose();
      }
    },
  });

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="800" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          What would you like to export?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ExtraExportFieldsProvider
            includeSpecialFields={includeSpecialFields}
            includeRestrictedFields={includeRestrictedFields}
          >
            {showAdminDiscoverModal ? (
              <AdminDiscoverExport
                exportMutation={exportMutation}
                numberOfAccounts={numberOfAccounts}
              />
            ) : (
              <MyAccountsExport
                exportMutation={exportMutation}
                numberOfAccounts={numberOfAccounts}
              />
            )}
          </ExtraExportFieldsProvider>
        </ModalBody>

        {showFooter && (
          <ModalFooter>
            <Box display="flex" alignItems="center" gap={6}>
              {customerHasRestrictedFields && (
                <Checkbox
                  id="include-research-fields"
                  isChecked={includeRestrictedFields}
                  onChange={(e) => setIncludeRestrictedFields(e.target.checked)}
                >
                  Include Research Fields
                </Checkbox>
              )}
              {isAdmin && (
                <Checkbox
                  id="include-special-fields"
                  isChecked={includeSpecialFields}
                  onChange={(e) => setIncludeSpecialFields(e.target.checked)}
                >
                  Include Special Fields
                </Checkbox>
              )}
            </Box>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
