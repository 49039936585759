import React from 'react';
import {Flex, Text} from '@chakra-ui/react';

export const BuilderTitleSection = ({
  children,
  fieldLabel,
  title,
}: {
  children?: React.ReactNode;
  fieldLabel?: string;
  title: string;
}) => {
  return (
    <Flex
      alignItems="baseline"
      borderBottom="1px solid"
      borderColor="kgray.200"
      h="90px"
      justifyContent="space-between"
      p="6"
    >
      <Flex gap={3}>
        <Text fontSize="2xl" fontWeight="normal">
          {title}
        </Text>
        {fieldLabel && (
          <Text
            fontSize="2xl"
            fontWeight="normal"
            color="kgray.300"
            opacity={0.7}
          >
            {fieldLabel}
          </Text>
        )}
      </Flex>
      {children}
    </Flex>
  );
};
