import React from 'react';
import {
  Box,
  chakra,
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useAccountWithLogo} from '../../hooks/api/accountWithLogo';
import {ObjectId} from 'bson';
import _ from 'lodash';

const sampleAccountIds = [
  new ObjectId('632358243046e5126bfacb05'), // 15Five
  new ObjectId('62b4e42e4b78b45377cb6c60'), // ClickUp
  new ObjectId('62b135a41de7b1246567bd47'), // Contentsquare
  new ObjectId('62b135a41de7b1246567bf82'), // Datadog
  new ObjectId('62b135a71de7b1246567ccbb'), // Gusto
];

export const SamplePreviewTable = () => {
  return (
    <TableContainer
      bgColor="white"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      overflowY="auto"
      whiteSpace="normal"
    >
      <Table>
        <Thead whiteSpace="nowrap">
          <Tr>
            <HeaderCell>Account</HeaderCell>
            <HeaderCell>Your New AI Field</HeaderCell>
          </Tr>
        </Thead>
        <Tbody>
          {sampleAccountIds.map((accountId) => {
            return (
              <Tr key={accountId.toString()}>
                <Td w="200px">
                  <AccountCell accountId={accountId} />
                </Td>
                <Td>
                  <Skeleton h="2" speed={0} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const HeaderCell = chakra(Th, {
  baseStyle: {
    bgColor: 'gray.50',
    fontSize: 'sm',
    fontWeight: 'normal',
    position: 'sticky',
    top: 0,
    textColor: 'kgray.500',
  },
});

const AccountCell = ({accountId}: {accountId: ObjectId}) => {
  const {data: account, isPending} = useAccountWithLogo(accountId);

  if (isPending || !account) {
    return <Skeleton />;
  }

  return (
    <Flex direction="column">
      <Box>{account.name}</Box>
      <Box fontSize="sm" textColor="gray.400">
        {account.domain}
      </Box>
    </Flex>
  );
};
