import React from 'react';
import {
  Box,
  chakra,
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  AiFieldDefinition,
  AiFieldDefinitionConfig,
} from '../../shared/enrichment';
import {ObjectId} from 'bson';
import {isAxiosError} from 'axios';
import {useGetEnrichmentPreviewResult} from '../../hooks/api/fieldDefinitionPreview';
import {assertNever} from '../../shared/util';
import {useAccountWithLogo} from '../../hooks/api/accountWithLogo';
import _ from 'lodash';

// TODO: should be shared logic somewhere?
const isConfigEmpty = (config: AiFieldDefinitionConfig) => {
  const {fieldType} = config;

  switch (fieldType) {
    case 'boolean':
    case 'rating':
      return !config.prompt.trim();
    case 'category':
      return config.categories.length < 2;
    case 'research':
      return !config.prompt.input.trim();

    default:
      assertNever(fieldType);
  }
};

const BuilderPreviewRow = ({
  accountId,
  fieldDefinition,
}: {
  accountId: ObjectId;
  fieldDefinition: AiFieldDefinition;
}) => {
  const {id: fieldDefinitionId, config} = fieldDefinition;

  const retryFn = (failureCount: number, error: Error) => {
    if (isAxiosError(error) && error.response?.status === 503) {
      return failureCount < (config.fieldType === 'research' ? 300 : 30);
    }
    return false;
  };

  const result = useGetEnrichmentPreviewResult(
    {
      accountId,
      fieldDefinitionId,
    },
    // need to use server state here, because we don't want query to become enabled
    // before changes are persisted to server
    {enabled: !isConfigEmpty(config), retry: retryFn}
  );

  if (result.isFetching) {
    return (
      <Tr>
        <Td>
          <AccountCell accountId={accountId} />
        </Td>
        <Td>
          <Skeleton h="2" speed={0} />
        </Td>
        {config.fieldType !== 'research' && (
          <Td>
            <Skeleton h="2" speed={0} />
          </Td>
        )}
      </Tr>
    );
  } else if (!result.data) {
    return (
      <Tr>
        <Td>
          <AccountCell accountId={accountId} />
        </Td>
        <Td colSpan={2} textColor="kgray.300">
          -
        </Td>
      </Tr>
    );
  }

  const {value, reasoning} = result.data;

  return (
    <Tr>
      <Td>
        <AccountCell accountId={accountId} />
      </Td>
      <Td whiteSpace="pre-line">{_.toString(value)}</Td>
      {config.fieldType !== 'research' && <Td>{reasoning}</Td>}
    </Tr>
  );
};

export const BuilderPreviewTable = ({
  accountIds,
  fieldDefinition,
}: {
  accountIds: ObjectId[];
  fieldDefinition: AiFieldDefinition;
}) => {
  return (
    <TableContainer
      bgColor="white"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      overflowY="auto"
      whiteSpace="normal"
    >
      <Table>
        <Thead whiteSpace="nowrap">
          <Tr>
            <HeaderCell>Account</HeaderCell>
            <HeaderCell>Value</HeaderCell>
            {fieldDefinition.config.fieldType !== 'research' && (
              <HeaderCell>Reasoning</HeaderCell>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {accountIds.map((accountId) => {
            return (
              <BuilderPreviewRow
                key={accountId.toString()}
                accountId={accountId}
                fieldDefinition={fieldDefinition}
              />
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const HeaderCell = chakra(Th, {
  baseStyle: {
    bgColor: 'gray.50',
    fontSize: 'sm',
    fontWeight: 'normal',
    position: 'sticky',
    top: 0,
    textColor: 'kgray.500',
  },
});

const AccountCell = ({accountId}: {accountId: ObjectId}) => {
  const {data: account, isPending} = useAccountWithLogo(accountId);

  if (isPending || !account) {
    return <Skeleton />;
  }

  return (
    <Flex direction="column">
      <Box>{account.name}</Box>
      <Box fontSize="sm" textColor="gray.400">
        {account.domain}
      </Box>
    </Flex>
  );
};
