import React from 'react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  FormHelperText,
  Divider,
  Text,
  Select,
} from '@chakra-ui/react';
import {AnalysisFieldsEditor} from './AnalysisFieldsEditor';
import {KeyClarificationsEditor} from './KeyClarificationsEditor';
import {
  AiBooleanFieldDefinitionConfig,
  AiRatingFieldDefinitionConfig,
  AiResearchFieldDefinitionConfig,
  AiResearchFieldModeSchema,
  AiResearchFieldModeLabels,
  AiResearchFieldMode,
} from '../../../shared/enrichment';
import {useBuilderMode} from './BuilderModeContext';

export const PromptConfigEditor = ({
  config,
}: {
  config: AiBooleanFieldDefinitionConfig | AiRatingFieldDefinitionConfig;
}) => {
  const mode = useBuilderMode();
  const {setConfig} = useAiFieldBuilderStore();

  return (
    <Flex direction="column" gap="6">
      <Text fontSize="xl">Write your prompt</Text>

      <FormControl>
        <FormLabel textTransform="uppercase">Prompt</FormLabel>
        <Textarea
          isDisabled={mode === 'review'}
          onChange={(e) => {
            setConfig({
              ...config,
              prompt: e.target.value,
            });
          }}
          rows={5}
          placeholder="Ask your AI analyst anything..."
          value={config.prompt}
        />
        <FormHelperText textColor="kgray.300">
          Prompting is most successful when you phrase your prompt as a question
          about a single account, like “Does this company have brick and mortar
          locations?”
        </FormHelperText>
      </FormControl>

      <Divider />

      <AnalysisFieldsEditor config={config} />

      <Divider />

      <KeyClarificationsEditor config={config} />
    </Flex>
  );
};

export const ResearchFieldPromptConfigEditor = ({
  config,
}: {
  config: AiResearchFieldDefinitionConfig;
}) => {
  const builderMode = useBuilderMode();
  const {setConfig} = useAiFieldBuilderStore();

  return (
    <Flex direction="column" gap="6">
      <Text fontSize="xl">Write your prompt</Text>

      <FormControl>
        <FormLabel textTransform="uppercase">Research Mode</FormLabel>
        <Select
          isDisabled={builderMode === 'review'}
          value={config.mode}
          onChange={(e) => {
            setConfig({
              ...config,
              mode: e.target.value as AiResearchFieldMode,
            });
          }}
        >
          {AiResearchFieldModeSchema.options.map((mode) => (
            <option key={mode} value={mode}>
              {AiResearchFieldModeLabels[mode]}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel textTransform="uppercase">Prompt</FormLabel>
        <Textarea
          isDisabled={builderMode === 'review'}
          onChange={(e) => {
            setConfig({
              ...config,
              prompt: {input: e.target.value},
            });
          }}
          rows={5}
          placeholder="Ask your AI analyst anything..."
          value={config.prompt.input}
        />
        <FormHelperText textColor="kgray.300">
          Prompting is most successful when you phrase your prompt as a question
          about a single account, like &quot;Does this company have brick and
          mortar locations?&quot;
        </FormHelperText>
      </FormControl>
    </Flex>
  );
};
