import React from 'react';
import {Box, FlexProps, Flex, Button, Tooltip} from '@chakra-ui/react';
import {
  ExportType,
  AccountQuery,
  ExportRequest,
  ExportResponse,
  isExtraFieldExportType,
  isAccountQueryExportType,
  ExportWithExtraFieldsMaxRows,
  PageScrapeExportMaxRows,
} from '../../../shared/api/api';
import {useAccountQueryParams} from '../AccountGrid.controlstate';
import {UseMutationResult} from '@tanstack/react-query';
import {useExtraExportFields} from './ExportModal';

type ExportModalSectionProps = FlexProps & {
  children: React.ReactNode;
  buttonText: string;
  exportMutation: UseMutationResult<ExportResponse, unknown, ExportRequest>;
  numberOfAccounts: number;
  type: ExportType;
  mode?: 'sync' | 'async';
};

export const ExportModalSection = ({
  children,
  buttonText,
  type,
  mode = 'async',
  exportMutation,
  numberOfAccounts,
  ...props
}: ExportModalSectionProps) => {
  const accountQuery = useAccountQueryParams();
  const {includeSpecialFields, includeRestrictedFields} =
    useExtraExportFields();
  const includeExtraFields = includeSpecialFields || includeRestrictedFields;

  const handleExportClick = async () => {
    let query: AccountQuery | undefined;
    if (isAccountQueryExportType(type)) {
      query = accountQuery;
    }

    exportMutation.mutate({
      type,
      query,
      mode,
      includeSpecialFields,
      includeRestrictedFields,
    });
  };

  const tooltipLabel = [
    (type === 'page_text' || type === 'page_source') &&
    numberOfAccounts > PageScrapeExportMaxRows
      ? `Export is limited to ${PageScrapeExportMaxRows} rows`
      : null,
    includeExtraFields && !isExtraFieldExportType(type)
      ? 'This export does not support extra fields'
      : null,
    includeExtraFields && numberOfAccounts > ExportWithExtraFieldsMaxRows
      ? `Export is limited to ${ExportWithExtraFieldsMaxRows} rows`
      : null,
  ].find((label) => label !== null);
  const isDisabled = !!tooltipLabel;

  return (
    <Flex border="1px" borderColor="kgray.200" p={4} mb={4} {...props}>
      <Box flexGrow="1">{children}</Box>
      <Tooltip label={tooltipLabel} isDisabled={!isDisabled}>
        <Button
          alignSelf="center"
          border="1px"
          colorScheme="kbuttonblue"
          variant="ghost"
          onClick={handleExportClick}
          isLoading={exportMutation.isPending}
          isDisabled={isDisabled}
          _hover={{
            bg: 'kblue.50',
          }}
          fontSize={14}
          fontWeight={500}
          px={6}
          py={3}
          transitionDuration="200ms"
          transitionProperty="background-color,border-color,color"
        >
          {buttonText}
        </Button>
      </Tooltip>
    </Flex>
  );
};
