import React, {useContext} from 'react';
import {IntegrationResponse} from '../../shared/api/api';
import {AllIntegrations} from './AllIntegrations';

const IntegrationContext = React.createContext<IntegrationResponse | null>(
  null
);

export const IntegrationProvider = ({
  children,
  integrationResponse,
}: {
  children: React.ReactNode;
  integrationResponse: IntegrationResponse;
}) => {
  return (
    <IntegrationContext.Provider value={integrationResponse}>
      {children}
    </IntegrationContext.Provider>
  );
};

export const useIntegrationFromContext = () => {
  const integration = useContext(IntegrationContext);
  if (!integration) {
    throw new Error('Integration details not found');
  }

  return integration;
};

export const useIntegrationStaticInfo = () => {
  const integration = useIntegrationFromContext();
  return AllIntegrations[integration.type];
};
