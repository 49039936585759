import React from 'react';
import {Box, Flex, Grid, Switch, Text, Tooltip} from '@chakra-ui/react';
import {
  IntegrationType,
  FieldMappingLabels,
  ManagedFieldMappingField,
  ManagedFieldMapping,
  ManagedFieldMappingCrmLabels,
  ManagedFieldMappingCrmNames,
  OptionalManagedFieldMappingFields,
  CoreManagedFieldMappingFields,
  isManagedFieldMappingField,
} from '../../shared/integrations';

import {
  GridCell,
  GridHeader,
  IntegrationSection,
  gridTemplateColumns,
} from './components';
import {ChevronRight} from '@carbon/icons-react';
import {useIntegrationDetailsState} from './IntegrationDetails.state';
import _ from 'lodash';
import {
  useIntegrationFromContext,
  useIntegrationStaticInfo,
} from './IntegrationDetailsContext';

export const ManagedEnrichmentFieldMapping = () => {
  const integration = useIntegrationFromContext();
  const integrationInfo = useIntegrationStaticInfo();

  const {
    addManagedField,
    deleteManagedField,
    publishChanges,
    managedFieldMappings,
    serverState,
  } = useIntegrationDetailsState();

  const availableFieldMappingFields = CoreManagedFieldMappingFields.concat(
    _.intersection(
      (serverState?.config?.optionalFields ?? []).filter(
        isManagedFieldMappingField
      ),
      OptionalManagedFieldMappingFields
    )
  );

  // Use all standard fields as the source to drive the list of field mappings to display.
  // For each one, if there exists an existing mapping, use its configuration.
  const mergedFieldMappings: ManagedFieldMapping[] =
    availableFieldMappingFields.map((field) => {
      const existingField = managedFieldMappings.find(
        (managedFieldMapping) => managedFieldMapping.enrichmentField === field
      );

      return {
        crmFieldName: getCrmFieldName(field, integration.type),
        enrichmentField: field,
        enrichmentFieldType: 'managed',
        kind: 'keyplay',
        enabled: !!existingField?.enabled,
      };
    });

  const onUpdateEnrichmentField = (
    fieldMapping: ManagedFieldMapping,
    enabled: boolean
  ) => {
    if (enabled) {
      addManagedField({
        ...fieldMapping,
        enabled,
      });
      publishChanges.mutate();
    } else {
      deleteManagedField(fieldMapping.enrichmentField);
      publishChanges.mutate();
    }
  };

  return (
    <IntegrationSection>
      <Box fontSize={'lg'} fontWeight={500} mb={6}>
        Managed Field Mappings
      </Box>
      <Text mb={10}>
        When enabling a managed field, Keyplay will create the field
        automatically on your {integrationInfo.label} company record within a
        “Keyplay Enrichment” field group. Values are refreshed weekly.
      </Text>

      <Grid templateColumns={gridTemplateColumns} gap={2}>
        <GridHeader></GridHeader>
        <GridHeader>Keyplay Field</GridHeader>
        <GridHeader></GridHeader>
        <GridHeader>{integrationInfo.label} Field</GridHeader>

        {mergedFieldMappings.map((fieldMapping) => (
          <ManagedEnrichmentFieldGridRow
            key={fieldMapping.enrichmentField}
            fieldMapping={fieldMapping}
            onUpdateEnrichmentField={onUpdateEnrichmentField}
          />
        ))}
      </Grid>
    </IntegrationSection>
  );
};

const CrmFieldCell = ({name, label}: {name: string; label: string}) => {
  return (
    <Flex direction="column">
      <Tooltip label={name} placement="top">
        <Box>{label}</Box>
      </Tooltip>
    </Flex>
  );
};

const getCrmFieldName = (
  fieldMappingField: ManagedFieldMappingField,
  integrationName: IntegrationType
): string => {
  const fieldValue = ManagedFieldMappingCrmNames[fieldMappingField];

  if (integrationName === 'salesforce') {
    // Convert string format for Salesforce
    // Camel case (with first work capitalized) and add __c
    const words = fieldValue.split('_');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join('') + '__c';
  }

  // Return original string for HubSpot
  return fieldValue;
};

const ManagedEnrichmentFieldGridRow = ({
  fieldMapping,
  onUpdateEnrichmentField,
}: {
  fieldMapping: ManagedFieldMapping;
  onUpdateEnrichmentField: (
    fieldMapping: ManagedFieldMapping,
    enabled: boolean
  ) => void;
}) => {
  return (
    <>
      <GridCell>
        <Switch
          isChecked={fieldMapping.enabled}
          onChange={(event) => {
            onUpdateEnrichmentField(fieldMapping, event.target.checked);
          }}
        />
      </GridCell>
      <GridCell hasBorder>
        {FieldMappingLabels[fieldMapping.enrichmentField]}
      </GridCell>
      <GridCell>
        <ChevronRight />
      </GridCell>
      <GridCell hasBorder>
        <CrmFieldCell
          name={fieldMapping.crmFieldName}
          label={ManagedFieldMappingCrmLabels[fieldMapping.enrichmentField]}
        />
      </GridCell>
    </>
  );
};
