import React from 'react';
import {ExportModalSection} from './ExportModalSection';
import {Heading, Text} from '@chakra-ui/react';
import {UseMutationResult} from '@tanstack/react-query';
import {ExportResponse, ExportRequest} from '../../../shared/api/api';

interface AdminDiscoverExportProps {
  numberOfAccounts: number;
  exportMutation: UseMutationResult<ExportResponse, unknown, ExportRequest>;
}

export const AdminDiscoverExport = ({
  exportMutation,
  numberOfAccounts,
}: AdminDiscoverExportProps) => {
  return (
    <>
      <ExportModalSection
        numberOfAccounts={numberOfAccounts}
        buttonText="Export CSV"
        type="filtered_accounts"
        exportMutation={exportMutation}
        bgColor={'kyellow.50'}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          Scored Discover Accounts (Admin Only)
        </Heading>
        <Text>
          {Number(numberOfAccounts || 0).toLocaleString()} accounts matching
          your current filters, keyed by <Text as="u">domain</Text>.
        </Text>
      </ExportModalSection>

      <ExportModalSection
        numberOfAccounts={numberOfAccounts}
        buttonText="Export CSV"
        type="discover"
        exportMutation={exportMutation}
        bgColor={'kyellow.50'}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          Entire SAM (Admin Only)
        </Heading>
        <Text maxW="512px">
          Full list of recommended accounts that match SAM (will bypass 10k
          limit). Does not include Keyplay Fit or similar accounts. This is the
          same logic we use to generate freemium purchases.
        </Text>
      </ExportModalSection>
    </>
  );
};
