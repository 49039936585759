import {useMutation} from '@tanstack/react-query';
import React, {useEffect} from 'react';
import {useSearchParams, Navigate, useNavigate} from 'react-router-dom';
import {useKeyplayApi} from '../../context/ApiContext';
import {OAuthPayload, OAuthPayloadSchema} from '../../shared/api/api';
import {Center, Spinner, useToast} from '@chakra-ui/react';
import {captureMessage} from '@sentry/react';

function parseOauthPayload(params: string): OAuthPayload | null {
  const {code, state} = JSON.parse(params);
  try {
    const {integrationName, isSandbox} = JSON.parse(state);

    const oauthPayload = {
      code,
      integrationName,
      isSandbox,
    };
    return OAuthPayloadSchema.parse(oauthPayload);
  } catch {
    return null;
  }
}

const CallbackHandling = () => {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const oauthPayload = parseOauthPayload(atob(params['params']));

  if (!oauthPayload) {
    captureMessage('Invalid OAuth payload', {
      extra: {params},
      level: 'error',
    });

    toast({
      title: 'Something went wrong!',
      description: 'Please contact Keyplay for assistance.',
      status: 'error',
      isClosable: true,
    });
    return <Navigate to={'/settings/integrations'} />;
  }

  return <_CallbackHandling oauthPayload={oauthPayload} />;
};

const _CallbackHandling = ({oauthPayload}: {oauthPayload: OAuthPayload}) => {
  const toast = useToast();
  const makeApiCall = useKeyplayApi();
  const navigate = useNavigate();

  const {mutate: updateOAuth} = useMutation({
    mutationFn: (payload: OAuthPayload) => {
      return makeApiCall<void, OAuthPayload>('/integrations/oauth', {
        method: 'post',
        data: payload,
      });
    },
  });

  useEffect(() => {
    updateOAuth(oauthPayload, {
      onSuccess: () => {
        navigate(`/settings/integrations/${oauthPayload.integrationName}`);
      },

      onError: (error) => {
        captureMessage('Error updating integration OAuth config', {
          extra: {error, oauthPayload},
          level: 'error',
        });

        toast({
          title: 'Something went wrong!',
          description: 'Please contact Keyplay for assistance.',
          status: 'error',
          isClosable: true,
        });

        navigate('/settings/integrations');
      },
    });

    // clear search parameters in url
    history.replaceState(null, '', location.pathname);
  }, [oauthPayload, updateOAuth, navigate, toast]);

  return (
    <Center mt={8}>
      <Spinner />
    </Center>
  );
};

export default CallbackHandling;
