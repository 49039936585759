import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
  VStack,
  Code,
  Text,
  Flex,
  Divider,
  Heading,
  Badge,
  Checkbox,
  Stack,
  Collapse,
} from '@chakra-ui/react';
import {
  GptModel,
  GptModelSchema,
  GptTool,
  GptToolSchema,
} from '../../shared/enrichment';
import {isAxiosError} from 'axios';
import {useAiDebugStore} from './AiDebug.state';
import {createApiQuery} from '../../hooks/api/api';
import {GetAiDebugCompletion} from '../../shared/api/definitions';

const useAiDebugCompletion = createApiQuery(
  '/debug/aiCompletion',
  GetAiDebugCompletion,
  (params) => ['aiDebugCompletion', params],
  {
    retryDelay: 1_000,
    staleTime: Infinity,
  }
);

export const AiDebug = () => {
  // Local UI states
  const [isEnabled, setIsEnabled] = useState(false);
  const [messagesOpen, setMessagesOpen] = useState(false);
  const [tokenUsageOpen, setTokenUsageOpen] = useState(false);
  const [toolsUsedOpen, setToolsUsedOpen] = useState(false);

  // Get persisted state and actions from Zustand store
  const {
    prompt,
    setPrompt,
    model,
    setModel,
    temperature,
    setTemperature,
    selectedTools,
    addTool,
    removeTool,
  } = useAiDebugStore();

  const temperatureEnabled = model === 'gpt-4o-mini' || model === 'gpt-4o';
  const toolsEnabled = model !== 'o1-mini';

  // Only include tools if they're enabled and at least one is selected
  const {data, isLoading} = useAiDebugCompletion(
    {
      model,
      prompt,
      ...(temperatureEnabled ? {temperature: Number(temperature)} : {}),
      ...(toolsEnabled && selectedTools.length > 0
        ? {tools: selectedTools}
        : {}),
    },
    {
      enabled: isEnabled,
      retry: (failureCount: number, error: Error) => {
        if (isAxiosError(error) && error.response?.status === 503) {
          return failureCount < 300;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    // Reset enabled flag after request completes
    if (isLoading || !isEnabled) {
      return;
    }
    setIsEnabled(false);
  }, [isLoading, isEnabled]);

  // Reset collapsible sections when new data comes in
  useEffect(() => {
    if (data) {
      setMessagesOpen(false);
      setTokenUsageOpen(false);
      setToolsUsedOpen(false);
    }
  }, [data]);

  // Handle tool checkbox changes
  const handleToolChange = (tool: GptTool, isChecked: boolean) => {
    if (isChecked) {
      addTool(tool);
    } else {
      removeTool(tool);
    }
  };

  return (
    <Flex height="100vh" width="100%">
      {/* Left Pane - Controls */}
      <Box
        p={6}
        width="50%"
        borderRight="1px"
        borderColor="gray.200"
        overflowY="auto"
      >
        <VStack spacing={6} align="stretch">
          <Heading size="lg" mb={4}>
            AI Debug Tool
          </Heading>

          <FormControl isDisabled={isLoading}>
            <FormLabel>Model</FormLabel>
            <Select
              value={model}
              onChange={(e) => setModel(e.target.value as GptModel)}
              isDisabled={isLoading}
            >
              {GptModelSchema.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <FormLabel>Temperature</FormLabel>
            <NumberInput
              value={temperature}
              isDisabled={!temperatureEnabled || isLoading}
              onChange={(value) => setTemperature(value)}
              clampValueOnBlur={true}
              min={0}
              max={2}
              step={0.01}
              precision={2}
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <FormLabel>Tools</FormLabel>
            <Stack spacing={2} direction="column">
              {GptToolSchema.options.map((tool) => (
                <Checkbox
                  key={tool}
                  isChecked={selectedTools.includes(tool)}
                  isDisabled={!toolsEnabled || isLoading}
                  onChange={(e) => handleToolChange(tool, e.target.checked)}
                >
                  {tool}
                </Checkbox>
              ))}
            </Stack>
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <FormLabel>Prompt</FormLabel>
            <Textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter your prompt here..."
              rows={8}
              isDisabled={isLoading}
            />
          </FormControl>

          <Button
            colorScheme="kbuttonblue"
            onClick={() => setIsEnabled(true)}
            isLoading={isLoading}
            isDisabled={isLoading || !prompt.trim()}
            mb={4}
          >
            Run Completion
          </Button>
        </VStack>
      </Box>

      {/* Right Pane - Results */}
      <Box p={6} width="50%" bg="gray.50" overflowY="auto">
        <Heading size="lg" mb={4}>
          Results
          {isLoading && (
            <Badge colorScheme="blue" ml={2} fontSize="md">
              Loading...
            </Badge>
          )}
        </Heading>

        {data && (
          <VStack spacing={6} align="stretch">
            <Box>
              <Heading size="md" mb={2}>
                Completion Result
              </Heading>
              <Box
                bg="white"
                p={4}
                borderRadius="md"
                whiteSpace="pre-wrap"
                fontFamily="monospace"
                border="1px"
                borderColor="gray.200"
              >
                {data.result}
              </Box>
            </Box>

            <Divider />

            <Box>
              <Flex
                justify="space-between"
                align="center"
                onClick={() => setMessagesOpen(!messagesOpen)}
                cursor="pointer"
                p={2}
                bg="gray.100"
                borderRadius="md"
                _hover={{bg: 'gray.200'}}
              >
                <Heading size="md">Messages</Heading>
                <Text fontSize="sm">
                  {messagesOpen ? 'Hide' : 'Show'} ({data.messages?.length || 0}
                  )
                </Text>
              </Flex>

              <Collapse in={messagesOpen} animateOpacity>
                <Box pt={4}>
                  {data.messages && data.messages.length > 0 ? (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (data.messages as any[]).map((message, index) => (
                      <Box
                        key={index}
                        p={3}
                        mb={2}
                        bg="white"
                        borderRadius="md"
                        border="1px"
                        borderColor="gray.200"
                      >
                        <Flex justify="space-between" mb={2}>
                          <Badge
                            colorScheme={
                              message.role === 'user' ? 'blue' : 'green'
                            }
                          >
                            {message.role || 'unknown'}
                          </Badge>
                          <Text fontSize="sm" color="gray.500">
                            {index + 1} of {data.messages.length}
                          </Text>
                        </Flex>

                        {/* Display message content if it exists */}
                        {message.content !== null && (
                          <>
                            {/* Try to parse as JSON and format if it's valid JSON */}
                            {(() => {
                              try {
                                // Check if content is a string and looks like JSON
                                if (
                                  typeof message.content === 'string' &&
                                  (message.content.trim().startsWith('{') ||
                                    message.content.trim().startsWith('['))
                                ) {
                                  const jsonData = JSON.parse(message.content);
                                  return (
                                    <Box>
                                      <Flex align="center" mb={1}>
                                        <Badge colorScheme="blue" mr={2}>
                                          JSON
                                        </Badge>
                                        <Text fontSize="sm" color="gray.500">
                                          Formatted content
                                        </Text>
                                      </Flex>
                                      <Code
                                        display="block"
                                        p={2}
                                        overflowX="auto"
                                        bg="gray.50"
                                        borderRadius="md"
                                      >
                                        {JSON.stringify(jsonData, null, 2)}
                                      </Code>
                                    </Box>
                                  );
                                }
                                // Not JSON or not a string, display as regular text
                                return (
                                  <Text whiteSpace="pre-wrap">
                                    {message.content}
                                  </Text>
                                );
                              } catch (e) {
                                // If parsing fails, it's not valid JSON, display as regular text
                                return (
                                  <Text whiteSpace="pre-wrap">
                                    {message.content}
                                  </Text>
                                );
                              }
                            })()}
                          </>
                        )}

                        {/* Display tool calls if they exist */}
                        {message.tool_calls &&
                          message.tool_calls.length > 0 && (
                            <Box mt={2}>
                              <Text fontWeight="bold" mb={1}>
                                Tool Calls:
                              </Text>
                              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                              {(message.tool_calls as any[]).map((toolCall) => (
                                <Box
                                  key={toolCall.id}
                                  p={2}
                                  mt={1}
                                  bg="gray.50"
                                  borderRadius="md"
                                  border="1px"
                                  borderColor="gray.200"
                                >
                                  <Flex gap={2} flexWrap="wrap" mb={1}>
                                    <Badge colorScheme="purple">
                                      {toolCall.type}
                                    </Badge>
                                    <Text fontSize="xs" color="gray.500">
                                      ID: {toolCall.id}
                                    </Text>
                                  </Flex>

                                  {toolCall.function && (
                                    <Box mt={1}>
                                      <Flex align="center" gap={2}>
                                        <Badge colorScheme="teal">
                                          function
                                        </Badge>
                                        {toolCall.function.name && (
                                          <Text fontWeight="medium">
                                            {toolCall.function.name}
                                          </Text>
                                        )}
                                      </Flex>

                                      {toolCall.function.arguments && (
                                        <Box mt={1}>
                                          <Text
                                            fontSize="sm"
                                            fontWeight="medium"
                                          >
                                            Arguments:
                                          </Text>
                                          {(() => {
                                            try {
                                              // Try to parse the JSON arguments
                                              const parsedArgs = JSON.parse(
                                                toolCall.function.arguments
                                              );
                                              return (
                                                <Code
                                                  display="block"
                                                  p={2}
                                                  mt={1}
                                                  overflowX="auto"
                                                  fontSize="sm"
                                                >
                                                  {JSON.stringify(
                                                    parsedArgs,
                                                    null,
                                                    2
                                                  )}
                                                </Code>
                                              );
                                            } catch (e) {
                                              // If parsing fails, show the raw string
                                              return (
                                                <Text
                                                  fontSize="sm"
                                                  p={2}
                                                  fontStyle="italic"
                                                  color="red.500"
                                                >
                                                  Invalid JSON:{' '}
                                                  {toolCall.function.arguments}
                                                </Text>
                                              );
                                            }
                                          })()}
                                        </Box>
                                      )}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          )}
                      </Box>
                    ))
                  ) : (
                    <Text>No messages available</Text>
                  )}
                </Box>
              </Collapse>
            </Box>

            <Divider />

            <Box>
              <Flex
                justify="space-between"
                align="center"
                onClick={() => setTokenUsageOpen(!tokenUsageOpen)}
                cursor="pointer"
                p={2}
                bg="gray.100"
                borderRadius="md"
                _hover={{bg: 'gray.200'}}
              >
                <Heading size="md">Token Usage</Heading>
                <Text fontSize="sm">{tokenUsageOpen ? 'Hide' : 'Show'}</Text>
              </Flex>

              <Collapse in={tokenUsageOpen} animateOpacity>
                <Box pt={4}>
                  <Code display="block" p={2} whiteSpace="pre-wrap">
                    {JSON.stringify(data.usage, null, 2)}
                  </Code>
                </Box>
              </Collapse>
            </Box>

            {selectedTools.length > 0 && (
              <>
                <Divider />
                <Box>
                  <Flex
                    justify="space-between"
                    align="center"
                    onClick={() => setToolsUsedOpen(!toolsUsedOpen)}
                    cursor="pointer"
                    p={2}
                    bg="gray.100"
                    borderRadius="md"
                    _hover={{bg: 'gray.200'}}
                  >
                    <Heading size="md">Tools Used</Heading>
                    <Text fontSize="sm">
                      {toolsUsedOpen ? 'Hide' : 'Show'} ({selectedTools.length})
                    </Text>
                  </Flex>

                  <Collapse in={toolsUsedOpen} animateOpacity>
                    <Box pt={4}>
                      <Flex gap={2} flexWrap="wrap">
                        {selectedTools.map((tool) => (
                          <Badge key={tool} colorScheme="purple" px={2} py={1}>
                            {tool}
                          </Badge>
                        ))}
                      </Flex>
                    </Box>
                  </Collapse>
                </Box>
              </>
            )}
          </VStack>
        )}
      </Box>
    </Flex>
  );
};
