import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {GptModel, GptTool} from '../../shared/enrichment';

interface AiDebugState {
  // Persisted form state
  prompt: string;
  model: GptModel;
  temperature: string;
  selectedTools: GptTool[];

  // Actions
  setPrompt: (prompt: string) => void;
  setModel: (model: GptModel) => void;
  setTemperature: (temperature: string) => void;
  addTool: (tool: GptTool) => void;
  removeTool: (tool: GptTool) => void;
  clearTools: () => void;
}

export const useAiDebugStore = create<AiDebugState>()(
  persist(
    (set) => ({
      // Default state
      prompt: '',
      model: 'gpt-4o',
      temperature: '0',
      selectedTools: [],

      // Actions
      setPrompt: (prompt) => set({prompt}),
      setModel: (model) => set({model}),
      setTemperature: (temperature) => set({temperature}),

      addTool: (tool) =>
        set((state) => ({
          selectedTools: [...state.selectedTools, tool],
        })),

      removeTool: (tool) =>
        set((state) => ({
          selectedTools: state.selectedTools.filter((t) => t !== tool),
        })),

      clearTools: () => set({selectedTools: []}),
    }),
    {
      name: 'ai-debug-storage',
    }
  )
);
