import React from 'react';
import {
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import {Navigate, useParams} from 'react-router';
import {Link as ReactRouterLink} from 'react-router-dom';
import {AllIntegrations} from './AllIntegrations';
import {useIntegration} from '../../hooks/api/integrations';
import {IntegrationStatus} from '../../shared/integrations';
import {IntegrationType, isIntegrationType} from '../../shared/integrations';
import _ from 'lodash';
import {ManagedEnrichmentFieldMapping} from './ManagedEnrichmentFieldMapping';
import {AdditionalEnrichmentFieldMapping} from './AdditionalEnrichmentFieldMapping';
import {Settings} from './SyncSettings';
import {StatusHeader} from './StatusHeader';
import {useIsFreeOrListBuilderCustomer} from '../../hooks/api/metadata';
import {Title} from '../Title';
import {IntegrationProvider} from './IntegrationDetailsContext';
import {DelayedSpinner} from '../DelayedSpinner';

export const IntegrationDetails = () => {
  const {integration} = useParams();

  if (!isIntegrationType(integration)) {
    return <Navigate to={'/settings/integrations'} />;
  }

  return (
    <Title title={AllIntegrations[integration].label}>
      <IntegrationDetails_ integrationType={integration} />
    </Title>
  );
};

const IntegrationDetails_ = ({
  integrationType,
}: {
  integrationType: IntegrationType;
}) => {
  const {data, isError, isPending} = useIntegration(integrationType);
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();

  if (isError) {
    return <Navigate to={'/settings/integrations'} />;
  } else if (isPending) {
    return (
      <Center h="100%">
        <DelayedSpinner delayMs={100} />
      </Center>
    );
  }

  return (
    <IntegrationProvider integrationResponse={data}>
      <Flex direction="column">
        <Flex direction="column" py={4} px={8} bgColor={'kgray.100'}>
          {/* Navigation */}
          <Breadcrumb separator={'>'}>
            <BreadcrumbItem>
              <BreadcrumbLink
                as={ReactRouterLink}
                to={'/settings/integrations'}
              >
                Integrations
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>
                {AllIntegrations[integrationType].label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <StatusHeader />
        </Flex>

        {/* Body */}
        <Flex direction="column" my={8} gap={4}>
          <Settings />
          <ManagedEnrichmentFieldMapping />
          {!isFreeOrListBuilderCustomer && <AdditionalEnrichmentFieldMapping />}
        </Flex>
      </Flex>
    </IntegrationProvider>
  );
};

const StatusText = {
  active: 'Connected',
  disconnected: 'Disconnected',
  refresh_token_expired: 'Auth Error',
} satisfies Record<IntegrationStatus, string>;

const StatusStyle = {
  active: {
    bgColor: 'kgreen.100',
    color: 'kgreen.500',
  },
  disconnected: {
    bgColor: 'kyellow.100',
    color: 'kgray.400',
  },
  refresh_token_expired: {
    bgColor: 'kred.100',
    color: 'kred.500',
  },
} satisfies Record<IntegrationStatus, Partial<BoxProps>>;

type StatusBadgeProps = BoxProps & {
  status?: IntegrationStatus;
};

export const StatusBadge = ({status, ...props}: StatusBadgeProps) => {
  if (!status) {
    return <Spinner size="sm" />;
  }

  return (
    <Box
      borderRadius={'md'}
      fontSize={'xs'}
      p={1}
      textTransform={'uppercase'}
      {...StatusStyle[status]}
      {...props}
    >
      {StatusText[status]}
    </Box>
  );
};
