import React, {useEffect} from 'react';
import {
  LeftColumnContent,
  RightColumnContent,
  TwoColumnGrid,
} from '../AiFieldBuilder/helpers';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  useModalContext,
} from '@chakra-ui/react';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {AgentBuilderPageIndicator} from './AgentBuilderPageIndicator';
import {useAgentBuilderNavStore} from './AgentBuilderNav.state';
import {SamplePreviewTable} from '../../Ai/SampleTablePreview';
import {
  AiResearchFieldMode,
  AiResearchFieldModeLabels,
  AiResearchFieldModeSchema,
  isAiResearchFieldDefinition,
} from '../../../shared/enrichment';
import {useCustomer} from '../../../hooks/api/metadata';
import {useFieldDefinitionEditorStore} from './AiFieldDefinitionEditor.state';
import {
  useAgentBuilderStore,
  useRequiredAgentBuilderServerState,
} from './AgentBuilder.state';
import {BuilderPreviewTable} from '../../Ai/BuilderPreviewTable';
import {useIsFetching} from '@tanstack/react-query';
import {EnrichmentPreviewResultQueryKeys} from '../../../hooks/api/fieldDefinitionPreview';
import _ from 'lodash';

export const AgentBuilderEditResearchFieldStep = () => {
  const agentId = useRequiredAgentBuilderServerState().id;
  const {previewAccountIds} = useAgentBuilderStore();
  const {prevStep} = useAgentBuilderNavStore();

  const {updateServerState, publishChanges, reset, hasChanges} =
    useFieldDefinitionEditorStore();
  const {onClose} = useModalContext();

  const fieldDefinitions = useCustomer().fieldDefinitions ?? [];
  const agentResearchField = fieldDefinitions
    .filter(isAiResearchFieldDefinition)
    .find((fieldDefinition) => fieldDefinition.agentId?.equals(agentId));

  // 1. Initialize the store with the agent research field on mount
  // 2. Reset the store when the user navigates away
  useEffect(() => {
    if (agentResearchField) {
      updateServerState({
        serverState: agentResearchField,
        updateMode: 'initialLoad',
      });
    }

    return reset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAndExit = () => {
    publishChanges.mutate(undefined, {onSuccess: onClose});
  };

  const runningPreviewCount = useIsFetching({
    predicate: (query) => {
      if (!agentResearchField) {
        return false;
      }

      return _.isMatch(
        query.queryKey,
        EnrichmentPreviewResultQueryKeys.field({
          fieldDefinitionId: agentResearchField.id,
        })
      );
    },
  });

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Box position="relative" h="100%">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <LeftColumnContent>
                <AgentBuilderPageIndicator />
                <ResearchFieldEditor />
              </LeftColumnContent>
            </ScrollableFlex>
          </WrapperFlex>
        </Box>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4} gap={10}>
          <Button onClick={prevStep} variant="ghost">
            Back
          </Button>

          <Button
            colorScheme="kbuttonblue"
            isLoading={publishChanges.isPending}
            onClick={saveAndExit}
          >
            Save & Exit
          </Button>
        </Flex>
      </Flex>

      <RightColumnContent>
        <Flex alignItems="baseline" justifyContent="space-between" mt="-6px">
          <Text fontSize="xl">Preview</Text>
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!hasChanges()}
            isLoading={runningPreviewCount > 0}
            onClick={() => publishChanges.mutate(undefined)}
          >
            Run Prompt to Preview
          </Button>
        </Flex>

        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              {agentResearchField ? (
                <BuilderPreviewTable
                  accountIds={previewAccountIds}
                  fieldDefinition={agentResearchField}
                />
              ) : (
                <SamplePreviewTable />
              )}
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </RightColumnContent>
    </TwoColumnGrid>
  );
};

export const ResearchFieldEditor = () => {
  const {config, setConfig, label, setLabel} = useFieldDefinitionEditorStore();
  if (config && config.fieldType !== 'research') {
    return <>Field type is not research</>;
  }

  return (
    <Flex direction="column" gap="6">
      <Text fontSize="xl">Prompt your Researcher</Text>

      <FormControl>
        <FormLabel fontSize="sm" textTransform="uppercase">
          Researcher Name
        </FormLabel>
        <Input
          type="text"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm" textTransform="uppercase">
          Research Mode
        </FormLabel>
        <Select
          value={config?.mode ?? ('structured' as const)}
          onChange={(e) => {
            setConfig({
              ...(config
                ? config
                : {
                    analysisFields: [],
                    model: 'gpt-4o-mini',
                    fieldType: 'research',
                    prompt: {input: ''},
                    tools: ['perplexity'],
                  }),
              mode: e.target.value as AiResearchFieldMode,
            });
          }}
        >
          {AiResearchFieldModeSchema.options.map((mode) => (
            <option key={mode} value={mode}>
              {AiResearchFieldModeLabels[mode]}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm" textTransform="uppercase">
          Research Question
        </FormLabel>
        <Textarea
          onChange={(e) => {
            setConfig({
              ...(config
                ? config
                : {
                    analysisFields: [],
                    model: 'gpt-4o-mini',
                    fieldType: 'research',
                    mode: 'structured',
                    tools: ['perplexity'],
                  }),
              prompt: {
                input: e.target.value,
              },
            });
          }}
          rows={5}
          placeholder="Ask your AI analyst anything..."
          value={config?.prompt.input ?? ''}
        />
        <FormHelperText textColor="kgray.300">
          Prompting is most successful when you phrase your prompt as a question
          about a single account, like &quot;Does this company have brick and
          mortar locations?&quot;
        </FormHelperText>
      </FormControl>
    </Flex>
  );
};
