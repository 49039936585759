import React from 'react';
import {Box, Button, Divider, Flex, Text} from '@chakra-ui/react';
import {AccountTypeahead} from '../../AccountTypeahead';
import {AccountList} from '../../AccountList';
import {MaxBulkPreviewAccounts} from '../../../shared/validation';
import {useAgentBuilderStore} from './AgentBuilder.state';
import {AccountImportButton} from '../../AccountImportButton';
import {BuilderCenteredContent} from '../../Ai/BuilderCenteredContent';
import {BuilderFooter} from '../../Ai/BuilderFooter';
import {useAgentBuilderNavStore} from './AgentBuilderNav.state';
import {AgentBuilderPageIndicator} from './AgentBuilderPageIndicator';

export const AgentBuilderSelectAccountsStep = () => {
  const {previewAccountIds, addAccount, removeAccount, publishChanges} =
    useAgentBuilderStore();
  const {prevStep, nextStep} = useAgentBuilderNavStore();

  const onContinue = () => {
    publishChanges.mutate(undefined, {onSuccess: nextStep});
  };

  return (
    <Flex direction="column" flex="1">
      <BuilderCenteredContent>
        <AgentBuilderPageIndicator />
        <Flex direction="column" flex="1" gap="4">
          <Flex direction="column" gap="1">
            <Text fontSize="xl">Choose accounts to preview</Text>
            <Text fontSize="md" color="kgray.300">
              Choose up to {MaxBulkPreviewAccounts} accounts.
            </Text>
          </Flex>
          <Flex alignItems="center" gap="2">
            <Box flex="1">
              <AccountTypeahead
                disabledTooltip="Max preview accounts reached"
                isDisabled={previewAccountIds.length >= MaxBulkPreviewAccounts}
                onSelect={addAccount}
                placeholder="Search for an account..."
                selected={previewAccountIds}
              />
            </Box>
            <AccountImportButton
              accountIds={previewAccountIds}
              addAccount={addAccount}
              maxAccounts={MaxBulkPreviewAccounts}
              borderRadius="xl"
              h="100%"
            >
              Upload CSV
            </AccountImportButton>
          </Flex>
          <AccountList
            onRemoveAccount={removeAccount}
            selectedAccountIds={previewAccountIds}
          />
        </Flex>
      </BuilderCenteredContent>

      <Divider />

      <BuilderFooter justifyContent="space-between">
        <Button variant="ghost" onClick={prevStep}>
          Back
        </Button>

        <Button
          colorScheme="kbuttonblue"
          isDisabled={!previewAccountIds.length}
          isLoading={publishChanges.isPending}
          onClick={onContinue}
        >
          Continue
        </Button>
      </BuilderFooter>
    </Flex>
  );
};
