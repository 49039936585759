import {chakra} from '@chakra-ui/react';
import {AgentType} from '../../../shared/agent';
import {SearchLocate, WordCloud, ZoomArea} from '@carbon/icons-react';

export const agentTypeInfo: Record<
  AgentType,
  {description: string; icon: React.ElementType}
> = {
  analyst: {
    description:
      'Agent assesses Keyplay account research & data fields to create structured outputs.',
    icon: chakra(SearchLocate),
  },
  researcher: {
    description:
      'AI research agent searches the web and returns a summary of findings for each account.',
    icon: chakra(WordCloud),
  },
  researcherAndAnalyst: {
    description:
      'Agent conducts online research and creates structured outputs.',
    icon: chakra(ZoomArea),
  },
};
