import React from 'react';
import {useAgentBuilderStore} from './AgentBuilder.state';
import {Button, FormControl, FormLabel, Input, Text} from '@chakra-ui/react';
import {Divider, Flex} from '@chakra-ui/react';
import {AgentType, AgentTypeLabels} from '../../../shared/agent';
import Select, {OptionProps, components} from 'react-select';
import {defaultSelectStyles} from '../../theme/select';
import {keys} from '../../../shared/util';
import {BuilderCenteredContent} from '../../Ai/BuilderCenteredContent';
import {BuilderFooter} from '../../Ai/BuilderFooter';
import {agentTypeInfo} from './agentTypeInfo';
import {useAgentBuilderNavStore} from './AgentBuilderNav.state';
import {AgentBuilderPageIndicator} from './AgentBuilderPageIndicator';

export const AgentBuilderSetNameStep = () => {
  const {label, type, publishChanges} = useAgentBuilderStore();
  const {nextStep, prevStep} = useAgentBuilderNavStore();

  const onContinue = () => {
    publishChanges.mutate(undefined, {
      onSuccess: nextStep,
    });
  };

  return (
    <Flex direction="column" flex="1">
      <BuilderCenteredContent>
        <AgentBuilderPageIndicator />
        <Flex direction="column" gap="2">
          <Text fontSize="xl">
            Say hello to your new {type ? AgentTypeLabels[type] : 'agent'}
          </Text>
          <Text color="kgray.300">
            We recommend giving your agent a descriptive name based on the job
            you want it to do.
          </Text>
        </Flex>
        <Flex direction="column" gap="6" mt="8">
          <AgentEditorSection />
        </Flex>
      </BuilderCenteredContent>

      <Divider />

      <BuilderFooter justifyContent="space-between">
        <Button onClick={prevStep} variant="ghost">
          Back
        </Button>

        <Button
          colorScheme="kbuttonblue"
          isDisabled={!label || !type}
          isLoading={publishChanges.isPending}
          onClick={onContinue}
        >
          Continue
        </Button>
      </BuilderFooter>
    </Flex>
  );
};

interface AgentTypeOption {
  label: string;
  description: string;
  value: AgentType;
}

const agentTypeOptions = keys(agentTypeInfo).map((type) => ({
  label: AgentTypeLabels[type],
  description: agentTypeInfo[type].description,
  value: type,
}));

const AgentTypeOption = (props: OptionProps<AgentTypeOption>) => {
  const {label, description} = props.data as AgentTypeOption;
  return (
    <components.Option {...props}>
      <Text>{label}</Text>
      <Text fontSize="sm" mt="2" color="kgray.300">
        {description}
      </Text>
    </components.Option>
  );
};

const agentTypeSelectStyles = defaultSelectStyles<AgentTypeOption, false>();

const AgentEditorSection = () => {
  const {label, type, setLabel, setType, serverState} = useAgentBuilderStore();
  return (
    <>
      <FormControl>
        <FormLabel fontSize="sm" fontWeight="normal" textTransform="uppercase">
          Agent name
        </FormLabel>
        <Input
          type="text"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          placeholder="e.g. Pricing Strategy"
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="sm" fontWeight="normal" textTransform="uppercase">
          Agent type
        </FormLabel>
        <Select
          components={{Option: AgentTypeOption}}
          isDisabled={!!serverState}
          isMulti={false}
          isSearchable={false}
          onChange={(newValue) => {
            if (!newValue) {
              return;
            }

            setType(newValue.value);
          }}
          options={agentTypeOptions}
          styles={agentTypeSelectStyles}
          value={agentTypeOptions.find((option) => option.value === type)}
        />
      </FormControl>
    </>
  );
};
