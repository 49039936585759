import React from 'react';
import {Icon, IconProps, forwardRef} from '@chakra-ui/react';

export const Credits = forwardRef(({...props}: IconProps, ref) => (
  <Icon
    width="16px"
    height="16px"
    ref={ref}
    version="1.1"
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
    fill={'currentColor'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0042 0.5C4.48809 0.5 0 4.97879 0 10.4958C0 16.0119 4.48809 20.5 10.0042 20.5C15.5215 20.5 20 16.0119 20 10.4958C20 4.97969 15.5212 0.5 10.0042 0.5ZM10.0042 1.74596C14.8452 1.74596 18.7541 5.65492 18.7541 10.4959C18.7541 15.3369 14.8452 19.2542 10.0042 19.2542C5.16324 19.2542 1.24587 15.3369 1.24587 10.4959C1.24587 5.65492 5.16324 1.74596 10.0042 1.74596ZM10.0043 4.56367C7.94017 4.56367 6.24746 6.24686 6.24746 8.3109C6.24746 9.47795 6.79008 10.5702 7.69743 11.2752L6.28489 15.6244C6.15009 16.0311 6.45602 16.4496 6.88502 16.446H13.1243C13.5486 16.4437 13.8487 16.0276 13.7151 15.6244L12.301 11.2748C13.2086 10.5702 13.7514 9.47696 13.7514 8.30972C13.7514 6.2456 12.0682 4.5625 10.0042 4.5625L10.0043 4.56367ZM10.969 11.2121C10.8795 10.9381 10.9909 10.6299 11.2501 10.4793H11.2513C12.026 10.0327 12.5066 9.2052 12.5066 8.31085C12.5066 6.92188 11.3943 5.80953 10.0053 5.80953C8.61633 5.80953 7.50399 6.92188 7.50399 8.31085C7.50399 9.20403 7.97518 10.0316 8.74995 10.4793C9.00925 10.63 9.12068 10.9383 9.03097 11.2125L9.03475 11.2137L7.74306 15.192H12.2558L10.9641 11.2137L10.969 11.2121Z"
    />
  </Icon>
));
