import {ObjectId} from 'bson';
import {
  GetEnrichedFieldCount,
  GetNumAccountsToEnrichDefinition,
  RunEnrichmentDefinition,
} from '../../shared/api/definitions';
import {createApiQuery, useApiMutation} from './api';

export const useNumAccountsToEnrich = () => {
  return useApiMutation(
    '/numAccountsToEnrich',
    GetNumAccountsToEnrichDefinition
  );
};

export const useRunEnrichment = () => {
  return useApiMutation('/runEnrichment', RunEnrichmentDefinition);
};

export const EnrichedFieldCountQueryKeys = {
  all: ['enrichedFieldCount'] as const,
  field: (fieldDefinitionId: ObjectId) =>
    [...EnrichedFieldCountQueryKeys.all, fieldDefinitionId.toString()] as const,
};

export const useEnrichedFieldCount = createApiQuery(
  '/enrichedFieldCount',
  GetEnrichedFieldCount,
  ({fieldDefinitionId}) => EnrichedFieldCountQueryKeys.field(fieldDefinitionId)
);
