import {AccountTierOverride, AccountTierSchema, Tag} from '../scoredAccounts';
import {ScoringSignalSchema, ScoringSignal} from '../signals';
import {StrictOmit, typeguardFromArray, WithId, zodEnum} from '../util';
import {TaskState, TaskType} from '../task';
import {
  CrmIntegration,
  IntegrationConfigSchema,
  IntegrationType,
  IntegrationTypeSchema,
} from '../integrations';
import {ApiKey, Customer} from '../customer';
import {
  ElementAttributes,
  PuppeteerScrapeMode,
  SignalData,
} from '../scraping/common';
import {
  BusinessAudienceSchema,
  PrimaryBusinessCategorySchema,
} from '../account';
import {User as Auth0User} from 'auth0';
import {ObjectId} from 'bson';
import z from 'zod';
import {zodTypeguard} from './helpers';
import {ObjectIdSchema} from '../zod';
import {KeyplayUser} from '../auth';

const SortOrderSchema = z.union([z.literal('asc'), z.literal('desc')]);
export type SortOrder = z.infer<typeof SortOrderSchema>;

const AccountSortFieldSchema = z.union([
  z.literal('overallFit'),
  z.literal('employeeCount'),
  z.literal('saveDate'),
]);
export type AccountSortField = z.infer<typeof AccountSortFieldSchema>;

const PrimaryAccountSortFieldSchema = z.union([
  z.literal('overallFit'),
  z.literal('saveDate'),
]) satisfies z.ZodUnion<
  [z.ZodLiteral<AccountSortField>, ...z.ZodLiteral<AccountSortField>[]]
>;
export type PrimaryAccountSortField = z.infer<
  typeof PrimaryAccountSortFieldSchema
>;

const NoParameterAccountActionSchema = z.object({
  name: z.union([
    z.literal('approve'),
    z.literal('disqualify'),
    z.literal('exclude'),
    z.literal('reactivate'),
  ]),
});
export type NoParameterAccountAction = z.infer<
  typeof NoParameterAccountActionSchema
>;

const TierOverrideAccountActionSchema = z.object({
  name: z.literal('override'),
  override: AccountTierOverride,
});
export type TierOverrideAccountAction = z.infer<
  typeof TierOverrideAccountActionSchema
>;

const TagAccountActionSchema = z.object({
  name: z.literal('tag'),
  tagsToAdd: z.array(ObjectIdSchema).optional(),
  tagsToRemove: z.array(ObjectIdSchema).optional(),
});
export type TagAccountAction = z.infer<typeof TagAccountActionSchema>;

const AccountActionSchema = z.union([
  NoParameterAccountActionSchema,
  TierOverrideAccountActionSchema,
  TagAccountActionSchema,
]);
export type AccountAction = z.infer<typeof AccountActionSchema>;

export type AccountActionName = AccountAction['name'];

const AccountFilterSchema = z.object({
  active: z.boolean().optional(),
  audience: z.array(BusinessAudienceSchema).optional(),
  disqualified: z.boolean().optional(),
  excluded: z.boolean().optional(),
  hasTierOverride: z.boolean().optional(),
  importSources: z.array(ObjectIdSchema).optional(),
  imported: z.boolean().optional(),
  inCRM: z.boolean().optional(),
  inSAM: z.boolean().optional(),
  similarTo: z.array(ObjectIdSchema).optional(),
  matchedSignals: z.array(z.array(ScoringSignalSchema)).optional(),
  maxEmployees: z.number().optional(),
  minEmployees: z.number().optional(),
  primaryCategory: z.array(PrimaryBusinessCategorySchema).optional(),
  saved: z.boolean().optional(),
  search: z.string().optional(),
  tags: z.array(ObjectIdSchema).optional(),
  tier: z.array(AccountTierSchema).optional(),
});
export type AccountFilter = z.infer<typeof AccountFilterSchema>;

export const AccountSortSchema = z.object({
  field: PrimaryAccountSortFieldSchema,
  order: SortOrderSchema,
});
export type AccountSort = z.infer<typeof AccountSortSchema>;

const BaseOAuthPayloadSchema = z.object({
  code: z.string(),
  integrationName: IntegrationTypeSchema,
});

const SalesforceOAuthPayloadSchema = BaseOAuthPayloadSchema.extend({
  integrationName: z.literal('salesforce'),
  isSandbox: z.boolean().optional(),
});

const HubSpotOAuthPayloadSchema = BaseOAuthPayloadSchema.extend({
  integrationName: z.literal('hubspot'),
});

export const OAuthPayloadSchema = z.union([
  SalesforceOAuthPayloadSchema,
  HubSpotOAuthPayloadSchema,
]);
export type OAuthPayload = z.infer<typeof OAuthPayloadSchema>;
export const isOAuthPayload = zodTypeguard(OAuthPayloadSchema);

// All queries need a runid and sort.
// Filters are optional
export const AccountQueryBaseSchema = z.object({
  runId: ObjectIdSchema,
  sort: AccountSortSchema,
  filter: AccountFilterSchema.optional(),
  limit: z.number().optional(),
});
export type AccountQueryBase = z.infer<typeof AccountQueryBaseSchema>;
export const isAccountQueryBase = zodTypeguard(AccountQueryBaseSchema);

// runId optional -- we lookup and use most recent run if it isn't specified
export const AccountQuerySchema = AccountQueryBaseSchema.extend({
  runId: ObjectIdSchema.optional(),
});
export type AccountQuery = z.infer<typeof AccountQuerySchema>;
export const isAccountQuery = zodTypeguard(AccountQuerySchema);

export const AccountQueryWithoutSortSchema = AccountQueryBaseSchema.omit({
  sort: true,
});
export type AccountQueryWithoutSort = z.infer<
  typeof AccountQueryWithoutSortSchema
>;
export const isAccountQueryWithoutSort = zodTypeguard(
  AccountQueryWithoutSortSchema
);

export const AccountActionPayloadSchema = z.object({
  action: AccountActionSchema,
  runId: ObjectIdSchema,
  accountIds: z.array(ObjectIdSchema),
});
export type AccountActionPayload = z.infer<typeof AccountActionPayloadSchema>;
export const isAccountActionPayload = zodTypeguard(AccountActionPayloadSchema);

export const AccountActionQueryPayloadSchema = z.object({
  action: AccountActionSchema,
  query: AccountQueryBaseSchema,
});
export type AccountActionQueryPayload = z.infer<
  typeof AccountActionQueryPayloadSchema
>;
export const isAccountActionQueryPayload = zodTypeguard(
  AccountActionQueryPayloadSchema
);

export const AccountActionQueryLimit = 5000;
export const ScoredAccountsQueryLimit = 500;

export const ExportTypes = [
  'all_accounts',
  'filtered_accounts',
  'discover',
  'crm_accounts',
  'page_source',
  'page_text',
] as const;
export const ExportTypeSchema = zodEnum(ExportTypes);
export type ExportType = z.infer<typeof ExportTypeSchema>;

export const PageScrapeExportMaxRows = 5_000;
export const ExportWithExtraFieldsMaxRows = 25_000;

// exports that support extra fields
export const ExtraFieldExportTypes = [
  'all_accounts',
  'filtered_accounts',
  'crm_accounts',
] as const;
export const isExtraFieldExportType = typeguardFromArray(ExtraFieldExportTypes);

export const AccountQueryExportTypes = [
  'filtered_accounts',
  'page_source',
  'page_text',
] as const;
export const isAccountQueryExportType = typeguardFromArray(
  AccountQueryExportTypes
);

export const ExportRequest = z.object({
  type: ExportTypeSchema,
  query: z.optional(AccountQuerySchema),
  includeSpecialFields: z.boolean().optional(),
  includeRestrictedFields: z.boolean().optional(),
  // This is likely just a temporary field so that we can easily test sync vs async behavior on the front-end.
  mode: z.union([z.literal('sync'), z.literal('async')]),
});
export type ExportRequest = z.infer<typeof ExportRequest>;
export const isExportRequest = zodTypeguard(ExportRequest);

export interface ExportResponse {
  exportUrl?: string;
}

export const AccountSelectionQuerySchema = z.object({
  name: z.literal('query'),
  query: AccountQueryWithoutSortSchema,
});
export type AccountSelectionQuery = z.infer<typeof AccountSelectionQuerySchema>;

export const AccountSelectionListSchema = z.object({
  name: z.literal('list'),
  ids: ObjectIdSchema.array(),
  runId: ObjectIdSchema,
});
export type AccountSelectionList = z.infer<typeof AccountSelectionListSchema>;

// Represents a set of scored accounts that are either fetched by issuing a query or as a hardcoded list of IDs.
export const AccountSelectionSchema = z.union([
  AccountSelectionQuerySchema,
  AccountSelectionListSchema,
]);
export type AccountSelection = z.infer<typeof AccountSelectionSchema>;

export const DownloadRequestSchema = z.object({
  exportId: z.string(),
});
export type DownloadRequest = z.infer<typeof DownloadRequestSchema>;

export interface DownloadResponse {
  url: string;
}

export const AccountLinksRequestSchema = z.object({
  accountId: ObjectIdSchema,
});
export type AccountLinksRequest = z.infer<typeof AccountLinksRequestSchema>;

export const AccountLinkSchema = z.object({
  label: z.string(),
  url: z.string(),
});
export const isAccountLink = zodTypeguard(AccountLinkSchema);
export type AccountLink = z.infer<typeof AccountLinkSchema>;

export type AccountLinksResponse = AccountLink[];

export const UploadUrlRequestSchema = z.object({
  contentSize: z.number(),
});

export interface UploadURLResponse {
  url: string;
  cloudStorageBucket: string;
  cloudStorageFilePath: string;
}

export const FileCrmImportRequestSchema = z.object({
  cloudStorageBucket: z.string(),
  cloudStorageFilePath: z.string(),
});

export const RecommendedAccountsImportRequestSchema = z.object({
  cloudStorageBucket: z.string(),
  cloudStorageFilePath: z.string(),
  source: z.string(),
});

export interface FileCRMImportResponse {
  taskId?: string;
}

export const TaskStatusRequest = z.object({
  type: z.union([
    z.literal('fileCRMImport'),
    z.literal('hubSpotEnrich'),
    z.literal('hubSpotFullRun'),
    z.literal('salesforceFullRun'),
  ]) satisfies z.ZodUnion<
    [z.ZodLiteral<TaskType>, ...z.ZodLiteral<TaskType>[]]
  >,
});

export type TaskStatusRequest = z.infer<typeof TaskStatusRequest>;

export interface TaskStatusResponse {
  task?: {
    id: ObjectId;
    state: TaskState;
    progress: number;
  };
}

export type ApiKeyResponse = ApiKey[];

export type SignalCountsResponse = Partial<Record<ScoringSignal, number>>;

export type ImportSourceCountsResponse = {
  [id: string]: number;
};

export type TagResponse = WithId<StrictOmit<Tag, 'customerId'>>;

// TODO: should be using the same type as getMergedData
export type DebugAccountResponse = {
  signals: {
    coreSignals: Record<string, SignalData>;
    hiringSignals: Record<string, SignalData>;
    hiringSignalsUrl?: string;
    locations: {
      [location: string]: {
        finalUrl: string;
        scrapeMode?: PuppeteerScrapeMode;
        elements?: ElementAttributes[];
      };
    };
  } | null;
};

export interface MetadataResponse {
  customer: WithId<
    Pick<
      Customer,
      | 'agents'
      | 'credits'
      | 'fieldDefinitions'
      | 'features'
      | 'limits'
      | 'markets'
      | 'name'
      | 'onboarded'
      | 'plan'
      | 'signalDefinitions'
      | 'signals'
      | 'toggles'
    >
  >;
  defaultMarketId: ObjectId;
  tags: {[tagId in string]?: TagResponse};
  imports: {[importId in string]?: string};
}

export interface UserResponse {
  intercomHash: string;
}

export interface GetCustomerResponse {
  customer: WithId<Customer>;
  // we want the raw Auth0 user here so we have access to special metadata like login count
  users: Auth0User[];
}

export const UserStateLabels = {
  active: 'Active',
  invited: 'Invited',
} as const;
export type UserState = keyof typeof UserStateLabels;

export interface GetUsersResponse {
  users: KeyplayUser[];
}

const InviteUserRequestSchema = z.object({
  email: z.string().email(),
});
export const isInviteUserRequest = zodTypeguard(InviteUserRequestSchema);
export type InviteUserRequest = z.infer<typeof InviteUserRequestSchema>;

export const MinSamDefinitionSize = 2_500;
export interface SamDefinitionSizeResponse {
  withinLimits: boolean;
}

export const AccountsInFreeExport = 25;

export type IntegrationResponse = Pick<
  CrmIntegration,
  | 'id'
  | 'config'
  | 'crmSyncDay'
  | 'lastEnrichment'
  | 'status'
  | 'timestamp'
  | 'type'
>;

export type IntegrationsResponse = Partial<
  Record<IntegrationType, IntegrationResponse>
>;

const UpdateIntegrationRequestSchema = IntegrationConfigSchema.partial();
export type UpdateIntegrationRequest = z.infer<
  typeof UpdateIntegrationRequestSchema
>;
export const isUpdateIntegrationRequest = zodTypeguard(
  UpdateIntegrationRequestSchema
);
