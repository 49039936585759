import React, {useEffect, useState} from 'react';
import {HeaderButton, HeaderButtonProps} from './HeaderButton';
import {DataEnrichment} from '@carbon/icons-react';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {useCustomer} from '../../../hooks/api/metadata';
import Select from 'react-select';
import {FieldDefinition} from '../../../shared/enrichment';
import {
  useNumAccountsToEnrich,
  useRunEnrichment,
} from '../../../hooks/api/enrichment';
import {useAccountQueryParams} from '../AccountGrid.controlstate';
import {useScoringRun} from '../../../context/ScoringRunContext';
import {pluralize} from '../../../lib/helpers';

export const EnrichButton = ({...props}: HeaderButtonProps) => {
  const {isOpen, onClose, onOpen} = useDisclosure();

  return (
    <>
      <HeaderButton onClick={onOpen} leftIcon={<DataEnrichment />} {...props}>
        Enrich
      </HeaderButton>
      <EnrichAccountsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const EnrichAccountsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enrich Accounts</ModalHeader>
        <ModalCloseButton />
        <EnrichAccountsModalContent onClose={onClose} />
      </ModalContent>
    </Modal>
  );
};

const EnrichAccountsModalContent = ({onClose}: {onClose: () => void}) => {
  const customer = useCustomer();
  const options =
    customer.fieldDefinitions
      ?.filter(({type}) => type === 'ai' || type === 'roleCount')
      ?.filter(({status}) => status === 'published')
      ?.map((field) => ({
        value: field.id,
        label: field.label,
        field,
      })) ?? [];

  const [selectedField, setSelectedField] = useState<FieldDefinition | null>(
    null
  );
  const [overrideFieldValues, setOverrideFieldValues] = useState(false);

  const scoringRun = useScoringRun();
  const queryParams = useAccountQueryParams();
  const numAccountsToEnrich = useNumAccountsToEnrich();
  const fieldDefinitionId = selectedField?.id;
  const runEnrichment = useRunEnrichment();
  const toast = useToast();

  const {mutate: numAccountsToEnrichMutation} = numAccountsToEnrich;
  useEffect(() => {
    if (fieldDefinitionId) {
      numAccountsToEnrichMutation({
        accountSelection: {
          name: 'query',
          query: {
            ...queryParams,
            runId: scoringRun._id,
          },
        },
        fieldDefinitionId,
        overrideFieldValues,
      });
    }
  }, [
    fieldDefinitionId,
    overrideFieldValues,
    scoringRun,
    queryParams,
    numAccountsToEnrichMutation,
  ]);

  const {numAccounts, creditsNeeded} = numAccountsToEnrich.data ?? {};
  const insufficientCredits = creditsNeeded && creditsNeeded > customer.credits;

  const onEnrich = () => {
    if (!fieldDefinitionId) {
      return;
    }

    runEnrichment.mutate(
      {
        accountSelection: {
          name: 'query',
          query: {
            ...queryParams,
            runId: scoringRun._id,
          },
        },
        fieldDefinitionId,
        overrideFieldValues,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Enrichment task started',
            description: 'We will send an email when it has finished.',
            status: 'success',
          });
          onClose();
        },
      }
    );
  };

  return (
    <>
      <ModalBody as={Flex} flexDir="column" gap="8" mb="4">
        <FormControl>
          <FormLabel>Custom Field</FormLabel>
          <Select
            isMulti={false}
            onChange={(selected) => {
              if (!selected) {
                return;
              }

              setSelectedField(selected.field);
            }}
            options={options}
            placeholder="Select a field"
            value={options.find(
              (option) =>
                option.value.toString() === selectedField?.id.toString()
            )}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            isChecked={overrideFieldValues}
            onChange={(e) => setOverrideFieldValues(e.target.checked)}
          >
            Re-run enrichment for all accounts in view?
          </Checkbox>
          <FormHelperText>
            By default, only accounts with no existing value will be enriched.
          </FormHelperText>
        </FormControl>
      </ModalBody>
      <Divider />
      <ModalFooter as={Flex} flexDir="column" gap="2" h="auto" py="4">
        <Flex justifyContent="space-between" w="100%">
          <Text>Required credits:</Text>
          <Text>{creditsNeeded?.toLocaleString() ?? '-'}</Text>
        </Flex>
        <Flex justifyContent="space-between" w="100%">
          <Text>Remaining credits:</Text>
          <Text>{customer.credits.toLocaleString()}</Text>
        </Flex>

        <Tooltip label="Insufficient credits" isDisabled={!insufficientCredits}>
          <Button
            colorScheme="kbuttonblue"
            fontWeight="normal"
            size="md"
            width="100%"
            isLoading={numAccountsToEnrich.isPending || runEnrichment.isPending}
            isDisabled={!numAccounts || insufficientCredits || !scoringRun}
            onClick={onEnrich}
            alignSelf="flex-end"
            mt="2"
          >
            {numAccounts !== undefined
              ? `Enrich ${pluralize(numAccounts, 'account', 'accounts')}`
              : 'Enrich Accounts'}
          </Button>
        </Tooltip>
      </ModalFooter>
    </>
  );
};
