import {ObjectId} from 'bson';
import {GetEnrichmentPreviewResultDefinition} from '../../shared/api/definitions';
import {createApiQuery} from './api';

export const EnrichmentPreviewResultQueryKeys = {
  all: ['enrichmentPreviewResult'],
  field: ({fieldDefinitionId}: {fieldDefinitionId: ObjectId}) => [
    ...EnrichmentPreviewResultQueryKeys.all,
    fieldDefinitionId.toString(),
  ],
  account: ({
    fieldDefinitionId,
    accountId,
  }: {
    fieldDefinitionId: ObjectId;
    accountId: ObjectId;
  }) => [
    ...EnrichmentPreviewResultQueryKeys.field({fieldDefinitionId}),
    accountId.toString(),
  ],
} as const;

export const useGetEnrichmentPreviewResult = createApiQuery(
  '/enrichmentPreviewResult',
  GetEnrichmentPreviewResultDefinition,
  ({fieldDefinitionId, accountId}) =>
    EnrichmentPreviewResultQueryKeys.account({
      fieldDefinitionId,
      accountId,
    }),
  {
    retryDelay: 1_000,
    staleTime: Infinity,
  }
);
