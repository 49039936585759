import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Code,
  Flex,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {ObjectId} from 'bson';
import {useBulkFindAccounts} from '../hooks/useBulkFindAccounts';

export const AccountImportButton = ({
  accountIds,
  addAccount,
  maxAccounts,
  children,
  ...rest
}: React.PropsWithChildren<
  ButtonProps & {
    accountIds: ObjectId[];
    addAccount: (accountId: ObjectId) => void;
    maxAccounts: number;
  }
>) => {
  const toast = useToast();
  const remainingAccounts = maxAccounts - accountIds.length;

  const {isPending, open} = useBulkFindAccounts({
    maxRows: remainingAccounts,
    onError: () => {
      toast({
        title: 'Invalid account list',
        description: `Please ensure your CSV file has a Domain column and is less than ${remainingAccounts} rows.`,
        status: 'error',
      });
    },
    onSuccess: (accountIds) => {
      accountIds.forEach(addAccount);
    },
    type: 'preview',
  });

  return (
    <Tooltip
      hasArrow
      isDisabled={isPending}
      label={
        <Flex direction="column" gap="4" my="2">
          <Text>The CSV should include a single column of domain names.</Text>
          <Code>
            <Box>Domain</Box>
            <Box>example1.com</Box>
            <Box>example2.com</Box>
          </Code>
        </Flex>
      }
      placement="top"
    >
      <Button
        colorScheme="kbuttonblue"
        fontSize="sm"
        fontWeight="normal"
        isDisabled={isPending || remainingAccounts <= 0}
        onClick={open}
        variant="outline"
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
