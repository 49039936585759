import React from 'react';
import {PageIndicator} from '../../PageIndicator';
import {Flex} from '@chakra-ui/react';
import {useAgentBuilderNavStore} from './AgentBuilderNav.state';
import {ContentHeaderSpacePx} from '../AiFieldBuilder/helpers';

export const AgentBuilderPageIndicator = () => {
  const {step, steps} = useAgentBuilderNavStore();

  return (
    <Flex alignItems="center" h={ContentHeaderSpacePx}>
      <PageIndicator page={steps.indexOf(step)} totalPages={steps.length} />
    </Flex>
  );
};
