import React, {useMemo} from 'react';
import {Box, Button, Divider, Flex, Radio, Text} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {WrapperFlex, ScrollableFlex} from '../../utils/scrolling';
import {FieldBuilderPageIndicator} from './FieldBuilderPageIndicator';
import Select from 'react-select';
import {useTags} from '../../../hooks/api/tags';
import _ from 'lodash';
import {
  isAiEnrichmentAccountFilter,
  isMyAccountsFilter,
  isTaggedAccountsFilter,
  TaggedAccountsFilterSchema,
} from '../../../shared/enrichment';
import {z} from 'zod';
import {zodTypeguard} from '../../../shared/api/helpers';

export const AiFieldBuilderAccountFilterStep = () => {
  const {publishChanges, accountFilter} = useAiFieldBuilderStore();
  const {prevStep, nextStep} = useAiFieldBuilderNavStore();

  return (
    <Flex direction="column" flex="1">
      <Box position="relative" h="100%">
        <WrapperFlex>
          <ScrollableFlex alignItems="center" px="6">
            <Flex direction="column" maxW="800px" w="100%" flex="1">
              <FieldBuilderPageIndicator />
              <Flex direction="column" gap="4">
                <Text flexGrow="1" fontSize="xl">
                  Selected Accounts to Run
                </Text>
                <AccountFilters />
              </Flex>
            </Flex>
          </ScrollableFlex>
        </WrapperFlex>
      </Box>

      <Divider />

      <Flex justifyContent="space-between" w="100%" px={10} py={4}>
        <Button variant="ghost" onClick={prevStep}>
          Back
        </Button>

        <Button
          colorScheme="kbuttonblue"
          isDisabled={!isAiEnrichmentAccountFilter(accountFilter)}
          isLoading={publishChanges.isPending}
          onClick={() => publishChanges.mutate('save', {onSuccess: nextStep})}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};

const FilterOptionContainer = ({
  children,
  isSelected,
  title,
  onClick,
}: React.PropsWithChildren<{
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
}>) => {
  return (
    <Flex
      direction="column"
      gap="4"
      px="6"
      py="4"
      bgColor={isSelected ? 'kblue.50' : 'white'}
      border="1px solid"
      borderColor={isSelected ? 'kblue.500' : 'kgray.200'}
      borderRadius="lg"
      cursor="pointer"
      onClick={onClick}
      _hover={{
        bgColor: !isSelected && 'kgray.100',
      }}
    >
      <Flex alignItems="center" gap="4">
        <Radio isChecked={isSelected} />
        <Text>{title}</Text>
      </Flex>
      {children}
    </Flex>
  );
};

const AllAccountsFilterOption = () => {
  const {accountFilter, setAccountFilter} = useAiFieldBuilderStore();
  return (
    <FilterOptionContainer
      title="All accounts in My Accounts"
      isSelected={isMyAccountsFilter(accountFilter)}
      onClick={() =>
        setAccountFilter({
          active: true,
          excluded: false,
        })
      }
    />
  );
};

const isEmptyTaggedAccountsFilter = zodTypeguard(
  TaggedAccountsFilterSchema.extend({
    tags: z.string().array().length(0),
  })
);

const AccountTagFilterOption = () => {
  const {accountFilter, setAccountFilter} = useAiFieldBuilderStore();
  const isSelected =
    isTaggedAccountsFilter(accountFilter) ||
    isEmptyTaggedAccountsFilter(accountFilter);
  const tags = useTags();

  const options = useMemo(
    () =>
      tags.data?.map((tag) => ({
        label: tag.name,
        value: tag._id,
      })) ?? [],
    [tags.data]
  );

  return (
    <FilterOptionContainer
      title="Only accounts with a specific tag"
      isSelected={isSelected}
      onClick={() => {
        // Ensure we don't remove a selected tag
        if (!isTaggedAccountsFilter(accountFilter)) {
          setAccountFilter({
            active: true,
            excluded: false,
            tags: [],
          });
        }
      }}
    >
      {isSelected && (
        <Box onClick={(e) => e.stopPropagation()}>
          <Select
            isLoading={tags.isLoading}
            isMulti={false}
            options={options}
            value={options.find((option) =>
              _.isEqual(option.value, accountFilter.tags[0])
            )}
            onChange={(option) => {
              if (!option) {
                return;
              }

              setAccountFilter({
                active: true,
                excluded: false,
                tags: [option.value],
              });
            }}
            placeholder="Select or search..."
          />
        </Box>
      )}
    </FilterOptionContainer>
  );
};

const AccountFilters = () => {
  return (
    <Flex direction="column" gap="4">
      <AllAccountsFilterOption />
      <AccountTagFilterOption />
    </Flex>
  );
};
