import {create} from 'zustand';
import {Agent, AgentType} from '../../../shared/agent';
import {useSaveAgent} from '../../../hooks/api/agents';
import {useMutation} from '@tanstack/react-query';
import {ObjectId} from 'bson';
import _ from 'lodash';
import {assertExists} from '../../../shared/util';

interface AgentBuilderState {
  updateServerState: ({
    agent,
    updateMode,
  }: {
    agent: Agent;
    updateMode: 'always' | 'ifNoChanges';
  }) => void;
  serverState: Agent | undefined;

  hasChanges: () => boolean;

  label: string;
  setLabel: (label: string) => void;

  type: AgentType | undefined;
  setType: (type: AgentType) => void;

  previewAccountIds: ObjectId[];
  addAccount: (accountId: ObjectId) => void;
  removeAccount: (accountId: ObjectId) => void;

  reset: () => void;
}

const useAgentBuilderState = create<AgentBuilderState>()((set, get) => ({
  updateServerState: ({agent, updateMode}) => {
    if (updateMode === 'ifNoChanges' && get().hasChanges()) {
      // Don't update the server state if there are local changes
      return;
    }

    set({
      serverState: agent,
      label: agent.label,
      type: agent.type,
      previewAccountIds: agent.previewAccountIds,
    });
  },
  serverState: undefined,

  hasChanges: () => {
    const {serverState, label, type, previewAccountIds} = get();
    if (!serverState) {
      return !!label || !!type || !!previewAccountIds.length;
    }

    return (
      serverState.label !== label ||
      serverState.type !== type ||
      !_.isEqual(serverState.previewAccountIds, previewAccountIds)
    );
  },

  label: '',
  setLabel: (label) => set({label}),

  type: undefined,
  setType: (type) => set({type}),

  previewAccountIds: [],
  addAccount: (accountId) => {
    const {previewAccountIds} = get();
    if (previewAccountIds.some((id) => id.equals(accountId))) {
      return;
    }

    set({previewAccountIds: [...previewAccountIds, accountId]});
  },
  removeAccount: (accountId) =>
    set({
      previewAccountIds: get().previewAccountIds.filter(
        (id) => !id.equals(accountId)
      ),
    }),

  reset: () =>
    set({
      serverState: undefined,
      label: '',
      type: undefined,
      previewAccountIds: [],
    }),
}));

export const useAgentBuilderStore = () => {
  const store = useAgentBuilderState();

  const saveAgent = useSaveAgent();
  const publishChanges = useMutation({
    mutationFn: async () => {
      if (!store.hasChanges()) {
        return;
      }

      const {label, type, previewAccountIds, serverState} = store;
      return saveAgent.mutateAsync(
        {
          agent: {
            id: serverState?.id,
            label,
            type: type!,
            previewAccountIds,
            status: serverState?.status ?? 'draft',
          },
        },
        {
          onSuccess: (agent) => {
            store.updateServerState({agent, updateMode: 'always'});
          },
        }
      );
    },
  });

  return {
    ...store,
    publishChanges,
  };
};

export const useRequiredAgentBuilderServerState = () => {
  const {serverState} = useAgentBuilderStore();
  return assertExists(serverState, 'Agent builder server state is required');
};
