import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Badge,
  Heading,
} from '@chakra-ui/react';
import {createApiQuery} from '../../hooks/api/api';
import {GetResearchPrompt} from '../../shared/api/definitions';
import {create} from 'zustand';
import {SingleAccountTypeahead} from '../SingleAccountTypeahead';
import {AccountWithLogo} from '../../shared/scoredAccounts';
import {ObjectId} from 'bson';

const useResearchPrompt = createApiQuery(
  '/debug/researchPrompt',
  GetResearchPrompt,
  (params) => ['researchPrompt', params]
);

interface ResearchPromptState {
  // Persisted form state
  selectedAccount: AccountWithLogo | undefined;
  plan: string;

  // Actions
  setSelectedAccount: (account: AccountWithLogo | undefined) => void;
  setPlan: (plan: string) => void;
  reset: () => void;
}

const DummyId = new ObjectId();

export const useResearchPromptStore = create<ResearchPromptState>()((set) => ({
  // Default state
  selectedAccount: undefined,
  plan: '',

  // Actions
  setSelectedAccount: (selectedAccount) => set({selectedAccount}),
  setPlan: (plan) => set({plan}),
  reset: () => set({selectedAccount: undefined, plan: ''}),
}));

export const GenerateResearchPrompt = () => {
  // Get state from Zustand store
  const {selectedAccount, setSelectedAccount, plan, setPlan} =
    useResearchPromptStore();

  // Local state for query control
  const [isEnabled, setIsEnabled] = useState(false);

  const {data, isLoading} = useResearchPrompt(
    {
      // HACK: ok because this won't be enabled if there isn't an object id
      accountId: selectedAccount?._id ?? DummyId,
      mode: 'structured', // TODO: should be configurable
      plan,
    },
    {
      enabled: isEnabled && !!selectedAccount,
    }
  );

  // Reset enabled flag after request completes
  useEffect(() => {
    if (isLoading || !isEnabled) {
      return;
    }
    setIsEnabled(false);
  }, [isLoading, isEnabled]);

  return (
    <Flex height="100vh" width="100%">
      {/* Left Pane - Controls */}
      <Box
        p={6}
        width="50%"
        borderRight="1px"
        borderColor="gray.200"
        overflowY="auto"
      >
        <VStack spacing={6} align="stretch">
          <Heading size="lg" mb={4}>
            Generate Research Prompt
          </Heading>

          <FormControl isDisabled={isLoading}>
            <FormLabel>Account</FormLabel>
            <SingleAccountTypeahead
              defaultAccount={selectedAccount}
              onAccountSelect={setSelectedAccount}
              placeholder="Select an account..."
            />
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <FormLabel>Research Plan</FormLabel>
            <Textarea
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
              placeholder="Enter research plan..."
              rows={8}
              isDisabled={isLoading}
            />
          </FormControl>

          <Button
            colorScheme="kbuttonblue"
            onClick={() => setIsEnabled(true)}
            isLoading={isLoading}
            isDisabled={isLoading || !selectedAccount || !plan.trim()}
            mb={4}
          >
            Generate Prompt
          </Button>
        </VStack>
      </Box>

      {/* Right Pane - Results */}
      <Box p={6} width="50%" bg="gray.50" overflowY="auto">
        <Heading size="lg" mb={4}>
          Results
          {isLoading && (
            <Badge colorScheme="blue" ml={2} fontSize="md">
              Loading...
            </Badge>
          )}
        </Heading>

        {data && (
          <Box
            bg="white"
            p={4}
            borderRadius="md"
            whiteSpace="pre-wrap"
            fontFamily="monospace"
            border="1px"
            borderColor="gray.200"
          >
            {data.prompt}
          </Box>
        )}
      </Box>
    </Flex>
  );
};
