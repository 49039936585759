import {
  OnChangeFn,
  RowSelectionState,
  VisibilityState,
} from '@tanstack/react-table';
import produce from 'immer';
import _ from 'lodash';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {AccountQuery, AccountSort} from '../../shared/api/api';
import {AccountView, getFiltersForView} from '../../shared/api/helpers';
import {AccountTier} from '../../shared/scoredAccounts';
import {ScoringSignal} from '../../shared/signals';
import {BusinessAudience, PrimaryBusinessCategory} from '../../shared/account';
import {track} from '../../analytics';
import {ObjectId} from 'bson';
import {useAccountView} from './AccountGrid';
import {useMemo} from 'react';

export function useIsMyAccountsView() {
  const view = useAccountView();
  return isMyAccountsView(view);
}

function isMyAccountsView(view: AccountView) {
  return view !== 'recommended';
}

type TopLevelStoreState = {
  [view in AccountView]: ControlsState;
};

export interface ControlsState {
  gridView: AccountView;

  // Server-side - changes the data that we fetch from the server
  sort: AccountSort;
  setSort: (sort: AccountSort) => void;

  // Filters: If you add anything here you MUST update resetFilter and
  // its adjacent functions (wish I could enforce that with types)
  tiers: Record<AccountTier, boolean>;
  setTiers: (tier: AccountTier, selected: boolean) => void;
  signals: ScoringSignal[][];
  setSignals: (signals: ScoringSignal[][]) => void;
  importSources: ObjectId[];
  setImportSources: (importSources: ObjectId[]) => void;
  tags: ObjectId[];
  setTags: (tags: ObjectId[]) => void;
  similarTo: ObjectId[];
  setSimilarTo: (similarTo: ObjectId[]) => void;
  inSam: boolean | undefined;
  setInSam: (inSam: boolean | undefined) => void;
  inCrm: boolean | undefined;
  setInCrm: (inCrm: boolean | undefined) => void;
  hasTierOverride: boolean | undefined;
  setHasTierOverride: (hasTierOverride: boolean | undefined) => void;
  minEmployees: number | undefined;
  setMinEmployees: (minEmployees: number | undefined) => void;
  maxEmployees: number | undefined;
  setMaxEmployees: (maxEmployees: number | undefined) => void;
  disqualified: boolean | undefined;
  setDisqualified: (disqualified: boolean | undefined) => void;
  excluded: boolean | undefined;
  setExcluded: (excluded: boolean | undefined) => void;
  audience: BusinessAudience[];
  setAudience: (audience: BusinessAudience[]) => void;
  primaryCategory: PrimaryBusinessCategory[];
  setPrimaryCategory: (primaryCategory: PrimaryBusinessCategory[]) => void;

  numberOfActiveFiltersToDisplay: () => number;
  filtersDifferFromDefault: () => boolean;
  resetFilters: () => void;

  // Search. If there's a search we ignore filters and sorting (except for excluded or not)
  search: string;
  setSearch: (searchText: string) => void;

  // Client-side controls - e.g. selecting rows, showing columns
  columnVisibility: VisibilityState;
  setColumnVisibility: OnChangeFn<VisibilityState>;

  // row selection - we reset this whenever we make changes to filters/sorts
  rowSelection: RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;

  numBulkSelected: number;
  setNumBulkSelected: (selected: number) => void;

  getNumberOfSelectedAccounts: () => number;
  resetRowSelection: () => void;
}

// Performance note: we only trigger re-renders if any user control value changes,
// which should be fine...it's not going to affect, say, scrolling performance
export const useTopLevelStoreState = create<TopLevelStoreState>()(
  devtools<TopLevelStoreState>(
    (set, get) => {
      const initialStateCreator = (gridView: AccountView): ControlsState => ({
        gridView,

        sort:
          gridView === 'saved'
            ? {
                field: 'saveDate',
                order: 'desc',
              }
            : {
                field: 'overallFit',
                order: 'desc',
              },
        setSort(sort) {
          set(
            produce<TopLevelStoreState>((state) => {
              track('accountsSorted', {
                properties: {
                  sort,
                },
              });
              state[gridView].sort = sort;
              _resetRowSelection(state, gridView);
            })
          );
        },
        tiers: {
          A: true,
          B: true,
          C: true,
          D: true,
        },
        setTiers(tier: AccountTier, selected: boolean) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].tiers[tier] = selected;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Tiers',
                },
              });
            })
          );
        },
        signals: [[]],
        setSignals(signals: ScoringSignal[][]) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].signals = signals;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Signals',
                },
              });
            })
          );
        },
        importSources: [],
        setImportSources(importSources: ObjectId[]) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].importSources = importSources;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Import Sources',
                },
              });
            })
          );
        },
        tags: [],
        setTags(tags: ObjectId[]) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].tags = tags;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Tags',
                },
              });
            })
          );
        },
        similarTo: [],
        setSimilarTo(similarTo: ObjectId[]) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].similarTo = similarTo;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Similar To',
                },
              });
            })
          );
        },
        inSam: undefined,
        setInSam(inSam: boolean | undefined) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].inSam = inSam;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'SAM',
                },
              });
            })
          );
        },
        inCrm: undefined,
        setInCrm(inCrm: boolean | undefined) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].inCrm = inCrm;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'CRM',
                },
              });
            })
          );
        },
        disqualified: !isMyAccountsView(gridView) ? false : undefined,
        setDisqualified(disqualified: boolean | undefined) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].disqualified = disqualified;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Disqualified',
                },
              });
            })
          );
        },
        excluded: isMyAccountsView(gridView) ? false : undefined,
        setExcluded(excluded: boolean | undefined) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].excluded = excluded;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Excluded',
                },
              });
            })
          );
        },
        hasTierOverride: undefined,
        setHasTierOverride(hasTierOverride: boolean | undefined) {
          if (hasTierOverride === false) {
            throw new Error(
              `we don't have a UI state for false right now (which would be 
              'show everything that does not have an override')`
            );
          }
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].hasTierOverride = hasTierOverride;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Has Tier Override',
                },
              });
            })
          );
        },
        minEmployees: undefined,
        setMinEmployees(minEmployees) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].minEmployees = minEmployees;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Min Employees',
                },
              });
            })
          );
        },
        maxEmployees: undefined,
        setMaxEmployees(maxEmployees) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].maxEmployees = maxEmployees;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Max Employees',
                },
              });
            })
          );
        },
        audience: [],
        setAudience(audience) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].audience = audience;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Audience',
                },
              });
            })
          );
        },
        primaryCategory: [],
        setPrimaryCategory(primaryCategory) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].primaryCategory = primaryCategory;
              _resetRowSelection(state, gridView);
              track('accountsFiltered', {
                properties: {
                  filter: 'Primary Category',
                },
              });
            })
          );
        },

        // search
        search: '',
        setSearch(searchText: string) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].search = searchText;
              _resetRowSelection(state, gridView);
            })
          );
        },

        // UI function that determines how many filters should we show to the user as active.
        // This might have customizations for better UX, e.g. is it 1 per selected signal or 1
        // if we have any selected; don't add 1 for active/excluded)
        // TODO: I just picked something arbitrarily. Let's launch
        // and decide whether to change.
        numberOfActiveFiltersToDisplay() {
          const state = get()[gridView];
          // We go filter by filter and add up how many active filters.
          const filterCounts: number[] = [
            // 1 if we changed this at all
            !_.isEqual(state.tiers, {
              A: true,
              B: true,
              C: true,
              D: true,
            })
              ? 1
              : 0,
            // was slightly tempted to make this +1 per signal
            state.signals.some((s) => s.length) ? 1 : 0,
            !_.isEmpty(state.importSources) ? 1 : 0,
            !_.isEmpty(state.tags) ? 1 : 0,
            // +1 for any of these that are changed.
            // ignore active/excluded
            state.inSam !== undefined ? 1 : 0,
            state.inCrm !== undefined ? 1 : 0,
            state.hasTierOverride !== undefined ? 1 : 0,
            state.minEmployees !== undefined || state.maxEmployees !== undefined
              ? 1
              : 0,
          ];
          return _.sum(filterCounts);
        },

        // this is stricter than the above.
        filtersDifferFromDefault() {
          const state = get()[gridView];

          const isDefault =
            _.isEqual(state.tiers, {
              A: true,
              B: true,
              C: true,
              D: true,
            }) &&
            _.isEmpty(state.signals.flat()) &&
            _.isEmpty(state.importSources) &&
            _.isEmpty(state.tags) &&
            _.isEmpty(state.similarTo) &&
            _.isEmpty(state.audience) &&
            _.isEmpty(state.primaryCategory) &&
            state.inSam === undefined &&
            state.inCrm === undefined &&
            state.hasTierOverride === undefined &&
            state.minEmployees === undefined &&
            state.maxEmployees === undefined &&
            (isMyAccountsView(state.gridView)
              ? !state.excluded
              : !state.disqualified);

          return !isDefault;
        },

        resetFilters() {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView] = {
                ...state[gridView],
                tiers: {
                  A: true,
                  B: true,
                  C: true,
                  D: true,
                },
                signals: [[]],
                importSources: [],
                tags: [],
                similarTo: [],
                audience: [],
                primaryCategory: [],
                inSam: undefined,
                inCrm: undefined,
                hasTierOverride: undefined,
                minEmployees: undefined,
                maxEmployees: undefined,
                search: '',
                excluded: isMyAccountsView(gridView) ? false : undefined,
                disqualified: !isMyAccountsView(gridView) ? false : undefined,
              };
            })
          );
        },

        // client-side controls

        columnVisibility: {
          locations: false,
          industry: false,
          tagline: false,
        },
        setColumnVisibility(newColumnVisibility) {
          set(
            produce<TopLevelStoreState>((state) => {
              if (typeof newColumnVisibility === 'function') {
                state[gridView].columnVisibility = newColumnVisibility(
                  state[gridView].columnVisibility
                );
              } else {
                state[gridView].columnVisibility = newColumnVisibility;
              }

              track('accountsViewColumnsUpdated', {
                properties: {
                  columns: state[gridView].columnVisibility,
                },
              });
            })
          );
        },
        rowSelection: {},
        setRowSelection(newRowSelection) {
          set(
            produce<TopLevelStoreState>((state) => {
              if (typeof newRowSelection === 'function') {
                state[gridView].rowSelection = newRowSelection(
                  state[gridView].rowSelection
                );
              } else {
                state[gridView].rowSelection = newRowSelection;
              }
            })
          );
        },
        numBulkSelected: 0,
        setNumBulkSelected(selected) {
          set(
            produce<TopLevelStoreState>((state) => {
              state[gridView].numBulkSelected = selected;
              if (!selected) {
                state[gridView].rowSelection = {};
              }
            })
          );
        },
        resetRowSelection() {
          set(
            produce<TopLevelStoreState>((state) => {
              _resetRowSelection(state, gridView);
            })
          );
        },
        getNumberOfSelectedAccounts() {
          const state = get()[gridView];
          return state.numBulkSelected
            ? state.numBulkSelected
            : // Count the number of selected accounts
              Object.values(state.rowSelection).filter(_.identity).length;
        },
      });

      return {
        all: initialStateCreator('all'),
        recommended: initialStateCreator('recommended'),
        imported: initialStateCreator('imported'),
        saved: initialStateCreator('saved'),
        crm: initialStateCreator('crm'),
      };
    },
    {
      enabled: import.meta.env.DEV,
    }
  )
);

const _resetRowSelection = (
  state: TopLevelStoreState,
  gridView: AccountView
) => {
  state[gridView].rowSelection = {};
  state[gridView].numBulkSelected = 0;
};

// creates the query parameters to send to the server

export const useAccountQueryParams = (runId?: ObjectId): AccountQuery => {
  const state = useUserControls();

  const queryParams = useMemo(
    () => ({
      sort: state.sort,
      filter: {
        ...getFiltersForView(state.gridView),
        // only need to send this when tiers are less than 4
        ...(!_.every(state.tiers)
          ? {
              tier: Object.keys(_.pickBy(state.tiers)) as AccountTier[],
            }
          : {}),
        ...(state.gridView !== 'crm' ? {inCRM: state.inCrm} : {}),
        importSources: state.importSources,
        matchedSignals: state.signals.filter((signals) => signals.length),
        tags: state.tags,
        similarTo: state.similarTo,
        inSAM: state.inSam,
        excluded: state.excluded,
        disqualified: state.disqualified,
        hasTierOverride: state.hasTierOverride,
        audience: state.audience,
        primaryCategory: state.primaryCategory,
        search: state.search,
        ...(state.minEmployees !== undefined
          ? {minEmployees: state.minEmployees}
          : {}),
        ...(state.maxEmployees !== undefined
          ? {maxEmployees: state.maxEmployees}
          : {}),
      },
      ...(runId ? {runId} : {}),
    }),
    [state, runId]
  );

  return queryParams;
};

// I can extend this with the same mapping function support that one would get directly using
// zustand if I want...it would mainly be for performance. But I think I need to rerender the
// entire account grid on any change anyway, so it wouldn't make a difference
export const useUserControls = () => {
  const view = useAccountView();
  return useTopLevelStoreState((state) => state[view]);
};
