import React from 'react';
import {WrapperFlex, ScrollableFlex} from '../utils/scrolling';
import {Box, Flex, FlexProps} from '@chakra-ui/react';

export const BuilderCenteredContent = ({
  children,
  ...props
}: React.PropsWithChildren<FlexProps>) => {
  return (
    <Box position="relative" h="100%">
      <WrapperFlex>
        <ScrollableFlex alignItems="center" p="0">
          <Flex
            direction="column"
            flex="1"
            maxW="700px"
            px="6"
            w="100%"
            {...props}
          >
            {children}
          </Flex>
        </ScrollableFlex>
      </WrapperFlex>
    </Box>
  );
};
