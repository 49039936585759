import {StaticAccountData} from './account';
import {ScoredAccount, Tag} from './scoredAccounts';
import {WithId} from './util';
import {KeyplayScoringSignal} from './signals';
import {CrmRecord} from './crm';
import {EnrichedFieldValue} from './enrichment';

export type ExportData = {
  tags?: WithId<Tag>[];
  scoringData?: {
    signals: KeyplayScoringSignal[];
  };
  crmRecord?: WithId<CrmRecord>;
  scoredAccount?: WithId<ScoredAccount>;
  enrichedFields?: EnrichedFieldValue[];
  // HACK: Account isn't in shared yet
  account?: {staticData?: StaticAccountData};
};

export type WithStaticAccountData<T> = T & {
  staticAccountData?: StaticAccountData;
};

export const MaxClayExportSize = 5_000;
export const ClayExportRegex = /^https:\/\/api.clay.com/;
