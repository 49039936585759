import React from 'react';
import {Flex, FlexProps} from '@chakra-ui/react';

export const BuilderFooter = ({
  children,
  ...props
}: React.PropsWithChildren<FlexProps>) => {
  return (
    <Flex w="100%" px={10} py={4} {...props}>
      {children}
    </Flex>
  );
};
