import React from 'react';
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Thead,
  TableContainer,
  Td,
  Tbody,
  Text,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {pluralize} from '../../../lib/helpers';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {format} from 'date-fns';
import {useCustomer} from '../../../hooks/api/metadata';
import {Agent, AgentTypeLabels} from '../../../shared/agent';
import {AgentBuilderModal} from './AgentBuilderModal';
import {StatusLabels} from '../../../shared/enrichment';
import {OverflowMenuVertical} from '@carbon/icons-react';

export const Agents = () => {
  const agents = useCustomer().agents ?? [];
  const agentBuilderModal = useDisclosure();

  return (
    <Flex direction="column" gap="4">
      <Flex justifyContent="space-between">
        <Box>
          <Box fontWeight="500">Agents</Box>
          <Box textColor="kgray.300" fontSize="sm">
            {pluralize(agents.length, 'agent', 'agents')}
          </Box>
        </Box>
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          onClick={agentBuilderModal.onOpen}
        >
          Create Agent
        </Button>
        <AgentBuilderModal
          isOpen={agentBuilderModal.isOpen}
          onClose={agentBuilderModal.onClose}
        />
      </Flex>
      <AgentsTable agents={agents} />
    </Flex>
  );
};

const columnHelper = createColumnHelper<Agent>();
const columns = [
  columnHelper.accessor((agent) => agent, {
    header: 'Agent Name',
    cell: (cell) => cell.getValue().label,
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    cell: (cell) => AgentTypeLabels[cell.getValue()],
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (cell) => StatusLabels[cell.getValue()],
  }),
  columnHelper.accessor('timestamp', {
    header: 'Created',
    cell: (cell) => format(cell.getValue(), 'M/dd/yy'),
  }),
  columnHelper.accessor((agent) => agent, {
    id: 'actions',
    header: '',
    size: 50,
    cell: (cell) => <AgentMenu agent={cell.getValue()} />,
  }),
];

const AgentMenu = ({agent}: {agent: Agent}) => {
  const agentBuilderModal = useDisclosure();
  return (
    <Menu>
      <MenuButton>
        <OverflowMenuVertical />
      </MenuButton>
      <MenuList>
        {agent.status === 'draft' && (
          <MenuItem onClick={agentBuilderModal.onOpen}>
            Edit Agent
            <AgentBuilderModal
              isOpen={agentBuilderModal.isOpen}
              onClose={agentBuilderModal.onClose}
              agentId={agent.id}
            />
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

const AgentsTable = ({agents}: {agents: Agent[]}) => {
  const table = useReactTable({
    columns,
    data: agents,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
    defaultColumn: {
      minSize: 0,
      size: 150,
    },
  });

  if (!agents.length) {
    return (
      <Flex direction="column" align="center" p={8}>
        <Text textColor="kgray.300">No agents defined.</Text>
      </Flex>
    );
  }

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  w={header.getSize() ? `${header.getSize()}px` : 'auto'}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} role="group">
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  _groupHover={{bg: 'kgray.100'}}
                  w={
                    cell.getContext().column.getSize()
                      ? `${cell.getContext().column.getSize()}px`
                      : 'auto'
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
