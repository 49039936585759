import React from 'react';
import {AgentType, AgentTypeLabels} from '../../../shared/agent';
import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react';
import {keys} from '../../../shared/util';
import {useAgentBuilderStore} from './AgentBuilder.state';
import {BuilderCenteredContent} from '../../Ai/BuilderCenteredContent';
import {BuilderFooter} from '../../Ai/BuilderFooter';
import {agentTypeInfo} from './agentTypeInfo';
import {useAgentBuilderNavStore} from './AgentBuilderNav.state';
import {AgentBuilderPageIndicator} from './AgentBuilderPageIndicator';

export const AgentBuilderSelectTypeStep = () => {
  const {type} = useAgentBuilderStore();
  const {nextStep} = useAgentBuilderNavStore();
  return (
    <Flex direction="column" flex="1">
      <BuilderCenteredContent>
        <AgentBuilderPageIndicator />
        <Text fontSize="xl">Pick your agent to get started</Text>
        <AgentCardGrid />
      </BuilderCenteredContent>

      <Divider />

      <BuilderFooter justifyContent="end">
        <Button colorScheme="kbuttonblue" isDisabled={!type} onClick={nextStep}>
          Continue
        </Button>
      </BuilderFooter>
    </Flex>
  );
};

const AgentCardGrid = () => {
  const {type, setType, serverState} = useAgentBuilderStore();
  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'agentType',
    value: type,
    onChange: setType,
    isDisabled: !!serverState,
  });

  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="8" mt="8" {...getRootProps()}>
      {keys(agentTypeInfo).map((agentType) => (
        <AgentTypeCard
          key={agentType}
          agentType={agentType}
          isDisabled={!!serverState}
          radioProps={getRadioProps({value: agentType})}
        />
      ))}
    </Grid>
  );
};

const AgentTypeCard = ({
  agentType,
  isDisabled,
  radioProps,
}: {
  agentType: AgentType;
  isDisabled?: boolean;
  radioProps: UseRadioProps;
}) => {
  const {getInputProps, getRadioProps, getLabelProps} = useRadio(radioProps);
  const id = `agent-card-${agentType}`;
  const {description, icon: AgentIcon} = agentTypeInfo[agentType];

  return (
    <GridItem
      as="label"
      border="1px solid"
      borderColor="kgray.200"
      borderRadius="md"
      boxShadow="sm"
      cursor="pointer"
      h="200px"
      px="4"
      py="6"
      _checked={{
        bg: 'kgray.100',
        borderColor: 'kgray.200',
      }}
      _focus={{
        boxShadow: 'outline',
      }}
      {...getLabelProps()}
      {...getRadioProps()}
    >
      <input {...getInputProps()} aria-labelledby={id} disabled={isDisabled} />
      <Flex direction="column" gap="8">
        <AgentIcon color="kgray.300" boxSize="32px" />
        <Flex direction="column" gap="2">
          <Text id={id} fontSize="xl">
            {AgentTypeLabels[agentType]}
          </Text>
          <Text fontSize="sm" color="kgray.300">
            {description}
          </Text>
        </Flex>
      </Flex>
    </GridItem>
  );
};
