import {StoreApi, createStore, useStore} from 'zustand';
import {AiFieldType} from '../../../shared/enrichment';
import {useFieldDefinitionFromContext} from './FieldDefinitionContext';

const Steps = [
  'defineField',
  'selectAccounts',
  'previewResults',
  'analyzeLists',
  'accountFilter',
  'reviewSettings',
] as const;
export type AiFieldBuilderStep = (typeof Steps)[number];

export const AiFieldBuilderSteps: Record<
  AiFieldType,
  readonly AiFieldBuilderStep[]
> = {
  boolean: Steps,
  category: Steps,
  rating: Steps,
  research: Steps.filter((step) => step !== 'analyzeLists'),
};

interface AiFieldBuilderNavState {
  step: AiFieldBuilderStep;
  steps: readonly AiFieldBuilderStep[];
  setStep: (step: AiFieldBuilderStep) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const createAiFieldBuilderNavStore = (fieldType: AiFieldType) =>
  createStore<AiFieldBuilderNavState>()((set, get) => {
    const getCurrentStepIdx = () => get().steps.indexOf(get().step);

    return {
      step: 'defineField',
      steps: AiFieldBuilderSteps[fieldType],
      setStep: (step) => set({step}),
      nextStep: () => {
        const nextStep = get().steps[getCurrentStepIdx() + 1];
        if (nextStep) {
          set({step: nextStep});
        }
      },
      prevStep: () => {
        const prevStep = get().steps[getCurrentStepIdx() - 1];
        if (prevStep) {
          set({step: prevStep});
        }
      },
    };
  });

const stores = {} as Record<AiFieldType, StoreApi<AiFieldBuilderNavState>>;
export const useAiFieldBuilderNavStore = () => {
  const fieldType = useFieldDefinitionFromContext().config.fieldType;
  stores[fieldType] ??= createAiFieldBuilderNavStore(fieldType);

  return useStore(stores[fieldType]);
};
