import React, {useMemo} from 'react';
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Skeleton,
  Box,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import {assertNever, WithId} from '../../../../shared/util';
import {useAnalysisRunContext} from './AnalysisRunProvider';
import {AiFieldDataType, TestListResults} from '../../../../shared/enrichment';
import {TestList} from '../../../../shared/testLists';
import {calculateNormalizedPercentage} from '../../../../lib/helpers';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import _ from 'lodash';

function useFieldTableData() {
  const {analysisRun} = useAnalysisRunContext();
  return useMemo(() => {
    const {fieldDefinition} = analysisRun;
    const config = fieldDefinition.config;
    switch (config.fieldType) {
      case 'boolean':
        return {
          headerLabel: 'Answer',
          fieldValues: [true, false],
        };
      case 'rating':
        return {
          headerLabel: 'Rating',
          fieldValues: _.range(config.min, config.max + 1).reverse(),
        };
      case 'category':
        return {
          headerLabel: 'Category',
          fieldValues: config.categories.map(({name}) => name),
        };
      case 'research':
        throw new Error('not implemented');
      default:
        assertNever(config);
    }
  }, [analysisRun]);
}

const CellHeight = '46px';

const SkeletonCell = React.memo(() => {
  return (
    <Flex alignItems="center" justifyContent="center" h={CellHeight}>
      <Skeleton h="10px" w="50%" />
    </Flex>
  );
});
SkeletonCell.displayName = 'SkeletonCell';

const PercentageColor = [
  '#FFFFFF', // 0
  '#F3EDFD', // 1-10
  '#E6DCF8', // 11-20
  '#DED0F5', // 21-30
  '#D5C5F2', // 31-40
  '#CDB9EF', // 41-50
  '#C4AEEB', // 51-60
  '#BCA2E8', // 61-70
  '#B396E5', // 71-80
  '#AB8BE2', // 81-90
  '#9E7BDD', // 91-100
];

const TestListResultCell = ({
  fieldValue,
  result,
  testList,
}: {
  fieldValue: AiFieldDataType;
  result: TestListResults;
  testList: WithId<TestList>;
}) => {
  const {fieldValues} = useFieldTableData();

  if (testList.status.type !== 'ready' || result.status === 'running') {
    return <SkeletonCell />;
  }

  const {count, percentage} = calculateNormalizedPercentage({
    targetValue: fieldValue,
    valueCounts: result.valueCounts.map(({value, count}) => [value, count]),
    isLastValue: fieldValue === fieldValues[fieldValues.length - 1],
  });

  const color = PercentageColor[Math.floor((percentage - 1) / 10) + 1];

  return (
    <Flex
      alignItems="center"
      bgColor={color}
      h={CellHeight}
      justifyContent="center"
    >
      <Tooltip label={`${count} accounts`} isDisabled={!count} placement="top">
        <Box>{percentage}%</Box>
      </Tooltip>
    </Flex>
  );
};

const columnHelper = createColumnHelper<AiFieldDataType>();
function useTableColumns({headerLabel}: {headerLabel: string}) {
  const {analysisRun, testLists} = useAnalysisRunContext();

  return useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'fieldValue',
        header: headerLabel,
        cell: (info) => <Box px="6">{info.getValue().toLocaleString()}</Box>,
      }),

      // Each test list has a column
      ...analysisRun.results.map((result) => {
        const testListId = result.testListId.toString();
        const testList = testLists.find((list) =>
          list._id.equals(result.testListId)
        );

        if (!testList) {
          return columnHelper.accessor((row) => row, {
            id: testListId,
            header: 'Unknown Test List',
            cell: '-',
            size: 100,
          });
        }

        return columnHelper.accessor((row) => row, {
          id: testListId,
          header: () => <Box textAlign="center">{testList.label}</Box>,
          cell: (info) => (
            <TestListResultCell
              key={testListId}
              fieldValue={info.getValue()}
              result={result}
              testList={testList}
            />
          ),
          size: 100,
        });
      }),
    ],
    [analysisRun, headerLabel, testLists]
  );
}

export const AnalysisResultsTable = () => {
  const {headerLabel, fieldValues} = useFieldTableData();
  const columns = useTableColumns({headerLabel});

  const table = useReactTable({
    columns,
    data: fieldValues,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer
      border="1px solid"
      borderColor="kgray.200"
      borderRadius="lg"
    >
      <Table
        variant="simple"
        sx={{
          borderCollapse: 'separate',
          borderSpacing: 0,
          'th, td': {
            borderRight: '1px solid',
            borderColor: 'kgray.200',
            '&:last-child': {
              borderRight: 0,
            },
          },
          'th:first-of-type, td:first-of-type': {
            backgroundColor: 'white',
            position: 'sticky',
            left: 0,
            zIndex: 1,
          },
          'thead th:first-of-type': {
            backgroundColor: 'kgray.100',
            zIndex: 1,
          },
        }}
      >
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  p="0"
                  width={
                    cell.getContext().column.getSize()
                      ? `${cell.getContext().column.getSize()}px`
                      : 'auto'
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
